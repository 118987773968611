import { createActions, createReducer } from "reduxsauce";

/*
 * Action types & creators
 */
export const { Types, Creators } = createActions({
  setEntities: ["entities"],
});

/*
 * Handlers
 */
const INITIAL_STATE = {
  entities: [],
};

const setEntities = (state = INITIAL_STATE, action) => {
  const { entities } = action;
  return {
    entities,
  };
};

/*
 * Reducers
 */
export default createReducer(INITIAL_STATE, {
  [Types.SET_ENTITIES]: setEntities,
});
