import { combineReducers } from "redux";

import base from "./base";
import profiles from "./profiles";
import setTrabalhista from "./setTrabalhista";

export default combineReducers({
  base,
  profiles,
  setTrabalhista,
});
