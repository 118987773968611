import React, { useRef, useEffect, useState } from "react";
import { create, edit } from "../../services/academy";
import { Button, Message, Modal, Schema, toaster } from "rsuite";
import { getCurrentDateHour } from "../../utils/currentDateHour";
import { Add, ArrowLeft2, CloseCircle, Edit } from "iconsax-react";
import { getRoute } from "./Settings";
import FormUpdate from "./FormUpdate";
import FormCreate from "./FormCreate";

const model = Schema.Model({
  query: Schema.Types.StringType().isRequired("Este campo é obrigatório."),
  answer: Schema.Types.StringType().isRequiredOrEmpty(
    "Este campo é obrigatório.",
  ),
  session: Schema.Types.StringType().isRequired("Este campo é obrigatório."),
  videoURL: Schema.Types.StringType().isURL("Não é uma URL válida."),
});

const CreateQuestion = ({ updated, data, getQuestions }) => {
  const profile = JSON.parse(localStorage.getItem("profile"));

  const formRef = useRef();
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [formValue, setFormValue] = useState({});
  const [formError, setFormError] = useState({});

  const handleModal = () => {
    setModal(!modal);
  };

  async function onCreate() {
    const { query, answer, videoURL, session } = formValue;

    if (!formRef.current.check()) {
      console.log(formError);
    } else if (!videoURL && !answer) {
      toaster.push(
        <Message closable showIcon type="warning" duration={8000}>
          Os campos Resposta e Youtube Link não podem estar vazios, preencha
          algum dos dois ou ambos.
        </Message>,
      );
    } else {
      setLoading(true);

      const dataCreate = {
        query: query,
        answer: answer || null,
        route: getRoute(session),
        session: session,
        videoURL: videoURL || null,
        actived: true,
        user_create: {
          userId: profile.id,
          userName: profile.name,
        },
        created_at: getCurrentDateHour(),
      };

      try {
        const createQuestion = await create(dataCreate);

        toaster.push(
          <Message closable showIcon type="success" duration={5000}>
            Pergunta criada com sucesso!
          </Message>,
        );

        getQuestions();
        setFormValue({});

        console.log(createQuestion);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
        handleModal();
      }
    }
  }

  async function onUpdateData() {
    const { query, answer, videoURL, session, actived } = formValue;

    if (!formRef.current.check()) {
      console.log(formError);
    } else if (videoURL?.length === 0 && answer?.length === 0) {
      toaster.push(
        <Message closable showIcon type="warning" duration={8000}>
          Os campos Resposta e Youtube Link não podem estar vazios, preencha
          algum dos dois ou ambos.
        </Message>,
      );
    } else {
      setLoading(true);

      const dataUpdate = {
        query: query,
        answer: answer,
        route: getRoute(session),
        session: session,
        videoURL: videoURL,
        actived: actived,
        user_update: {
          userId: profile.id,
          userName: profile.name,
        },
        updated_at: getCurrentDateHour(),
      };

      try {
        const updatedQuestion = await edit(data.id, dataUpdate);

        toaster.push(
          <Message closable showIcon type="success" duration={5000}>
            Pergunta atualizado com sucesso!
          </Message>,
        );

        getQuestions();

        console.log(updatedQuestion);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
        handleModal();
      }
    }
  }

  function setFormUpdated(dataUser) {
    setFormValue({
      query: dataUser.query,
      answer: dataUser.answer,
      route: dataUser.route,
      session: dataUser.session,
      videoURL: dataUser.videoURL,
      actived: dataUser.actived,
      created_at: dataUser.created_at,
      user_create: {
        userId: dataUser.user_create.id,
        userName: dataUser.user_create.userName,
      },
      user_update: {
        userName: dataUser?.user_update?.userName,
        userId: dataUser?.user_update?.userId,
      },
      updated_at: dataUser.updated_at,
    });
  }

  useEffect(() => {
    setFormValue({});

    if (updated) {
      setFormUpdated(data);
    }
  }, [data]);

  return (
    <>
      <Button
        onClick={handleModal}
        className={`question-action-button ${updated ? "update" : null}`}
      >
        {updated ? (
          <>
            <Edit size="18" color="#fff" />
            Editar
          </>
        ) : (
          <>
            <Add size="18" color="#fff" />
            Nova Pergunta
          </>
        )}
      </Button>

      <Modal className="tv-modal-default" open={modal} onClose={handleModal}>
        <Modal.Header closeButton={false} className="tv-modal-default_header">
          <div style={{ textAlign: "end" }}>
            <CloseCircle
              size="24"
              color="#98A2B3"
              onClick={handleModal}
              style={{ cursor: "pointer" }}
            />
          </div>

          <h6 className="tv-modal-default_header_title">
            {updated ? "Editar pergunta" : "Criar nova pergunta"}
          </h6>
        </Modal.Header>

        <Modal.Body className="tv-modal-default_body">
          {updated ? (
            <FormUpdate
              fluid
              formRef={formRef}
              data={data}
              model={model}
              formValue={formValue}
              formError={formError}
              setFormValue={setFormValue}
              setFormError={setFormError}
            />
          ) : (
            <FormCreate
              fluid
              formRef={formRef}
              model={model}
              formValue={formValue}
              formError={formError}
              setFormValue={setFormValue}
              setFormError={setFormError}
            />
          )}
        </Modal.Body>

        <Modal.Footer className="tv-modal-default_footer">
          <Button
            className="tv-modal-default_footer_button cancel"
            size="sm"
            block
            // icon={<ArrowLeft2 />}
            onClick={
              updated
                ? () => {
                    handleModal();
                    setFormUpdated(data);
                    // console.log(formRef.current)
                    // formRef.current.cleanErrors();
                  }
                : () => {
                    handleModal();
                    setFormValue({});
                  }
            }
          >
            <ArrowLeft2 size="24" color="#475467" />
            Cancelar
          </Button>
          <Button
            className="tv-modal-default_footer_button confirm"
            block
            size="sm"
            // icon={<Add />}
            loading={loading}
            onClick={updated ? () => onUpdateData() : () => onCreate()}
          >
            <Add size="24" color="#fff" />

            {updated ? "Salvar" : "Criar"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CreateQuestion;
