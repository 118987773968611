import React, { useEffect, useState } from "react";
import { Table } from "rsuite";
import CreateUpdateQuestion from "./CreateUpdateQuestion";
import "./style.scss";
import DeleteQuestion from "./DeleteQuestion";
import { verifyMegaUser } from "../../utils/verifyMegaUser";

const { Column, HeaderCell, Cell } = Table;

const ListQuestions = ({ questions, getQuestions }) => {
  const profile = JSON.parse(localStorage.getItem("profile"));

  const [loading, setLoading] = useState(false);
  const [sortColumn, setSortColumn] = useState();
  const [sortType, setSortType] = useState();

  const handleSortColumn = (sortColumn, sortType) => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setSortColumn(sortColumn);
      setSortType(sortType);
    }, 500);
  };

  const getData = () => {
    if (sortColumn && sortType) {
      return questions.sort((a, b) => {
        let x = a[sortColumn];
        let y = b[sortColumn];
        if (typeof x === "string") {
          x = x.charCodeAt();
        }
        if (typeof y === "string") {
          y = y.charCodeAt();
        }
        if (sortType === "asc") {
          return x - y;
        } else {
          return y - x;
        }
      });
    }
    return questions;
  };

  useEffect(() => {
    getQuestions();
  }, []);

  return (
    <div className="list-questions-container">
      <Table
        virtualized
        wordWrap="break-word"
        height={700}
        data={getData()}
        sortColumn={sortColumn}
        sortType={sortType}
        onSortColumn={handleSortColumn}
        loading={loading}
      >
        <Column>
          <HeaderCell>Status</HeaderCell>
          <Cell>
            {(rowData) => (
              <div className={`status-color`}>
                <block
                  className={`status-color_${rowData?.actived ? "active" : "inactive"}`}
                />
              </div>
            )}
          </Cell>
        </Column>
        <Column width={300} resizable sortable>
          <HeaderCell>Pergunta</HeaderCell>
          <Cell dataKey="query" />
        </Column>
        <Column width={150} sortable>
          <HeaderCell>Sessão</HeaderCell>
          <Cell dataKey="session" />
        </Column>

        <Column width={200} sortable>
          <HeaderCell>Atualizado em</HeaderCell>
          <Cell dataKey="updated_at" />
        </Column>
        <Column width={200} sortable>
          <HeaderCell>Data de Criação</HeaderCell>
          <Cell dataKey="created_at" />
        </Column>
        <Column>
          <HeaderCell></HeaderCell>
          <Cell style={{ padding: "6px" }}>
            {(rowData) => (
              <CreateUpdateQuestion
                updated
                data={rowData}
                getQuestions={getQuestions}
              />
            )}
          </Cell>
        </Column>

        {verifyMegaUser(profile.id) ? (
          <Column width={130}>
            <HeaderCell></HeaderCell>
            <Cell style={{ padding: "6px" }}>
              {(rowData) => (
                <DeleteQuestion
                  questionId={rowData.id}
                  getQuestions={getQuestions}
                />
              )}
            </Cell>
          </Column>
        ) : null}
      </Table>
    </div>
  );
};

export default ListQuestions;
