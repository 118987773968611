import React from "react";
// import { Preloader } from 'react-materialize';
import PropTypes from "prop-types";
import SimplePreloader from "../../loaders/SimplePreloader";
import "./TuriviusEntityIndicator.scss";

class TuriviusEntityIndicator extends React.Component {
  render() {
    const { loading, initials, color, backgroundColor, style } = this.props;
    return (
      <div className="entity-indicator">
        <div className="progress" data-percentage="0">
          <div
            className="progress-value"
            style={{
              backgroundColor: backgroundColor,
              border: `2px solid ${color}`,
              color: color,
              ...style,
            }}
          >
            {!loading ? <div>{initials}</div> : <SimplePreloader />}
          </div>
        </div>
      </div>
    );
    // <Preloader
    //     active
    //     flashing={false}
    //     className="turivius-simple-spinner"
    // />
  }
}

TuriviusEntityIndicator.propTypes = {
  initials: PropTypes.string,
  style: PropTypes.object,
  backgroundColor: PropTypes.string,
  color: PropTypes.string,
  loading: PropTypes.bool,
};

TuriviusEntityIndicator.defaultProps = {
  backgroundColor: "#fff",
  color: "#000",
  loading: false,
};

export default TuriviusEntityIndicator;
