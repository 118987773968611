import React, { useState, useRef, useEffect } from "react";
import {
  Grid,
  Col,
  Row,
  Button,
  Form,
  Schema,
  MaskedInput,
  toaster,
  Message,
} from "rsuite";
import { requester } from "../../../requesters";
import "./style.scss";

const companyModel = Schema.Model({
  name: Schema.Types.StringType().isRequired("Este campo é obrigatório."),
  email: Schema.Types.StringType()
    .isEmail("Insira um e-mail válido")
    .isRequired("Este campo é obrigatório."),
});

const mask = [
  "+",
  "5",
  "5",
  " ",
  "(",
  /[1-9]/,
  /[1-9]/,
  ")",
  " ",
  /[1-9]/,
  /[1-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  "-",
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
];

const Field = React.forwardRef((props, ref) => {
  const { name, message, label, accepter, error, ...rest } = props;
  return (
    <Form.Group ref={ref} className={error ? "has-error" : ""}>
      <Form.ControlLabel>{label}</Form.ControlLabel>
      <Form.Control
        name={name}
        accepter={accepter}
        errorMessage={error}
        {...rest}
      />
    </Form.Group>
  );
});

const CompanyDetails = ({ data, getCompany }) => {
  const companyForm = useRef();
  const [loading, setLoading] = useState(false);
  const [companyFormValue, setCompanyFormValue] = useState({});
  const [companyFormError, setCompanyFormError] = useState({});
  const [edit, setEdit] = useState(false);

  function setDefaultValues() {
    setEdit(false);

    setCompanyFormValue({
      id: data.id,
      name: data.name,
      email: data.email,
      description: data.description,
      address: data.address,
      cellphone: data.cellphone,
      last_modified: data.last_modified,
      users_count: data.max_profiles,
      users_created: data.active_users.active + data.active_users.inactive,
      users_active: data.active_users.active,
    });
  }

  function validateValues() {
    setLoading(true);

    if (companyForm.current.check()) {
      const { name, email, description, address, cellphone } = companyFormValue;

      requester.admin
        .updateCompany(data.id, {
          name,
          email,
          description,
          address,
          cellphone,
        })
        .then((response) => {
          toaster.push(
            <Message
              closeable
              duration={4000}
              type="success"
              header="Dados Atualizados"
            >
              {`Os dados da empresa ${data.name} foram atualizados com sucesso!`}
            </Message>,
          );
          getCompany();
          setEdit(false);
        })
        .catch((err) => {
          console.error(err);
          toaster.push(
            <Message closeable duration={4000} type="error">
              Houve um problema de conexão, tente novamente em instantes ou
              entre em contato com o suporte.
            </Message>,
          );
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      toaster.push(
        <Message closeable duration={4000} type="warning">
          Verifique os dados e tente novamente.
        </Message>,
      );
    }
  }

  useEffect(() => {
    setDefaultValues();
  }, []);

  return (
    <Grid fluid className="grid-container">
      <Form
        fluid
        ref={companyForm}
        onChange={setCompanyFormValue}
        onCheck={setCompanyFormError}
        formError={companyFormError}
        formValue={companyFormValue}
        model={companyModel}
        readOnly={loading}
      >
        <Row>
          <Col xs={24} sm={24} md={3}>
            <Form.Group controlId="id">
              <Form.ControlLabel>ID</Form.ControlLabel>
              <Form.Control
                name={"id"}
                // placeholder={"Insira o nome da empresa"}
                disabled
              />
            </Form.Group>
          </Col>
          <Col xs={24} sm={24} md={7}>
            <Form.Group controlId="name">
              <Form.ControlLabel>Nome</Form.ControlLabel>
              <Form.Control
                name={"name"}
                placeholder={"Insira o nome da empresa"}
                disabled={!edit}
              />
            </Form.Group>
          </Col>
          <Col xs={24} sm={24} md={7}>
            <Form.Group controlId="email">
              <Form.ControlLabel>E-mail</Form.ControlLabel>
              <Form.Control
                name={"email"}
                placeholder={"Insira o e-mail da empresa "}
                disabled={!edit}
              />
            </Form.Group>
          </Col>
          <Col xs={24} sm={24} md={7}>
            <Form.Group controlId="description">
              <Form.ControlLabel>Descrição</Form.ControlLabel>
              <Form.Control
                name={"description"}
                placeholder={"Insira uma descrição da empresa"}
                disabled={!edit}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row style={{ marginTop: 20 }}>
          <Col xs={24} sm={24} md={8}>
            <Form.Group controlId="address">
              <Form.ControlLabel>Endereço</Form.ControlLabel>
              <Form.Control
                name={"address"}
                placeholder={"Insira um endereço para a empresa"}
                disabled={!edit}
              />
            </Form.Group>
          </Col>
          <Col xs={24} sm={24} md={8}>
            <Field
              name="cellphone"
              label="Telefone"
              mask={mask}
              accepter={MaskedInput}
              placeholder={"Insira um telefone para a empresa"}
              disabled={!edit}
            />
          </Col>
          <Col xs={24} sm={24} md={8}>
            <Form.Group controlId="last_modified">
              <Form.ControlLabel>Última Atualização</Form.ControlLabel>
              <Form.Control readOnly disabled name={"last_modified"} />
            </Form.Group>
          </Col>
        </Row>

        <Row style={{ marginTop: 20 }}>
          <Col xs={24} sm={24} md={8}>
            <Form.Group controlId="users_count">
              <Form.ControlLabel>Usuários Contratados</Form.ControlLabel>
              <Form.Control
                readOnly
                disabled
                value={data.active_users.max_profiles}
              />
            </Form.Group>
          </Col>
          <Col xs={24} sm={24} md={8}>
            <Form.Group controlId="users_created">
              <Form.ControlLabel>Usuários Criados</Form.ControlLabel>
              <Form.Control
                readOnly
                disabled
                value={data.active_users.active + data.active_users.inactive}
              />
            </Form.Group>
          </Col>
          <Col xs={24} sm={24} md={8}>
            <Form.Group controlId="users_active">
              <Form.ControlLabel>Usuários Ativos</Form.ControlLabel>
              <Form.Control
                readOnly
                disabled
                value={data.active_users.active}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row style={{ marginTop: 20 }}>
          <Col xs={24} sm={24}>
            {edit ? (
              <Button
                loading={loading}
                onClick={validateValues}
                className="btn-save"
                style={{ float: "right" }}
              >
                Salvar
              </Button>
            ) : (
              <Button
                loading={loading}
                onClick={() => setEdit(true)}
                className="btn-edit"
                style={{ float: "right" }}
              >
                Editar
              </Button>
            )}
            {edit ? (
              <Button
                appearance="ghost"
                onClick={setDefaultValues}
                className="btn-cancel"
                style={{ float: "right", marginRight: 5 }}
              >
                Cancelar
              </Button>
            ) : null}
          </Col>
        </Row>
      </Form>
    </Grid>
  );
};

export default CompanyDetails;
