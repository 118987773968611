import React, { useState } from "react";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Modal,
  IconButton,
  toaster,
  Message,
  SelectPicker,
} from "rsuite";
import { requester } from "../../requesters";
import { Turivius } from "../../ui";
import "./style.scss";

const CopyServiceConfigButton = ({
  getPermissions,
  users,
  user,
  service,
  userPlan,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [load, setLoad] = useState(false);

  function handleModal() {
    setOpenModal(!openModal);
  }

  function updatePermissions() {
    setLoad(true);

    let token = `Token ${localStorage.getItem("token")}`;

    requester.segmentation
      .getServiceSegmentationByUserId(selectedUser, service, token)
      .then((response) => {
        if (userPlan) {
          return requester.segmentation.updateUserServiceSegmentation(
            service,
            user.id,
            response,
          );
        } else {
          return requester.segmentation.createUserServiceSegmentation(
            service,
            user.id,
            response.plano_alias,
            response,
          );
        }
      })
      .then((response) => {
        getPermissions(service);
        toaster.push(
          <Message closable duration={4000} type={"success"} header={"Sucesso"}>
            Segmentação realizada com sucesso!
          </Message>,
        );
      })
      .catch((err) => {
        console.log(err);
        toaster.push(
          <Message
            closable
            showIcon
            duration={4000}
            type={"error"}
            header={`Erro ${err.status}`}
          >
            {err.message}
          </Message>,
        );
      })
      .finally(() => {
        setLoad(false);
        setOpenModal();
      });
  }

  return (
    <>
      <Turivius.TuriviusTooltip
        trigger={"hover"}
        placement={"right"}
        message={"Clique aqui copiar permissões de outro usuário"}
      >
        <IconButton
          block
          className="btn-copy-service"
          icon={<FontAwesomeIcon icon={faCopy} />}
          onClick={() => handleModal()}
        />
      </Turivius.TuriviusTooltip>

      <Modal open={openModal} onClose={handleModal}>
        <Modal.Header>
          <h4>Copiar Configurações</h4>
        </Modal.Header>

        <Modal.Body>
          <p style={{ marginBottom: 15 }}>
            Selecione <b>de qual usuário</b> deseja copiar as permissões
          </p>
          <SelectPicker
            data={users.map((u) => ({ id: u.id, value: u.id, label: u.name }))}
            style={{ width: 224 }}
            onChange={setSelectedUser}
            readOnly={load}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button disabled={load} onClick={handleModal}>
            Cancelar
          </Button>
          <Button
            loading={load}
            onClick={updatePermissions}
            appearance="primary"
          >
            Confirmar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CopyServiceConfigButton;
