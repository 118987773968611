import React, { useEffect, useRef, useState } from "react";
import { Button, Modal, Form, Schema, toaster, Message } from "rsuite";
import { requester } from "../../requesters";
import TuriviusMultipleInput from "../TuriviusMultipleInput";
import "./style.scss";

const model = Schema.Model({
  cc: Schema.Types.ArrayType().of(
    Schema.Types.StringType()
      .isEmail("Informe um e-mail válido")
      .isRequired("Preencha este campo ou remova clicando na lixeira."),
  ),
  bcc: Schema.Types.ArrayType().of(
    Schema.Types.StringType()
      .isEmail("Informe um e-mail válido")
      .isRequired("Preencha este campo ou remova clicando na lixeira."),
  ),
});

const ResendButton = ({ data }) => {
  const formRef = useRef();
  const [openModal, setOpenModal] = useState(false);
  const [formError, setFormError] = useState({});
  const [formValue, setFormValue] = useState({});
  const [defaultFormValue, setDefaultFormValue] = useState({});
  const [loading, setLoading] = useState(false);

  const handleSubmit = () => {
    setLoading(true);
    if (!formRef.current.check()) {
      toaster.push(
        <Message closable duration={4000} type="error">
          Verifique os campos e tente novamente
        </Message>,
      );
      return;
    }

    requester.admin
      .resendFirstPasswordEmail(
        data.id,
        data.email,
        formValue.cc,
        formValue.bcc,
      )
      .then((response) => {
        toaster.push(
          <Message closable duration={5000} type="sucess">
            E-mail reenviado com sucesso!
          </Message>,
        );
        handleOpenClose();
      })
      .catch((err) => {
        toaster.push(
          <Message closable duration={5000} type="error">
            Erro ao enviar o e-mail: {err.message.errors[0]}
          </Message>,
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  function handleOpenClose() {
    setOpenModal(!openModal);
  }

  useEffect(() => {
    setDefaultFormValue({ email: data.email });
  }, []);

  return (
    <>
      <Button block className="btn-resend" onClick={() => handleOpenClose()}>
        Reenviar E-mail
      </Button>

      <Modal backdrop="static" open={openModal} onClose={handleOpenClose}>
        <Modal.Header>
          <h4>Reenviar e-mail de Boas-Vindas</h4>
        </Modal.Header>
        <Modal.Body>
          <Form
            fluid
            ref={formRef}
            onChange={setFormValue}
            onCheck={setFormError}
            formDefaultValue={defaultFormValue}
            formValue={formValue}
            formError={formError}
            model={model}
            readOnly={loading}
          >
            <Form.Group>
              <Form.ControlLabel>Reenviar e-mail para</Form.ControlLabel>
              <Form.Control name="email" readOnly />
              <Form.HelpText>
                não é possível substituir o e-mail do usuário.
              </Form.HelpText>
            </Form.Group>
            <Form.Group>
              <Form.ControlLabel>Cópia para (cc)</Form.ControlLabel>
              <Form.Control
                name="cc"
                accepter={TuriviusMultipleInput}
                error={formError.cc}
              />
              <Form.HelpText>
                este campo <b>não</b> é obrigatório
              </Form.HelpText>
            </Form.Group>
            <Form.Group>
              <Form.ControlLabel>Cópia Oculta para (bcc)</Form.ControlLabel>
              <Form.Control
                name="bcc"
                accepter={TuriviusMultipleInput}
                error={formError.bcc}
              />
              <Form.HelpText>
                este campo <b>não</b> é obrigatório
              </Form.HelpText>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button readOnly={loading} onClick={handleOpenClose}>
            Cancelar
          </Button>
          <Button loading={loading} onClick={handleSubmit} appearance="primary">
            Confirmar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ResendButton;
