import React, { useState, useEffect } from "react";
import { faArrowRightArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Modal, SelectPicker, toaster, Message } from "rsuite";
import { requester } from "../../requesters";
import TuriviusColors from "../../vars.module.scss";
import "./style.scss";
import SpinnerIcon from "@rsuite/icons/legacy/Spinner";

const ChangeOffice = (props) => {
  const [openModal, setOpenModal] = useState(false);
  const [load, setLoad] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [companiesList, setCompaniesList] = useState([]);

  function modalOpenClose() {
    setOpenModal(!openModal);
  }

  function getCompanies(page) {
    requester.admin
      .getCompanies({ page })
      .then((response) => {
        setCompaniesList(response.companies);
      })
      .catch((err) => console.error(err));
  }

  function changeCompany() {
    setLoad(true);

    requester.admin
      .changeUserCompany(props.userId, selectedCompany)
      .then((response) => {
        toaster.push(
          <Message closable duration={4000} type="success">
            Escritório alterado com sucesso!
          </Message>,
        );
        modalOpenClose();
        window.location.reload();
      })
      .catch((err) => {
        console.error(err);
        toaster.push(
          <Message
            closable
            duration={4000}
            type="error"
            header={`Erro ${err?.status}`}
          >
            {err?.message}
          </Message>,
        );
      })
      .finally(() => {
        setLoad(false);
      });
  }

  useEffect(() => {
    console.log(props.companyId);
    getCompanies(1);
    setSelectedCompany(props.companyId);
  }, [""]);

  return (
    <>
      <Button
        className="btn-change-office"
        onClick={modalOpenClose}
        disabled={props.disabled}
      >
        <FontAwesomeIcon
          color={TuriviusColors.white}
          icon={faArrowRightArrowLeft}
        />
      </Button>
      <Modal backdrop="static" open={openModal} onClose={modalOpenClose}>
        <Modal.Header>
          <h4>Alterar escritório</h4>
        </Modal.Header>

        <Modal.Body>
          <div style={{ marginTop: 20 }}>
            <h6 style={{ marginBottom: 15 }}>Selecione um escritório</h6>
            <SelectPicker
              block
              cleanable
              renderMenu={(menu) => {
                if (companiesList.length === 0) {
                  return (
                    <p style={{ padding: 10, color: "#999" }}>
                      <SpinnerIcon spin /> Carregando...
                    </p>
                  );
                }
                return menu;
              }}
              data={companiesList?.map((i) => ({
                label: i.name,
                value: i.id,
                ...i,
              }))}
              placeholder={"Selecione um escritório..."}
              value={selectedCompany}
              onChange={(value, e) => setSelectedCompany(value)}
            />
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button onClick={modalOpenClose}>Cancelar</Button>
          <Button loading={load} onClick={changeCompany} appearance="primary">
            Confirmar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ChangeOffice;
