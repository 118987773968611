import { SelectPicker } from "rsuite";
import PropTypes from "prop-types";

import "./TuriviusSingleSelection.scss";

const TuriviusSingleSelection = ({
  helperText,
  setTmpFilters,
  options,
  context,
  value,
  filters,
  filter,
  label,
  addon,
  posthog,
  labelKey,
  ...props
}) => {
  return (
    <div className="turivius-single-group-filter">
      <div className="title">
        <label>{label}</label>
      </div>

      <SelectPicker
        data={
          options
            ? options?.map((option) => ({
                value: option.id,
                label: option[labelKey],
              }))
            : []
        }
        block
        placeholder="Selecione uma ordenação"
        value={value}
        onChange={(value, e) => {
          if (context) {
            posthog.capture(context, {
              action: "change_filter",
              id: filter.field_name,
            });
          }
          setTmpFilters(filter.field_name, value);
        }}
        cleanable={false}
      />
      {helperText ? (
        <div className="input-field-header" style={{ marginBottom: 12 }}>
          {helperText}
        </div>
      ) : null}
    </div>
  );
};

TuriviusSingleSelection.propTypes = {
  context: PropTypes.string,
  filter: PropTypes.object,
  label: PropTypes.string,
  labelKey: PropTypes.string,
  helperText: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),

  // REDUX
  filters: PropTypes.object,
};

TuriviusSingleSelection.defaultProps = {
  label: "",
};

export default TuriviusSingleSelection;
