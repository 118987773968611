import { Tooltip, Whisper } from "rsuite";
import PropTypes from "prop-types";
import "./TuriviusTooltip.scss";

const TuriviusTooltip = ({ trigger, children, message, placement }) => (
  <Whisper
    trigger={trigger}
    placement={placement}
    controlId={`control-id-${placement}`}
    speaker={<Tooltip>{message}</Tooltip>}
  >
    {children}
  </Whisper>
);

TuriviusTooltip.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  message: PropTypes.string,
  trigger: PropTypes.oneOf([
    "click",
    "contextMenu",
    "hover",
    "focus",
    "active",
    "none",
  ]),
  placement: PropTypes.oneOf([
    "top",
    "bottom",
    "right",
    "left",
    "bottomStart",
    "bottomEnd",
    "topStart",
    "topEnd",
    "leftStart",
    "leftEnd",
    "rightStart",
    "rightEnd",
    "auto",
    "autoVerticalStart",
    "autoVerticalEnd",
    "autoHorizontalStart",
    "autoHorizontalEnd",
  ]),
};

TuriviusTooltip.defaultProps = {
  trigger: "click",
  placement: "auto",
};

export default TuriviusTooltip;
