import React, { useEffect, useState } from "react";
import { Button, toaster, Message, Grid, Col, Row } from "rsuite";
import TuriviusTable from "../../TuriviusTable";
import "./style.scss";
import { requester } from "../../../requesters";
import ExportSearchDataButton from "../../ExportSearchDataButton";

const columns = [
  { key: "query", label: "Nome", fixed: true, width: 300 },
  { key: "dt", label: "Data/Hora", fixed: true, width: 300 },
  {
    key: "url",
    label: "Ações",
    fixed: true,
    width: 200,
    customCell: ({ rowData, dataKey, ...props }) => (
      <Button
        className="btn-history-action"
        href={`https://app.turivius.com/resultados?query=${rowData.query}&filters=${JSON.stringify(rowData.filters)}`}
        target="_blank"
      >
        Refazer Pesquisa
      </Button>
    ),
  },
];

const UserHistorySearch = ({ user, userName }) => {
  const [load, setLoad] = useState(false);
  const [historyUser, setHistoryUser] = useState([]);

  function getUserHistory() {
    setLoad(true);
    requester.admin
      .getSearchHistoryById(user)
      .then((history) => {
        setHistoryUser(history);
      })
      .catch((err) => {
        console.error(err);
        toaster.push(
          <Message
            closable
            duration={5000}
            type="error"
            header={`Erro ${err?.status}`}
          >
            {err?.message}
          </Message>,
        );
      })
      .finally(() => {
        setLoad(false);
      });
  }

  useEffect(() => {
    getUserHistory();
  }, []);

  return (
    <div className="user-history-container">
      <Grid fluid>
        <Row>
          <Col sm={24} xs={24} md={24}>
            <Message type="warning">
              São apresentadas apenas <b>as últimas 20 pesquisas</b> do usuário
            </Message>
          </Col>
          <Col
            sm={10}
            xs={4}
            md={6}
            lg={4}
            style={{ float: "right", marginTop: 5 }}
          >
            <ExportSearchDataButton
              history={historyUser}
              userName={userName}
              getUserHistory={getUserHistory}
            />
          </Col>
        </Row>
      </Grid>
      <TuriviusTable
        wordWrap
        data={historyUser}
        columns={columns}
        loading={load}
        height={500}
      />
    </div>
  );
};

export default UserHistorySearch;
