import { request } from "./requester";

function getDecisionsStatistics() {
  return request({
    type: "GET",
    microservice: "database",
    action: `total_results_in_db/`,
    contentType: "application/json",
    auth: "token",
  }).then((response) => {
    return response;
  });
}

export const database = {
  getDecisionsStatistics,
};
