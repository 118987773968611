import React, { useState } from "react";
import { SelectPicker, Modal, Button, toaster, Message } from "rsuite";
import { requester } from "../../requesters";

const SelectServicePicker = ({
  getPermissions,
  service,
  userPlan,
  plans,
  user,
}) => {
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [selectPlan, setSelectPlan] = useState(false);

  function handleModal(value) {
    setSelectPlan(value);
    setOpenModal(!openModal);
  }

  function updateUserServiceSegmentation() {
    setLoading(true);
    requester.segmentation
      .updateUserServiceSegmentation(
        service,
        user.id,
        plans[service].find((p) => p.plano_alias === selectPlan),
      )
      .then((response) => {
        getPermissions(service);
        toaster.push(
          <Message
            closable
            duration={4000}
            type={"success"}
            header={"Dados Atualizados"}
          >
            Configuração atualizadas com sucesso!
          </Message>,
        );
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        toaster.push(
          <Message closable duration={4000} type={"error"}>
            Houve um problema de conexão, tente novamente em instantes.
          </Message>,
        );
      })
      .finally();
  }

  function createUserServiceSegmentation() {
    setLoading(true);
    requester.segmentation
      .createUserServiceSegmentation(
        service,
        user.id,
        selectPlan,
        plans[service].find((p) => p.plano_alias === selectPlan),
      )
      .then((response) => {
        getPermissions(service);
        toaster.push(
          <Message
            closable
            duration={4000}
            type={"success"}
            header={"Dados Criados"}
          >
            Configuração criadas com sucesso!
          </Message>,
        );
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        toaster.push(
          <Message closable duration={4000} type={"error"}>
            {err.message}
          </Message>,
        );
      });
  }

  function changeService() {
    if (userPlan) {
      updateUserServiceSegmentation();
    } else {
      createUserServiceSegmentation();
    }
  }

  function setNamePlan(plan) {
    switch (plan) {
      case "full_permissive":
        return "Permissão Completa";
      default:
        return plan;
    }
  }

  return (
    <>
      <SelectPicker
        style={{ width: "100%" }}
        cleanable={false}
        data={
          plans && plans[service]
            ? plans[service].map((plan) => ({
                label: setNamePlan(plan.plano_alias),
                value: plan.plano_alias,
                ...plan,
              }))
            : []
        }
        placeholder={"Selecione um plano..."}
        value={userPlan || null}
        onChange={(value, e) => handleModal(value)}
        loading={!plans?.[service]}
      />
      <Modal open={openModal} onClose={handleModal}>
        <Modal.Header>
          <h4>Confirmar ação</h4>
        </Modal.Header>

        <Modal.Body>
          <p>Deseja realizar alteração de serviço para {user.name}?</p>
        </Modal.Body>

        <Modal.Footer>
          <Button onClick={handleModal}>Cancelar</Button>
          <Button loading={loading} onClick={changeService}>
            Confirmar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SelectServicePicker;
