import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import { InputGroup, Input, Button, Grid, Row, toaster, Message } from "rsuite";
import "./style.scss";
import { requester } from "../../../requesters";

const CompanyPaymentSettings = ({ data, getCompany }) => {
  const [paymentURL, setPaymentURL] = useState("");
  const [loading, setLoading] = useState(false);

  const updatePaymentURL = () => {
    setLoading(true);
    requester.admin
      .updatePaymentURL(data.id, paymentURL)
      .then((response) => {
        getCompany();
        toaster.push(
          <Message
            closeable
            duration={4000}
            type="success"
            header="Dados Atualizados"
          >
            {`Os dados de pagamento da empresa ${data.name} foram atualizados com sucesso!`}
          </Message>,
        );
      })
      .catch((err) => {
        console.error(err);
        toaster.push(
          <Message closeable duration={4000} type="error">
            Houve um problema de conexão, tente novamente em instantes ou entre
            em contato com o suporte.
          </Message>,
        );
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    setPaymentURL(data.asaas_url);
  }, [""]);

  return (
    <Grid fluid className="payment-container">
      <Row>
        <label>Link Asaas</label>
        <InputGroup>
          <InputGroup.Addon>
            <FontAwesomeIcon className="rs-icon" icon={faLink} />
          </InputGroup.Addon>
          <Input
            value={paymentURL}
            onChange={setPaymentURL}
            disabled={loading}
          />
          <InputGroup.Button>
            <Button
              disabled={data.asaas_url === paymentURL}
              onClick={updatePaymentURL}
              loading={loading}
            >
              Salvar
            </Button>
          </InputGroup.Button>
        </InputGroup>
      </Row>
    </Grid>
  );
};

export default CompanyPaymentSettings;
