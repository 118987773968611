import React from "react";
import { Redirect } from "react-router-dom";
import { getAuth, signOut } from "firebase/auth";
const Logout = (props) => {
  //logout do firebase
  const auth = getAuth();
  signOut(auth)
    .then(() => {
      // Sign-out successful.
    })
    .catch((error) => {
      // An error happened.
    });
  localStorage.clear();

  return (
    <Redirect
      to={{
        pathname: "/login",
        state: { from: props.location },
      }}
    />
  );
};

export default Logout;
