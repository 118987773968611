import { Posthog } from "../posthog/posthog";
import { request } from "./requester";

function getServerHealth() {
  return request({
    type: "GET",
    microservice: "dashboards",
    action: `health`,
    auth: "token",
  }).then((response) => {
    return response;
  });
}

function getUserDashboards(page = 1) {
  return request({
    type: "GET",
    microservice: "dashboards",
    action: `dashboard/`,
    auth: "token",
    data: {
      page,
    },
  }).then((response) => {
    return response;
  });
}

function getDashboardById(dashboardId, auth = "token") {
  return request({
    type: "GET",
    microservice: "dashboards",
    action: `dashboard/${dashboardId}`,
    auth,
  }).then((response) => {
    return response;
  });
}

function getDashboardPlots(dashboardId, tablePage = 1) {
  return request({
    type: "GET",
    microservice: "dashboards",
    action: `dashboard/${dashboardId}/plot`,
    auth: "token",
    data: {
      table_page: tablePage,
    },
  }).then((response) => {
    return response;
  });
}

function getDashboardComments(dashboardId, page = 1) {
  return request({
    type: "GET",
    microservice: "dashboards",
    action: `dashboard/${dashboardId}/comment/`,
    auth: "token",
    data: {
      page,
    },
  }).then((response) => {
    Posthog.capture("Single Dashboard", {
      action: "get_dashboard_comments",
      id: dashboardId,
      params: {
        page: page,
      },
    });
    return response;
  });
}

function getDashboardPlotComments(dashboardId, plotId, page = 1) {
  return request({
    type: "GET",
    microservice: "dashboards",
    action: `dashboard/${dashboardId}/comment/`,
    auth: "token",
    data: {
      resource_id: plotId,
      page,
    },
  }).then((response) => {
    return response;
  });
}

function getDashboardCommentById(dashboardId, commentId) {
  return request({
    type: "GET",
    microservice: "dashboards",
    action: `dashboard/${dashboardId}/comment/${commentId}/`,
    auth: "token",
  }).then((response) => {
    return response;
  });
}

function getDashboardTablePage(
  dashboardId,
  page = 1,
  { entity, year, category },
) {
  let data = {
    page,
    entity,
    year,
    category,
  };
  Object.keys(data).forEach((k) => data[k] == null && delete data[k]);

  return request({
    type: "GET",
    microservice: "dashboards",
    action: `dashboard/${dashboardId}/table/`,
    auth: "token",
    data,
  }).then((response) => {
    return response;
  });
}

function getDashboardAdhocUsers(dashboardId, page = 1) {
  return request({
    type: "GET",
    microservice: "dashboards",
    action: `dashboard/${dashboardId}/adhocuser/`,
    auth: "token",
    data: {
      page,
    },
  }).then((response) => {
    return response;
  });
}

function getFilters() {
  return request({
    type: "GET",
    microservice: "dashboards",
    action: `filters`,
    auth: "token",
  }).then((filters) => {
    return filters;
  });
}

function getDashboardRelatorsFilter(dashboardId) {
  return request({
    type: "GET",
    microservice: "dashboards",
    action: `dashboard/${dashboardId}/relatores`,
    auth: "token",
  }).then((relatores) => {
    return relatores;
  });
}

function createDashboard(title, subtitle, queryString, sharedWith) {
  return request({
    type: "POST",
    microservice: "dashboards",
    action: "dashboard/",
    auth: "token",
    data: {
      title,
      subtitle,
      query: queryString,
      shared_with: sharedWith,
    },
  }).then((response) => {
    Posthog.capture("Dashboards", {
      action: "create_dashboard",
      id: response._id,
    });
    return response;
  });
}

function createComment(dashboardId, userId, plotId, text) {
  return request({
    type: "POST",
    microservice: "dashboards",
    action: `dashboard/${dashboardId}/comment/`,
    auth: "token",
    data: {
      user_id: userId,
      resource_id: plotId,
      text,
    },
  }).then((response) => {
    Posthog.capture("Single Dashboard", {
      action: "create_dashboard_comment",
      id: response._id,
      to: dashboardId,
    });
    return response;
  });
}

function createTextPlot(dashboardId, plotId, userId, text) {
  return request({
    type: "POST",
    microservice: "dashboards",
    action: `dashboard/${dashboardId}/textcard/`,
    auth: "token",
    data: {
      resource_id: plotId,
      user_id: userId,
      text,
    },
  }).then((response) => {
    return response;
  });
}

function createDashboardAdhocUser(dashboardId, email, expirationDate) {
  return request({
    type: "POST",
    microservice: "dashboards",
    action: `dashboard/${dashboardId}/adhocuser/`,
    auth: "token",
    data: {
      email,
      expiration_date: expirationDate,
    },
  }).then((response) => {
    return response;
  });
}

function updateTextPlot(dashboardId, plotId, text) {
  return request({
    type: "PUT",
    microservice: "dashboards",
    action: `dashboard/${dashboardId}/textcard/${plotId}/`,
    auth: "token",
    data: {
      text,
    },
  }).then((response) => {
    return response;
  });
}

function updateDashboard(
  dashboardId,
  title,
  subtitle,
  query,
  layouts,
  removedLayouts,
  sharedWith,
) {
  return request({
    type: "PUT",
    microservice: "dashboards",
    action: `dashboard/${dashboardId}`,
    auth: "token",
    data: {
      title,
      subtitle,
      query,
      layouts,
      removedLayouts,
      shared_with: sharedWith,
    },
  }).then((response) => {
    Posthog.capture("Sigle Dashboard", {
      action: "update_dashboard_params",
      id: dashboardId,
      params: query,
    });
    return response;
  });
}

function updateDashboardShare(dashboardId, sharedWith, etContext) {
  return request({
    type: "PUT",
    microservice: "dashboards",
    action: `dashboard/${dashboardId}`,
    auth: "token",
    data: {
      shared_with: sharedWith,
    },
  }).then((response) => {
    Posthog.capture(etContext, {
      action: "share_dashboard",
      ids: sharedWith,
      to: dashboardId,
    });
    return response;
  });
}

function updateDashboardPlotTitle(dashboardId, plotId, title) {
  return request({
    type: "PUT",
    microservice: "dashboards",
    action: `dashboard/${dashboardId}/titles/`,
    auth: "token",
    data: {
      resource_id: plotId,
      title,
    },
  }).then((response) => {
    Posthog.capture("Single Dashboard", {
      action: "update_dashboard_resource_title",
      id: dashboardId,
      to: plotId,
    });
    return response;
  });
}

function updateDashboardPlotSubtitle(dashboardId, plotId, subtitle) {
  return request({
    type: "PUT",
    microservice: "dashboards",
    action: `dashboard/${dashboardId}/titles/`,
    auth: "token",
    data: {
      resource_id: plotId,
      subtitle,
    },
  }).then((response) => {
    Posthog.capture("Single Dashboard", {
      action: "update_dashboard_resource_subtitle",
      id: dashboardId,
      to: plotId,
    });
    return response;
  });
}

function updateDashboardTitle(dashboardId, title) {
  return request({
    type: "PUT",
    microservice: "dashboards",
    action: `dashboard/${dashboardId}`,
    auth: "token",
    data: {
      title,
    },
  }).then((response) => {
    Posthog.capture("Single Dashboard", {
      action: "update_dashboard_title",
      id: dashboardId,
    });
    return response;
  });
}

function updateDashboardSubtitle(dashboardId, subtitle) {
  return request({
    type: "PUT",
    microservice: "dashboards",
    action: `dashboard/${dashboardId}`,
    auth: "token",
    data: {
      subtitle,
    },
  }).then((response) => {
    Posthog.capture("Single Dashboard", {
      action: "update_dashboard_subtitle",
      id: dashboardId,
    });
    return response;
  });
}

function updateDashboardLayout(dashboardId, layouts, removedLayouts) {
  return request({
    type: "PUT",
    microservice: "dashboards",
    action: `dashboard/${dashboardId}`,
    auth: "token",
    data: {
      layouts,
      removedLayouts,
    },
  }).then((response) => {
    Posthog.capture("Single Dashboard", {
      action: "update_dashboard_layout",
      id: dashboardId,
    });
    return response;
  });
}

function updateDashboardComment(dashboardId, commentId, text) {
  return request({
    type: "PUT",
    microservice: "dashboards",
    action: `dashboard/${dashboardId}/comment/${commentId}/`,
    auth: "token",
    data: {
      text,
    },
  }).then((response) => {
    Posthog.capture("Single Dashboard", {
      action: "update_dashboard_comment",
      id: dashboardId,
      to: commentId,
    });
    return response;
  });
}

function updateDashboardClassification(
  dashboardId,
  categoryId = null,
  options,
  cardId,
  userId,
  tablePage,
) {
  return request({
    type: "PUT",
    microservice: "dashboards",
    action: `dashboard/${dashboardId}/classification/`,
    auth: "token",
    data: {
      category_id: categoryId,
      options,
      card_id: cardId,
      user_id: userId,
    },
  })
    .then(() => {
      Posthog.capture("Single Dashboard", {
        action: "update_dashboard_classification",
        id: cardId,
        to: dashboardId,
        params: {
          category: categoryId ? categoryId : "favorabilidade",
          options: options,
        },
      });
      return getDashboardPlots(dashboardId, tablePage);
    })
    .then((response) => {
      return response;
    });
}

function updateDashboardAdhocUser(dashboardId, adhocUserId, expirationDate) {
  return request({
    type: "PUT",
    microservice: "dashboards",
    action: `dashboard/${dashboardId}/adhocuser/${adhocUserId}/`,
    auth: "token",
    data: {
      expiration_date: expirationDate,
    },
  }).then((response) => {
    return response;
  });
}

function deleteDashboard(dashboardId, etContext) {
  return request({
    type: "DELETE",
    microservice: "dashboards",
    action: `dashboard/${dashboardId}`,
    auth: "token",
  }).then((response) => {
    Posthog.capture(etContext, {
      action: "remove_dashboard",
      id: dashboardId,
    });
    return response;
  });
}

function deleteDashboardComment(dashboardId, commentId) {
  return request({
    type: "DELETE",
    microservice: "dashboards",
    action: `dashboard/${dashboardId}/comment/${commentId}/`,
    auth: "token",
  }).then((response) => {
    Posthog.capture("Single Dashboard", {
      action: "remove_dashboard_comment",
      id: commentId,
      to: dashboardId,
    });
    return response;
  });
}

function deleteDashboardCard(dashboardId, cardId, userId, tablePage) {
  return request({
    type: "POST",
    microservice: "dashboards",
    action: `dashboard/${dashboardId}/exclude_card/`,
    auth: "token",
    data: {
      user_id: userId,
      card_id: cardId,
    },
  })
    .then(() => {
      Posthog.capture("Single Dashboard", {
        action: "remove_dashboard_card",
        id: cardId,
        to: dashboardId,
      });
      return getDashboardPlots(dashboardId, tablePage);
    })
    .then((response) => {
      return response;
    });
}

function deleteDashboardAdhocUser(dashboardId, adhocUserId) {
  return request({
    type: "DELETE",
    microservice: "dashboards",
    action: `dashboard/${dashboardId}/adhocuser/${adhocUserId}/`,
    auth: "token",
  }).then((response) => {
    return response;
  });
}

export const dashboards = {
  getServerHealth,
  getUserDashboards,
  getDashboardById,
  getDashboardPlots,
  getDashboardComments,
  getDashboardPlotComments,
  getDashboardCommentById,
  getDashboardTablePage,
  getDashboardAdhocUsers,
  getFilters,
  getDashboardRelatorsFilter,
  createDashboard,
  createComment,
  createTextPlot,
  createDashboardAdhocUser,
  updateDashboard,
  updateDashboardShare,
  updateDashboardComment,
  updateDashboardTitle,
  updateDashboardSubtitle,
  updateDashboardPlotTitle,
  updateDashboardPlotSubtitle,
  updateDashboardLayout,
  updateTextPlot,
  updateDashboardClassification,
  updateDashboardAdhocUser,
  deleteDashboard,
  deleteDashboardComment,
  deleteDashboardCard,
  deleteDashboardAdhocUser,
};
