import { Posthog } from "../posthog/posthog";
import { request } from "./requester";

function getById(id) {
  return request({
    type: "GET",
    action: `profiles/${id}`,
    microservice: "profiles",
    auth: "token",
    finger: "print",
  }).then((profile) => {
    return profile;
  });
}

function getOnboardingUserById(id) {
  return request({
    type: "GET",
    action: `api/profiles/${id}/`,
    microservice: "profiles",
    auth: "token",
  }).then((profile) => {
    return profile;
  });
}

function updateOnboardingUser(userId, data) {
  return request({
    type: "PATCH",
    action: `api/profiles/${userId}/`,
    microservice: "profiles",
    auth: "token",
    data: data,
  }).then((profile) => {
    Posthog.capture("onboarding", {
      action: "setup_onboarding",
      id: profile.id,
    });
    return profile;
  });
}

function getCompanyProfiles(companyId) {
  return request({
    type: "GET",
    microservice: "profiles",
    action: `company/${companyId}/profiles`,
    auth: "token",
  }).then((profiles) => {
    return profiles;
  });
}

function getCompanyUserNames(companyId, name = "") {
  return request({
    type: "GET",
    microservice: "profiles",
    action: `profiles/get_user_names/${companyId}`,
    auth: "token",
    data: {
      name,
    },
  }).then((profiles) => {
    return profiles;
  });
}

function getProfilePhoto(userId) {
  return request({
    type: "GET",
    microservice: "profiles",
    action: `profiles/${userId}/photo`,
  }).then((photo) => {
    return photo;
  });
}

function getUserAgreements(userId) {
  return request({
    type: "GET",
    microservice: "profiles",
    action: `agreement/${userId}`,
    auth: "token",
  }).then((agreements) => {
    return agreements;
  });
}

function toggleUserActivity(id, etContext) {
  return request({
    type: "PATCH",
    action: `profiles/${id}/activity`,
    microservice: "profiles",
    auth: "token",
  }).then((response) => {
    Posthog.capture(etContext, {
      action: "toggle_user_activity",
      id: id,
    });
    return response;
  });
}

function createUser(name, email, position, short_bio = "", company, etContext) {
  return request({
    type: "POST",
    action: `profiles/`,
    microservice: "profiles",
    contentType: "application/x-www-form-urlencoded; charset=UTF-8",
    auth: "token",
    data: {
      name: encodeURIComponent(name),
      email: encodeURIComponent(email),
      position: encodeURIComponent(position),
      short_bio: encodeURIComponent(short_bio),
      company,
    },
  }).then((user) => {
    Posthog.capture(etContext, {
      action: "create_profile",
      id: user.id,
    });
    return user;
  });
}
function createUserBulk(data) {
  return request({
    type: "POST",
    action: `api/profiles-bulk/`,
    microservice: "profiles",
    auth: "token",
    data: data,
  }).then((response) => {
    return response;
  });
}

function updateCompany(
  companyId,
  name,
  email,
  address,
  description,
  etContext,
) {
  let data = {
    name: name,
    email: email,
    address: address,
    description: description,
  };
  return request({
    type: "PATCH",
    action: `company/${companyId}`,
    microservice: "profiles",
    auth: "token",
    data,
  }).then((company) => {
    Posthog.capture(etContext, {
      action: "update_company_profile",
      id: companyId,
    });
    return company;
  });
}

function checkEmail(email) {
  return request({
    type: "GET",
    action: `check_email/?email=${email}`,
    microservice: "profiles",
  }).then((response) => {
    return response;
  });
}

function updateProfile(
  id,
  name,
  email,
  position,
  short_bio,
  linkedin_link,
  etContext,
  phone,
) {
  let data = {
    name,
    email,
    position,
    short_bio,
    linkedin_link,
    phone,
  };
  return request({
    type: "PATCH",
    action: `profiles/${id}`,
    microservice: "profiles",
    auth: "token",
    data,
  }).then((profile) => {
    Posthog.capture(etContext, {
      action: "update_profile",
      id: id,
    });
    return profile;
  });
}

function updateCompanyImage(id, logo) {
  return request({
    type: "PATCH",
    action: `company/${id}/photo`,
    microservice: "profiles",
    auth: "token",
    data: {
      logo,
    },
  }).then((response) => {
    return response;
  });
}

function updateProfileImage(id, photo) {
  return request({
    type: "PATCH",
    action: `profiles/${id}/photo`,
    microservice: "profiles",
    auth: "token",
    data: {
      photo,
    },
  }).then((response) => {
    return response;
  });
}

function createSimple(
  name,
  email,
  password,
  law_area,
  cellphone,
  coupon,
  agreement,
) {
  let data = {
    name,
    email,
    password,
    law_area,
    cellphone,
    coupon,
    agreement,
  };
  if (data.coupon === "" || data.coupon === null || data.coupon === undefined) {
    delete data.coupon;
  }
  return request({
    type: "POST",
    action: `profiles/create_simple/`,
    microservice: "profiles",
    data,
  }).then((response) => {
    return response;
  });
}

function createUserAgreement(userId, privacy, terms) {
  return request({
    type: "POST",
    action: `agreement/${userId}`,
    microservice: "profiles",
    contentType: "application/x-www-form-urlencoded; charset=UTF-8",
    auth: "token",
    data: {
      privacy,
      terms,
    },
  }).then((response) => {
    return response;
  });
}

export const profiles = {
  getById,
  getCompanyProfiles,
  getCompanyUserNames,
  getUserAgreements,
  getProfilePhoto,
  getOnboardingUserById,
  updateOnboardingUser,
  createUser,
  createUserBulk,
  createUserAgreement,
  updateProfile,
  updateProfileImage,
  toggleUserActivity,
  updateCompany,
  updateCompanyImage,
  createSimple,
  checkEmail,
};
