import { createActions, createReducer } from "reduxsauce";

/*
 * Action types & creators
 */
export const { Types, Creators } = createActions({
  setEntitiesTrabalhista: ["entitiesTrabalhista"],
});

/*
 * Handlers
 */
const INITIAL_STATE = {
  entitiesTrabalhista: [],
};

const setEntitiesTrabalhista = (state = INITIAL_STATE, action) => {
  const { entitiesTrabalhista } = action;
  return {
    entitiesTrabalhista,
  };
};

/*
 * Reducers
 */
export default createReducer(INITIAL_STATE, {
  [Types.SET_ENTITIES_TRABALHISTA]: setEntitiesTrabalhista,
});
