import React from "react";
import PropTypes from "prop-types";
import "./TuriviusLineChart.scss";
import { ResponsiveLine } from "@nivo/line";

const TuriviusLineChart = ({
  id,
  className,
  style,
  data,
  enableSlices,
  margin,
  xScale,
  yScale,
  yFormat,
  curve,
  axisTop,
  axisRight,
  axisBottom,
  axisLeft,
  getColors,
  enablePoints,
  pointSize,
  pointBorderWidth,
  pointBorderColor,
  pointLabelYOffset,
  areaBlendMode,
  useMesh,
  debugMesh,
  onClick,
  tooltip,
  enableGridX,
  enableGridY,
  isInteractive,
  enableCrosshair,
  crosshairType,
  legends,
  animate,
  ...params
}) => (
  <div
    id={id}
    data-testid={`line-chart-${id}`}
    className={`turivius-line-chart ${className}`}
    style={style}
  >
    <ResponsiveLine
      data={data}
      enableSlices={enableSlices}
      margin={margin}
      xScale={xScale}
      yScale={yScale}
      yFormat={yFormat}
      curve={curve}
      axisTop={axisTop}
      axisRight={axisRight}
      axisBottom={axisBottom}
      axisLeft={axisLeft}
      colors={getColors}
      enablePoints={enablePoints}
      pointSize={pointSize}
      pointBorderWidth={pointBorderWidth}
      pointBorderColor={pointBorderColor}
      pointLabelYOffset={pointLabelYOffset}
      areaBlendMode={areaBlendMode}
      useMesh={useMesh}
      debugMesh={debugMesh}
      onClick={onClick}
      tooltip={tooltip}
      enableGridX={enableGridX}
      enableGridY={enableGridY}
      isInteractive={isInteractive}
      enableCrosshair={enableCrosshair}
      crosshairType={crosshairType}
      legends={legends}
      animate={animate}
      {...params}
    />
  </div>
);

TuriviusLineChart.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
  data: PropTypes.array,
  enableSlices: PropTypes.bool,
  margin: PropTypes.object,
  xScale: PropTypes.object,
  yScale: PropTypes.object,
  yFormat: PropTypes.string,
  curve: PropTypes.string,
  axisTop: PropTypes.object,
  axisRight: PropTypes.object,
  axisBottom: PropTypes.object,
  axisLeft: PropTypes.object,
  getColors: PropTypes.func,
  enablePoints: PropTypes.bool,
  pointSize: PropTypes.number,
  pointBorderWidth: PropTypes.number,
  pointBorderColor: PropTypes.object,
  pointLabelYOffset: PropTypes.number,
  areaBlendMode: PropTypes.string,
  useMesh: PropTypes.bool,
  debugMesh: PropTypes.bool,
  onClick: PropTypes.func,
  tooltip: PropTypes.func,
  enableGridX: PropTypes.bool,
  enableGridY: PropTypes.bool,
  isInteractive: PropTypes.bool,
  enableCrosshair: PropTypes.bool,
  crosshairType: PropTypes.string,
  legends: PropTypes.array,
  animate: PropTypes.bool,
};

TuriviusLineChart.defaultProps = {
  id: undefined,
  className: "",
  style: {
    width: 400,
    height: 300,
  },
  data: [],
  enableSlices: false,
  margin: {
    top: 20,
    right: 50,
    bottom: 50,
    left: 50,
  },
  xScale: { type: "point" },
  yScale: {
    type: "linear",
    min: "auto",
    max: "auto",
    stacked: false,
    reverse: false,
  },
  yFormat: " >-.2f",
  curve: "monotoneX",
  axisTop: null,
  axisRight: null,
  axisBottom: {
    orient: "bottom",
    tickSize: 5,
    tickPadding: 5,
    tickRotation: 0,
    legend: "xTitle",
    legendOffset: 36,
    legendPosition: "middle",
  },
  axisLeft: {
    orient: "left",
    tickSize: 5,
    tickPadding: 5,
    tickRotation: 0,
    legend: "yTitle",
    legendOffset: -45,
    legendPosition: "middle",
  },
  getColors: undefined,
  enablePoints: true,
  pointSize: 6,
  pointBorderWidth: 2,
  pointBorderColor: { from: "serieColor" },
  pointLabelYOffset: -12,
  areaBlendMode: "multiply",
  useMesh: true,
  debugMesh: false,
  onClick: () => {},
  tooltip: undefined,
  enableGridX: true,
  enableGridY: true,
  isInteractive: true,
  enableCrosshair: false,
  crosshairType: "bottom-left",
  legends: [
    {
      anchor: "bottom-right",
      direction: "column",
      justify: false,
      translateX: 100,
      translateY: 0,
      itemsSpacing: 0,
      itemDirection: "left-to-right",
      itemWidth: 80,
      itemHeight: 20,
      itemOpacity: 0.75,
      symbolSize: 12,
      symbolShape: "circle",
      symbolBorderColor: "rgba(0, 0, 0, .5)",
      effects: [
        {
          on: "hover",
          style: {
            itemBackground: "rgba(0, 0, 0, .03)",
            itemOpacity: 1,
          },
        },
      ],
    },
  ],
  animate: true,
};

export default TuriviusLineChart;
