import React from "react";
import PropTypes from "prop-types";
import { IconButton } from "rsuite";
import "./TuriviusIconButton.scss";

const TuriviusIconButton = ({
  children,
  appearance,
  onClick,
  disabled,
  fullWidth,
  loading,
  icon,
  placement,
  backgroundColor,
  color,
  style,
  className,
  size,
}) => (
  <IconButton
    appearance={appearance}
    className={className}
    onClick={onClick}
    disabled={disabled}
    block={fullWidth}
    icon={icon}
    placement={placement}
    loading={loading}
    size={size}
    style={{ ...style, backgroundColor, color, borderColor: color }}
  >
    {children}
  </IconButton>
);

TuriviusIconButton.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  appearance: PropTypes.oneOf([
    "default",
    "primary",
    "link",
    "subtle",
    "ghost",
  ]),
  placement: PropTypes.oneOf(["left", "right"]),
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  style: PropTypes.object,
  backgroundColor: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.oneOf(["xs", "sm", "md", "lg"]),
  icon: PropTypes.node,
};

TuriviusIconButton.defaultProps = {
  onClick: () => {},
  disabled: false,
  appearance: "default",
  backgroundColor: "",
  color: "",
  fullWidth: false,
  loading: false,
  placement: "left",
  size: "md",
};

export default TuriviusIconButton;
