import React from "react";
import { FlexboxGrid } from "rsuite";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faWaveSquare,
  faPeopleGroup,
  faUsersBetweenLines,
} from "@fortawesome/free-solid-svg-icons";
import "./style.scss";
import CountUp from "react-countup";

const CompanyAccount = ({ data }) => {
  return data.length === 0 ? (
    <div style={{ textAlign: "center" }}>
      <h4>Dados não encontrados</h4>
      <spam>
        Os dados da empresa não foram encontrados ou estão indisponíveis.
      </spam>
    </div>
  ) : (
    <div className="container-account">
      <FlexboxGrid justify="space-between">
        <FlexboxGrid.Item colspan={7}>
          <div className="content-information">
            <p>
              <CountUp end={data.active_users.max_profiles} duration={1} />
            </p>
            <label className="title-users">Usuários Contratados</label>
            <div className="icon-information">
              <FontAwesomeIcon
                size={"2x"}
                color="white"
                icon={faUsersBetweenLines}
              />
            </div>
          </div>
        </FlexboxGrid.Item>

        <FlexboxGrid.Item colspan={7}>
          <div className="content-information">
            <p>
              <CountUp
                end={data.active_users.active + data.active_users.inactive}
                duration={1}
              />
            </p>
            <label className="title-users">Usuários Criados</label>
            <div className="icon-information">
              <FontAwesomeIcon size={"2x"} color="white" icon={faPeopleGroup} />
            </div>
          </div>
        </FlexboxGrid.Item>

        <FlexboxGrid.Item colspan={7}>
          <div className="content-information">
            <p>
              <CountUp end={data.active_users.active} duration={1} />
            </p>
            <label className="title-users">Usuários Ativos</label>
            <div className="icon-information">
              <FontAwesomeIcon size={"2x"} color="white" icon={faWaveSquare} />
            </div>
          </div>
        </FlexboxGrid.Item>
      </FlexboxGrid>
    </div>
  );
  // <Grid fluid className="grid-container">
  //     <Row  gutter={24}>
  //         <Col xs={24} sm={24} md={8}>
  //             <div className="content-information">
  //                 <p>{data.active_users.max_profiles || "--"}</p>
  //                 <label className="title-users">Usuários Contratados</label>
  //                 <div className="icon-information">
  //                     <FontAwesomeIcon size={"2x"} color="white" icon={faUsers}/>
  //                 </div>
  //             </div>
  //         </Col>
  //         <Col xs={24} sm={24} md={8}>
  //             <div className="content-information">
  //                 <p>{(data.active_users.active + data.active_users.inactive)||"--"}</p>
  //                 <label className="title-users">Usuários Criados</label>
  //             </div>
  //         </Col>
  //         <Col xs={24} sm={24} md={8}>
  //             <div className="content-information">
  //                 <p>{data.active_users.active}</p>
  //                 <label className="title-users">Usuários Ativos</label>
  //             </div>
  //         </Col>
  //     </Row>

  //     {/* <Row>
  //         <Col xs={24} sm={24} md={12}>
  //             <label className="title-users">Usuários Contratados</label>
  //             <p>{data.active_users.max_profiles || "--"}</p>
  //         </Col>
  //     </Row>
  //     <Row style={{ marginTop: 15 }}>
  //         <Col xs={24} sm={24} md={8}>
  //             <label className="title-users">Usuários Contratados</label>
  //             <p>{data.active_users.max_profiles || "--" }</p>
  //         </Col>
  //         <Col xs={24} sm={24} md={8}>
  //             <label className="title-users">Usuários Criados</label>
  //             <p>{data.active_users.active + data.active_users.inactive}</p>
  //         </Col>
  //         <Col xs={24} sm={24} md={8}>
  //             <label className="title-users">Usuários Ativos</label>
  //             <p>{data.active_users.active}</p>
  //         </Col>
  //     </Row> */}
  //     {/* <Row style={{ marginTop: 25 }}>
  //         {data.resume.map(r => (
  //             <Col xs={24} sm={24} md={8}>
  //                 <label>{r.account_plan ? `Sistema ${r.area}: ${r.account_plan}` : `Sistema ${r.area}`}</label>
  //                 <p>{r.user_count} Usuário(s)</p>
  //             </Col>
  //         ))}
  //     </Row> */}
  // </Grid>
};

export default CompanyAccount;
