import React, { useEffect, useState } from "react";
import { Turivius } from "../../ui";
import { Input, Button, Col, Row, Grid } from "rsuite";
import { withRouter } from "react-router-dom";
import { requester } from "../../requesters";
import { UpdateStatusCompanyButton } from "../../components/UpdateStatusButton";
import CreateNewCompanyButton from "../../components/CreateNewCompanyButton";
import "./style.scss";

const response = {
  columns: [
    { key: "id", label: "ID", fixed: false, width: 100 },
    { key: "name", label: "Nome", fixed: false, width: 300, resizable: true },
    {
      key: "email",
      label: "E-Mail",
      fixed: false,
      width: 200,
      resizable: true,
      customCell: ({ rowData, dataKey, ...props }) => (
        <div
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          <Turivius.TuriviusTooltip
            trigger="hover"
            message={`Clique para enviar e-mail para ${rowData.email}`}
            placement="top"
          >
            <a href={`mailto:${rowData.email}`}>{rowData.email}</a>
          </Turivius.TuriviusTooltip>
        </div>
      ),
    },
    {
      key: "blocked",
      label: "Status",
      fixed: false,
      width: 150,
      customCell: ({ rowData, dataKey, ...props }) => (
        <UpdateStatusCompanyButton table data={rowData} type={"company"} />
      ),
    },
    {
      key: "asaas_url",
      label: "Tipo",
      fixed: false,
      width: 150,
      resizable: true,
      customCell: ({ rowData, dataKey, ...props }) => (
        <p className={`type-client ${rowData.asaas_url ? "client" : "trial"}`}>
          {rowData.asaas_url ? "Cliente" : "Trial"}
        </p>
      ),
    },
    {
      key: "usersactived",
      label: "Usuários Ativos",
      fixed: false,
      width: 200,
      customCell: ({ rowData, dataKey, ...props }) => (
        <p>{`${rowData.active_users.active ? rowData.active_users.active : 0} de ${rowData.active_users.active !== null && rowData.active_users.inactive !== null ? rowData.active_users.active + rowData.active_users.inactive : 0} `}</p>
      ),
    },

    // {
    //     key: 'action',
    //     label: 'Ações',
    //     fixed: false,
    //     width: 100,
    //     customCell: ({ rowData, dataKey, ...props }) => (
    //         <GoDetailsButton data={rowData} typeData={"company"} />
    //     )
    // },
  ],
};

const Company = (props) => {
  const [companies, setCompanies] = useState([]);
  const [companiesCount, setCompaniesCount] = useState(null);
  const [genericFilter, setGenericFilter] = useState("");
  const [genericFilterSearchTimeout, setGenericFilterSearchTimeout] =
    useState(null);
  const [load, setLoad] = useState(true);
  const [loadSearching, setLoadSearching] = useState(false);
  const [activePage, setActivePage] = useState(1);

  const getCompanies = (page) => {
    setLoad(true);

    requester.admin
      .getCompanies({ page, pageSize: 10, ordering: "id", genericFilter })
      .then((response) => {
        setCompanies(response.companies);
        setCompaniesCount(response.count);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoad(false);
      });
  };

  const changeGenericFilter = () => {
    setLoadSearching(true);
    clearTimeout(genericFilterSearchTimeout);
    setGenericFilterSearchTimeout(
      setTimeout(() => {
        setActivePage(1);
        getCompanies(1);
        setLoadSearching(false);
      }, 1000),
    );
  };

  function keyPressSearch(e) {
    if (e.key === "Enter") {
      changeGenericFilter();
    }
  }

  function directPage(data) {
    props.history.push(`/empresas/${data.id}`);
  }

  // const createNew = async () => {
  //     // const resp = await getAll()
  //     const res = await remove("94Lx0AuM9imFlUzMuXrq")
  //     console.log(res)
  // }

  useEffect(() => {
    document.title = `Empresas`;
    getCompanies(1);
  }, [""]);

  /// Formatar planilha para JSON

  return (
    <Grid fluid>
      <div className="container-companies">
        {/* import de planilha para multiplos usuarios */}

        <Row className="company-header">
          <Col xs={24} sm={24} md={19}>
            <h1>Empresas</h1>
          </Col>
          <Col xs={24} sm={24} md={5}>
            <CreateNewCompanyButton />
          </Col>
        </Row>

        <Row className="company-search">
          <Col xs={24} sm={24} md={19}>
            <Input
              placeholder="Filtro Genérico"
              className="input-search"
              value={genericFilter}
              onChange={setGenericFilter}
              onKeyUp={keyPressSearch}
            />
          </Col>
          <Col xs={24} sm={24} md={5}>
            <Button
              block
              className="button-search"
              loading={loadSearching}
              onClick={changeGenericFilter}
            >
              Buscar
            </Button>
          </Col>
        </Row>

        <div className="company-body">
          <Turivius.TuriviusTable
            data={companies}
            columns={response.columns}
            loading={load}
            height={500}
            fixed
            wordWrap
            onRowClick={(companie) => directPage(companie)}
            paginate
            total={companiesCount}
            limit={10}
            onChangePage={(page) => {
              getCompanies(page);
              setActivePage(page);
            }}
            activePage={activePage}
          />
        </div>
      </div>
    </Grid>
  );
};

export default withRouter(Company);
