import React from "react";
import { Container, Header, Content } from "rsuite";
import PropTypes from "prop-types";

const TuriviusBasePageContent = ({
  header,
  headerStyle,
  headerId,
  content,
  contentStyle,
  contentId,
  backgroundColor,
}) => (
  <Container className="custom-container">
    {header ? (
      <Header style={headerStyle} id={headerId}>
        <div
          className="custom-container-header"
          style={{ backgroundColor: backgroundColor }}
        >
          <div className="custom-container-header-content">
            <div className="header-hero">{header}</div>
          </div>
        </div>
      </Header>
    ) : null}
    <Content className="page-content" style={contentStyle} id={contentId}>
      {content}
    </Content>
  </Container>
);

TuriviusBasePageContent.propTypes = {
  header: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  content: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  headerStyle: PropTypes.object,
  contentStyle: PropTypes.object,
  id: PropTypes.string,
};

TuriviusBasePageContent.defaultProps = {
  header: null,
  content: null,
  headerStyle: {},
  contentStyle: {},
  headerId: "",
  contentId: "",
  backgroundColor: "#02032f",
};

export default TuriviusBasePageContent;
