import { Posthog } from "../posthog/posthog";
import { request } from "./requester";

/**NEW MONITORING */
function getMonitoring(page, search, ordering, limit = 6, etContext) {
  let offset = page * limit - limit;

  return request({
    type: "GET",
    microservice: "monitoring",
    action: `monitored-terms`,
    auth: "token",
    data: {
      search,
      offset,
      ordering,
      limit,
    },
  }).then((monitoring) => {
    Posthog.capture(etContext, {
      action: "get_monitoring_terms",
      id: null,
      to: null,
      page: page,
      order: ordering,
      term: search,
    });
    return monitoring;
  });
}

function getMonitoringById(monitoringId) {
  return request({
    type: "GET",
    microservice: "monitoring",
    action: `monitored-terms/${monitoringId}`,
    auth: "token",
  }).then((response) => {
    return response;
  });
}

function getMonitoringSharedWithMe(page, search, ordering, limit = 6) {
  let offset = page * limit - limit;

  return request({
    type: "GET",
    microservice: "monitoring",
    action: `monitored-terms`,
    auth: "token",
    data: {
      shared: true,
      offset,
      search,
      ordering,
      limit,
    },
  }).then((response) => {
    return response;
  });
}

function verifyMonitoring(term) {
  return request({
    type: "GET",
    microservice: "monitoring",
    action: `monitored-terms`,
    auth: "token",
    data: {
      term,
    },
  }).then((response) => {
    return response;
  });
}

function unsubscribe(userId, date, hash) {
  return request({
    type: "GET",
    microservice: "monitoring",
    action: `cancel_subscription`,
    auth: "token",
    data: {
      user_id: userId,
      date,
      h: hash,
    },
  }).then((response) => {
    return response;
  });
}

function updateMonitoring(
  monitoringId,
  sharedWith,
  etContext,
  query,
  frequency,
  send_email,
  send_notification,
  all_entities,
  filters,
  monitoring_week_day,
  monitoring_month_day,
) {
  let data = {
    query,
    frequency,
    send_email,
    send_notification,
    all_entities,
    filters,
    monitoring_week_day,
    monitoring_month_day,
    shared_with: sharedWith,
  };

  return request({
    type: "PATCH",
    microservice: "monitoring",
    action: `monitored-terms/${monitoringId}/`,
    auth: "token",
    data,
  }).then((monitoring) => {
    Posthog.capture(etContext, {
      action: "edit_monitoring",
      id: monitoringId,
      to: null,
      query: query,
      frequency: frequency,
      sendEmail: send_email,
      all_entities: all_entities,
      filters: filters,
    });
    return monitoring;
  });
}

function removeMonitoringTerm(monitoringId, etContext) {
  return request({
    type: "DELETE",
    microservice: "monitoring",
    action: `monitored-terms/${monitoringId}`,
    auth: "token",
  }).then((response) => {
    Posthog.capture(etContext, {
      action: "remove_monitoring",
      id: monitoringId,
      to: null,
    });
    return response;
  });
}

function addMonitoringTerm(
  term,
  frequency,
  sendEmail,
  sendNotification,
  allEntities,
  filters,
  sharedWith,
  monitoringWeekDay,
  monitoringMonthDay,
  userId,
  companyId,
  etContext,
) {
  return request({
    type: "POST",
    microservice: "monitoring",
    action: `monitored-terms/`,
    auth: "token",
    data: {
      user_id: userId,
      company_id: companyId,
      query: term,
      frequency,
      send_email: sendEmail,
      send_notification: sendNotification,
      all_entities: allEntities,
      filters,
      shared_with: sharedWith,
      monitoring_week_day: monitoringWeekDay,
      monitoring_month_day: monitoringMonthDay,
      active: true,
    },
  }).then((monitoring) => {
    Posthog.capture(etContext, {
      action: "create_monitoring",
      id: monitoring.id,
      to: null,
    });
    return monitoring;
  });
}

export const monitoring = {
  getMonitoring,
  verifyMonitoring,
  updateMonitoring,
  removeMonitoringTerm,
  unsubscribe,
  addMonitoringTerm,
  getMonitoringById,
  getMonitoringSharedWithMe,
};
