import React, { useState, useEffect } from "react";
import { Col, Grid, Row, toaster, Message } from "rsuite";
import { requester } from "../../../requesters";
import { bindActionCreators } from "redux";
import { Creators as TributarioActions } from "../../../store/ducks/base";
import { Creators as TrabalhistaActions } from "../../../store/ducks/setTrabalhista";

//Components
import { UpdateStatusUserButton } from "../../UpdateStatusButton";
import UpdateAdminButton from "../../UpdateAdminButton";
import UpdateBIButton from "../../UpdateBIButton";
import TuriviusPanel from "../../TuriviusPanel";
import SelectServicePicker from "../../SelectServicePicker";
import DeleteServiceButton from "../../DeleteServiceButton";
import CopyServiceConfigButton from "../../CopyServiceConfigButton";
import TributarioPermissions from "./Permissions/TributarioPermissions";
import BITributarioPermissions from "./Permissions/BITributarioPermissions";

//Config
import "./style.scss";
import { connect } from "react-redux";
import { permissions } from "../../../config/permissions";
import CheckoutBlockButton from "../../CheckoutBlockButton";

const UserPermissions = ({
  user,
  page,
  users,
  entities,
  entitiesTrabalhista,
  ...props
}) => {
  const [load, setLoading] = useState({
    tributario: true,
    trabalhista: true,
    bi_tributario: true,
  });
  const [loadSave, setLoadSave] = useState({
    tributario: false,
    trabalhista: false,
    bi_tributario: false,
  });
  const [userPermissions, setUserPermissions] = useState({});
  const [plans, setPlans] = useState({});

  function getPlans(service = "all") {
    if (service === "all" || service === "tributario") {
      requester.segmentation
        .getServicePlans("tributario")
        .then((response) => {
          setPlans((prevState) => ({
            ...prevState,
            tributario: response,
          }));
        })
        .catch((err) => {
          console.error(err);
        });
    }

    if (service === "all" || service === "bi_tributario") {
      requester.segmentation
        .getServicePlans("bi_tributario")
        .then((response) => {
          setPlans((prevState) => ({
            ...prevState,
            bi_tributario: response,
          }));
        })
        .catch((err) => {
          console.error(err);
        });
    }

    if (service === "all" || service === "trabalhista") {
      requester.segmentation
        .getServicePlans("trabalhista")
        .then((response) => {
          setPlans((prevState) => ({
            ...prevState,
            trabalhista: response,
          }));
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }

  function getPermissions(service = "all", reload) {
    let token = `Token ${localStorage.getItem("token")}`;

    if (service === "all" || service === "tributario") {
      setLoading((prevState) => ({
        ...prevState,
        tributario: true,
      }));
      requester.segmentation
        .getServiceSegmentationByUserId(user.id, "tributario", token)
        .then((response) => {
          response.entidades = Object.keys(response.entidades)?.reduce(
            (acc, entity) => {
              if (response.entidades[entity]) {
                acc.push(parseInt(entity));
              }
              return acc;
            },
            [],
          );
          response.orgaos_monitorados = Object.keys(
            response.orgaos_monitorados,
          )?.reduce((acc, entity) => {
            if (response.orgaos_monitorados[entity]) {
              acc.push(parseInt(entity));
            }
            return acc;
          }, []);
          response.dataviz_entidades = Object.keys(
            response.dataviz_entidades,
          )?.reduce((acc, entity) => {
            if (response.dataviz_entidades[entity]) {
              acc.push(parseInt(entity));
            }
            return acc;
          }, []);

          setUserPermissions((prevState) => ({
            ...prevState,
            tributario: response,
          }));
        })
        .catch((err) => {
          if (err.status === 400) {
            setUserPermissions((prevState) => ({
              ...prevState,
              tributario: [],
            }));
          }
        })
        .finally(() => {
          setLoading((prevState) => ({
            ...prevState,
            tributario: false,
          }));
          setLoadSave((prevState) => ({
            ...prevState,
            tributario: false,
          }));
        });
    }

    if (service === "all" || service === "bi_tributario") {
      let token = `Token ${localStorage.getItem("token")}`;

      setLoading((prevState) => ({
        ...prevState,
        bi_tributario: true,
      }));
      requester.segmentation
        .getServiceSegmentationByUserId(user.id, "bi_tributario", token)
        .then((response) => {
          response.entidades = Object.keys(response.entidades)?.reduce(
            (acc, entity) => {
              if (response.entidades[entity]) {
                acc.push(parseInt(entity));
              }
              return acc;
            },
            [],
          );
          setUserPermissions((prevState) => ({
            ...prevState,
            bi_tributario: response,
          }));
        })
        .catch((err) => {
          if (err.status === 400) {
            setUserPermissions((prevState) => ({
              ...prevState,
              bi_tributario: [],
            }));
          }
          // console.log(err)
          // toaster.push(
          //   <Message
          //     closable
          //     showIcon
          //     duration={4000}
          //     type={"error"}
          //     header={`Erro ${err.status}`}
          //   >
          //     {err.message}
          //   </Message>
          // )
        })
        .finally(() => {
          setLoading((prevState) => ({
            ...prevState,
            bi_tributario: false,
          }));
          setLoadSave((prevState) => ({
            ...prevState,
            bi_tributario: false,
          }));
        });
    }
    if (service === "all" || service === "trabalhista") {
      let token = `Token ${localStorage.getItem("token")}`;

      setLoading((prevState) => ({
        ...prevState,
        trabalhista: true,
      }));
      requester.segmentation
        .getServiceSegmentationByUserId(user.id, "trabalhista", token)
        .then((response) => {
          response.entidades = Object.keys(response.entidades)?.reduce(
            (acc, entity) => {
              if (response.entidades[entity]) {
                acc.push(parseInt(entity));
              }
              return acc;
            },
            [],
          );

          setUserPermissions((prevState) => ({
            ...prevState,
            trabalhista: response,
          }));
        })
        .catch((err) => {
          if (err.status === 400) {
            setUserPermissions((prevState) => ({
              ...prevState,
              trabalhista: [],
            }));
          }
        })
        .finally(() => {
          setLoading((prevState) => ({
            ...prevState,
            trabalhista: false,
          }));
          setLoadSave((prevState) => ({
            ...prevState,
            trabalhista: false,
          }));
        });
    }
  }

  function getPermissionTitle(title) {
    let header = "";
    switch (title) {
      case "tributario":
        header += "App Turivius";
        break;
      case "bi_tributario":
        header += "BI Tributário";
        break;
      case "trabalhista":
        header += "App Trabalhista";
        break;
      default:
        header += "Não Identificado";
        break;
    }

    return header;
  }

  function updateUserServiceSegmentation(service) {
    setLoadSave((prevState) => ({
      ...prevState,
      [service]: true,
    }));

    let newUserPermissions = userPermissions[service];
    let newUserPermissionsTrabalhista = userPermissions["trabalhista"];

    if (newUserPermissionsTrabalhista.entidades) {
      newUserPermissionsTrabalhista.entidades =
        newUserPermissionsTrabalhista.entidades.reduce((acc, value) => {
          acc[value] = true;
          return acc;
        }, {});
    }
    if (newUserPermissions.entidades) {
      newUserPermissions.entidades = newUserPermissions.entidades.reduce(
        (acc, value) => {
          acc[value] = true;
          return acc;
        },
        {},
      );
    }
    if (newUserPermissions.orgaos_monitorados) {
      newUserPermissions.orgaos_monitorados =
        newUserPermissions.orgaos_monitorados.reduce((acc, value) => {
          acc[value] = true;
          return acc;
        }, {});
    }
    if (newUserPermissions.dataviz_entidades) {
      newUserPermissions.dataviz_entidades =
        newUserPermissions.dataviz_entidades.reduce((acc, value) => {
          acc[value] = true;
          return acc;
        }, {});
    }

    requester.segmentation
      .updateUserServiceSegmentation(service, user.id, userPermissions[service])
      .then((response) => {
        getPermissions(service, true);

        requester.segmentation
          .updateUserServiceSegmentation(
            "trabalhista",
            user.id,
            userPermissions["trabalhista"],
          )
          .then((response) => {
            getPermissions("trabalhista", true);
            toaster.push(
              <Message
                closable
                showIcon
                duration={4000}
                type={"success"}
                header={"Dados Atualizados"}
              >
                Configuração atualizadas com sucesso!
              </Message>,
            );
          })

          .catch((err) => {
            setLoadSave((prevState) => ({
              ...prevState,
              [service]: false,
            }));
            console.log("trabalhista", err);
            toaster.push(
              <Message closable showIcon duration={4000} type={"error"}>
                Houve um problema de conexão, tente novamente em instantes.
              </Message>,
            );
          });
      })
      .catch((err) => {
        setLoadSave((prevState) => ({
          ...prevState,
          [service]: false,
        }));
        console.log("tributario", err);
        toaster.push(
          <Message closable showIcon duration={4000} type={"error"}>
            Houve um problema de conexão, tente novamente em instantes.
          </Message>,
        );
      });
  }

  function changePermissionValue(service, key, value) {
    let newUserPermission = userPermissions[service];

    newUserPermission[key] = value;

    setUserPermissions((prevState) => ({
      ...prevState,
      [service]: newUserPermission,
    }));
  }

  function groupArray(arr, groupBy) {
    let groupedArray = [];
    let categories = {};

    arr?.forEach((elem) => {
      categories[elem[groupBy]] = {};
      categories[elem[groupBy]].label = elem[groupBy];
      categories[elem[groupBy]].value = elem[groupBy];
    });
    Object.keys(categories).forEach((category, i) => {
      groupedArray.push([]);
      arr.forEach((elem) =>
        elem[groupBy] === category
          ? groupedArray[i].push({ value: elem.id, label: elem.initials })
          : null,
      );
      categories[category].children = groupedArray[i];
    });
    return Object.values(categories);
  }

  function onChangeEntities(service, key, value) {
    let entitiesFilter = [];

    value?.forEach((e) => {
      if (typeof e === "string") {
        entities.forEach((entity) => {
          if (entity.class === e) {
            entitiesFilter.push(entity.id);
          }
        });
      } else {
        entitiesFilter.push(e);
      }
    });

    changePermissionValue(service, key, entitiesFilter);
  }

  function onChangeEntitiesTrabalhista(service, key, value) {
    let entitiesFilter = [];

    value?.forEach((e) => {
      if (typeof e === "string") {
        entitiesTrabalhista.forEach((entity) => {
          if (entity.class === e) {
            entitiesFilter.push(entity.id);
          }
        });
      } else {
        entitiesFilter.push(e);
      }
    });

    changePermissionValue(service, key, entitiesFilter);
  }

  function onChangeObject(service, objKey, propKey, value) {
    let newUserPermission = userPermissions[service][objKey];

    newUserPermission[propKey] = value;
    changePermissionValue(service, objKey, newUserPermission);
  }

  useEffect(() => {
    getPlans();
    getPermissions();
  }, []);

  return (
    <div className="user-permissions-container">
      <Grid fluid className="grid-permissions-container">
        <Row>
          <Col xs={24} sm={24} md={6}>
            <label className="config-title">Status</label>
            <br />
            <UpdateStatusUserButton data={user} />
          </Col>
          <Col xs={24} sm={24} md={6}>
            <label className="config-title">Primeiro Acesso</label>
            <br />
            <CheckoutBlockButton data={user} />
          </Col>
          <Col xs={24} sm={24} md={6}>
            <label className="config-title">Administrador</label>
            <br />
            <UpdateAdminButton data={user} />
          </Col>
          <Col xs={24} sm={24} md={6}>
            <label className="config-title">BI Tributário</label>
            <br />
            <UpdateBIButton data={user} />
          </Col>
        </Row>
        <Col xs={24} sm={24} md={22}>
          {permissions.map((permissionKey, i) => {
            return (
              <TuriviusPanel
                expandedButton
                inside={false}
                loading={load[permissionKey]}
                key={permissionKey}
                hideToggleButton={!userPermissions[permissionKey]?.plano_alias}
                header={
                  <Grid fluid className="service-header">
                    <Row style={{ display: "flex", alignItems: "center" }}>
                      <Col xs={20} sm={20} md={7}>
                        <h6
                          className="service-title"
                          dangerouslySetInnerHTML={{
                            __html: getPermissionTitle(permissionKey),
                          }}
                        ></h6>
                      </Col>
                      <Col xs={24} sm={24} md={7}>
                        <SelectServicePicker
                          getPermissions={getPermissions}
                          service={permissionKey}
                          userPlan={userPermissions[permissionKey]?.plano_alias}
                          plans={plans}
                          user={user}
                        />
                      </Col>
                      <Col xs={24} sm={24} md={5}>
                        {users ? (
                          <CopyServiceConfigButton
                            service={permissionKey}
                            userPlan={
                              userPermissions[permissionKey]?.plano_alias
                            }
                            user={user}
                            users={users}
                            getPermissions={getPermissions}
                          />
                        ) : null}
                      </Col>
                      <Col xs={24} sm={24} md={5}>
                        {userPermissions[permissionKey]?.plano_alias ? (
                          <DeleteServiceButton
                            service={permissionKey}
                            user={user}
                            getPermissions={getPermissions}
                          />
                        ) : null}
                      </Col>
                    </Row>
                  </Grid>
                }
                content={
                  permissionKey === "tributario" ? (
                    <TributarioPermissions
                      permissionsTrib={userPermissions[permissionKey]}
                      permissionsTrab={userPermissions["trabalhista"]}
                      permissionKey={permissionKey}
                      changePermissionValue={changePermissionValue}
                      onChangeEntities={onChangeEntities}
                      groupArray={groupArray}
                      onChangeObject={onChangeObject}
                      updateUserServiceSegmentation={
                        updateUserServiceSegmentation
                      }
                      loadSave={loadSave[permissionKey]}
                      entities={entities}
                    />
                  ) : permissionKey === "bi_tributario" ? (
                    <BITributarioPermissions
                      permissions={userPermissions[permissionKey]}
                      permissionKey={permissionKey}
                      changePermissionValue={changePermissionValue}
                      onChangeEntities={onChangeEntities}
                      groupArray={groupArray}
                      onChangeObject={onChangeObject}
                      updateUserServiceSegmentation={
                        updateUserServiceSegmentation
                      }
                      loadSave={loadSave[permissionKey]}
                      entities={entities}
                    />
                  ) : // :
                  // permissionKey === 'trabalhista' ?
                  //   <TrabalhistaPermissions
                  //     permissions={userPermissions[permissionKey]}
                  //     permissionKey={permissionKey}
                  //     changePermissionValue={changePermissionValue}
                  //     onChangeEntities={onChangeEntitiesTrabalhista}
                  //     groupArray={groupArray}
                  //     onChangeObject={onChangeObject}
                  //     updateUserServiceSegmentation={updateUserServiceSegmentation}
                  //     loadSave={loadSave[permissionKey]}
                  //     entities={entitiesTrabalhista}
                  //   />
                  null
                }
              />
            );
          })}
        </Col>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => ({
  entities: state.base.entities,
  entitiesTrabalhista: state.setTrabalhista.entitiesTrabalhista,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    Object.assign({}, TributarioActions, TrabalhistaActions),
    dispatch,
  );

export default connect(mapStateToProps, {})(UserPermissions);
