import React, { useState, useRef } from "react";
import {
  Button,
  Form,
  Modal,
  CheckPicker,
  Schema,
  MaskedInput,
  RadioGroup,
  Radio,
  toaster,
  Message,
} from "rsuite";
import { areas } from "../../config/companies";
import { requester } from "../../requesters";
import { withRouter } from "react-router-dom";
import "./style.scss";

const model = Schema.Model({
  userName: Schema.Types.StringType().isRequired("Este campo é obrigatório."),
  userEmail: Schema.Types.StringType()
    .isEmail("Por favor, informe um e-mail válido.")
    .isRequired("Este campo é obrigatório."),
  companyName: Schema.Types.StringType().isRequired(
    "Este campo é obrigatório.",
  ),
  companyEmail: Schema.Types.StringType()
    .isEmail("Por favor, informe um e-mail válido.")
    .isRequired("Este campo é obrigatório."),
  cellphone: Schema.Types.StringType()
    .addRule((value, data) => {
      return !value.includes("_");
    }, "Informe um número válido")
    .isRequired("Preencha este campo."),

  lawArea: Schema.Types.ArrayType()
    .minLength(1, "Selecione pelo menos uma opção.")
    .isRequired("Este campo é obrigatório."),
});

const masks = {
  cellphone: [
    "+",
    "5",
    "5",
    " ",
    "(",
    /[1-9]/,
    /[1-9]/,
    ")",
    " ",
    /[1-9]/,
    /[1-9]/,
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    "-",
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
  ],
  telephone: [
    "+",
    "5",
    "5",
    " ",
    "(",
    /[1-9]/,
    /[1-9]/,
    ")",
    " ",
    /[1-9]/,
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    "-",
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
  ],
};

export const CreateNewCompanyButton = (props) => {
  const formRef = useRef();
  const [openModal, setOpenModal] = useState(false);
  const [formError, setFormError] = useState({});
  const [formValue, setFormValue] = useState({});
  const [contactType, setContactType] = useState("cellphone");
  const [loading, setLoading] = useState(false);

  function handleModal() {
    setOpenModal(!openModal);
  }

  const handleSubmit = () => {
    if (!formRef.current.check()) {
      console.error("Form Error");
      return;
    }
    setLoading(true);
    requester.admin
      .createCompanyWithAdminProfile(
        formValue.companyName,
        formValue.companyEmail,
        formValue.userName,
        formValue.userEmail,
        formValue.cellphone,
        formValue.lawArea,
        formValue.coupon,
      )
      .then((response) => {
        toaster.push(
          <Message closable duration={4000} type="success">
            Empresa criada com sucesso!
          </Message>,
        );
        props.history.push(`/empresas/${response.company.id}`);
      })
      .catch((err) => {
        console.error(err);

        if (err.status === 400) {
          toaster.push(
            <Message closable showIcon duration={7000} type="error">
              Uma conta com esse email de administrador já existe.
            </Message>,
          );
        } else {
          toaster.push(
            <Message closable duration={7000} type="error">
              Erro ao criar empresa. Entre em contato com o suporte para mais
              informações.
            </Message>,
          );
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div>
      <Button
        block
        className="btn-action-company"
        onClick={() => handleModal()}
      >
        Nova Empresa
      </Button>

      <Modal open={openModal} onClose={handleModal} size="md" backdrop="static">
        <Modal.Header>
          <h3>Nova Empresa</h3>
        </Modal.Header>

        <Modal.Body>
          <Form
            fluid
            ref={formRef}
            onChange={setFormValue}
            onCheck={setFormError}
            formValue={formValue}
            formError={formError}
            model={model}
            readOnly={loading}
          >
            <Form.Group controlId="userName">
              <Form.ControlLabel>Nome do Administrador</Form.ControlLabel>
              <Form.Control name="userName" />
            </Form.Group>
            <Form.Group controlId="userEmail">
              <Form.ControlLabel>E-mail de Administrador</Form.ControlLabel>
              <Form.Control name="userEmail" />
            </Form.Group>
            <Form.Group controlId="cellphone">
              <Form.ControlLabel>Telefone da Empresa</Form.ControlLabel>
              <RadioGroup
                onChange={setContactType}
                value={contactType}
                inline
                style={{
                  backgroundColor: "#eee",
                  borderRadius: 10,
                  marginLeft: 0,
                }}
              >
                <div
                  style={{
                    marginLeft: 8,
                    display: "inline-flex",
                    alignItems: "center",
                  }}
                >
                  <small>selecione o tipo de número</small>
                </div>
                <Radio value="cellphone">Celular</Radio>
                <Radio value="telephone">Telefone</Radio>
              </RadioGroup>
              <Form.Control
                name="cellphone"
                accepter={MaskedInput}
                mask={masks[contactType]}
                showMask
              />
            </Form.Group>
            <Form.Group controlId="companyName">
              <Form.ControlLabel>Nome da Empresa</Form.ControlLabel>
              <Form.Control name="companyName" />
            </Form.Group>
            <Form.Group controlId="companyEmail">
              <Form.ControlLabel>E-mail da Empresa</Form.ControlLabel>
              <Form.Control name="companyEmail" />
            </Form.Group>
            <Form.Group controlId="lawArea">
              <Form.ControlLabel>Área do Direiro</Form.ControlLabel>
              <Form.Control
                name="lawArea"
                accepter={CheckPicker}
                data={areas}
                searchable={false}
                block
              />
            </Form.Group>
            <Form.Group controlId="coupon">
              <Form.ControlLabel>Cupom</Form.ControlLabel>
              <Form.Control name="coupon" />
            </Form.Group>
          </Form>
        </Modal.Body>

        <Modal.Footer>
          <Button disabled={loading} onClick={() => handleModal()}>
            Cancelar
          </Button>
          <Button
            loading={loading}
            onClick={() => handleSubmit()}
            appearance="primary"
          >
            Criar Empresa
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default withRouter(CreateNewCompanyButton);
