import React, { useState } from "react";
import { faLayerGroup } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Turivius } from "../../ui";
import {
  Button,
  Modal,
  IconButton,
  Message,
} from "rsuite";

import TuriviusColors from "../../vars.module.scss";
import { read, utils } from "xlsx";
import { requester } from "../../requesters";
import "./style.scss";

const BulkCreateButton = ({ users }) => {
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errMsg, setErrMsg] = useState(null);
  const [successMsg, setSuccessMsg] = useState(null);

  function handleModal() {
    setErrMsg(null);
    setSuccessMsg(null);
    setOpenModal(!openModal);
  }
  function readUploadFile(e) {
    e.preventDefault();

    if (e.target.files) {
      setLoading(true);
      const reader = new FileReader();
      reader.onload = async (e) => {
        const data = e.target.result;
        const workbook = read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = utils.sheet_to_json(worksheet);
        console.log(json);
        var i = 0;
        const data1 = [];
        const data2 = [];
        const data3 = [];
        const data4 = [];
        json.map((item) => {
          if (i <= 25) {
            data1.push(item);
          } else if (i <= 50) {
            data2.push(item);
          } else if (i <= 75) {
            data3.push(item);
          } else if (i <= 100) {
            data4.push(item);
          } else {
            return;
          }
          i++;
        });

        try {
          if (data1.length > 0) {
            const res = await requester.profiles.createUserBulk(data1);
          }
          if (data2.length > 0) {
            const res = await requester.profiles.createUserBulk(data2);
          }
          if (data3.length > 0) {
            const res = await requester.profiles.createUserBulk(data3);
          }
          if (data4.length > 0) {
            const res = await requester.profiles.createUserBulk(data4);
          }

          setSuccessMsg("Operação concluída com Sucesso");
          setLoading(false);
        } catch (err) {
          console.log("erro", err);
          const dataMsg = [];
          err.message.map((item, i) => {
            if (item.email) {
              dataMsg.push(item.email);
            }
          });
          setErrMsg(dataMsg);
          setLoading(false);
        }
      };
      reader.readAsArrayBuffer(e.target.files[0]);
    }
  }

  return (
    <>
      <Turivius.TuriviusTooltip
        trigger="hover"
        placement="bottomEnd"
        message="Criação em Lote"
      >
        <IconButton
          block
          className="btn-update-bulk"
          onClick={handleModal}
          backgroundColor={TuriviusColors.turivius_light}
          color={TuriviusColors.white}
          icon={<FontAwesomeIcon className="rs-icon" icon={faLayerGroup} />}
        >
          Criação em Lote
        </IconButton>
      </Turivius.TuriviusTooltip>

      <Modal backdrop="static" open={openModal} onClose={handleModal}>
        <Modal.Header>
          <h4>Criação de Lote</h4>
          <Message showIcon type="info">
            <p className="mb-2">
              O limite atual é de <b>100 usuários</b> por planilha, fazemos o
              cadastro{" "}
              <b>25 por vez então a operação pode demorar mais que o normal.</b>
            </p>
          </Message>
        </Modal.Header>
        <Modal.Body>
          <div>
            {errMsg ? (
              <Message showIcon type="error">
                <h6 className="mb-2">
                  Refaça a operação removendo os erros abaixo
                </h6>
                {errMsg.map((item) => {
                  return (
                    <>
                      <small>{item}</small> <br />
                    </>
                  );
                })}
              </Message>
            ) : null}
            {loading ? (
              <h6>Cadastrando usuários...</h6>
            ) : successMsg ? (
              <Message showIcon type="success">
                <h6 className="mb-2">{successMsg}</h6>
              </Message>
            ) : (
              <form>
                <label htmlFor="upload">
                  Faça o upload do arquivo seguindo os padrões.
                </label>
                <br />
                <br />
                <input
                  type="file"
                  name="upload"
                  id="upload"
                  onChange={readUploadFile}
                />
              </form>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleModal}>Cancelar</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default BulkCreateButton;
