import React from "react";
import PropTypes from "prop-types";
import { Loader } from "rsuite";
import "./TuriviusPreloader.scss";

class TuriviusPreloader extends React.Component {
  render() {
    const { backdrop, style, source } = this.props;
    return (
      <div className={`turivius-preloader ${backdrop ? "backdrop" : ""}`}>
        <Loader
          vertical
          size="lg"
          className="turivius-spinner"
          style={style}
        ></Loader>
        <img
          className="turivius-spinner-image"
          src={source}
          alt="Carregando..."
        />
      </div>
    );
  }
}

TuriviusPreloader.propTypes = {
  backdrop: PropTypes.bool,
  style: PropTypes.object,
  source: PropTypes.string,
};

TuriviusPreloader.defaultProps = {
  backdrop: false,
  style: {},
  source: "/favicon.svg",
};

export default TuriviusPreloader;
