export const sessions = [
  "Jurisprudência",
  "Resultados",
  "Jurimetria Trabalhista",
  "Jurimetria Tributária",
  "Monitoramento",
  "Coleções",
  "Área Empresarial",
];

export const routes = [
  {
    name: "Jurisprudência",
    route: "jurisprudence",
  },
  {
    name: "Resultados",
    route: "results",
  },
  {
    name: "Jurimetria Trabalhista",
    route: "jurimetrics-trabalhist",
  },
  {
    name: "Jurimetria Tributária",
    route: "jurimetrics-tributaria",
  },
  { name: "Monitoramento", route: "monitoring" },
  { name: "Coleções", route: "collections" },
  {
    name: "Área Empresarial",
    route: "dashboard-empresarial",
  },
];

export function getRoute(session) {
  const routeFiltered = routes.filter((route) => route.name === session);

  return routeFiltered[0].route;
}
