import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Checkbox, Col, Grid, Row } from "rsuite";
import { faStarHalf } from "@fortawesome/free-solid-svg-icons";

// Componenets
import SimplePreloader from "../../loaders/SimplePreloader";
import TuriviusTooltip from "../../component-encapsulation/turivius-tooltip/TuriviusTooltip";

// Settings
import "./TuriviusMultipleSelection.scss";

class TuriviusMultipleSelection extends React.Component {
  // ({ helperText, labelKey, options, loading, context, filter, onClick, label, error, posthog, filterReference, placeholder, cols, ...props })
  state = {
    filters: null,
    selected: null,
  };

  selectionChange = (value) => {
    const { filterReference, setTmpFilters, posthog, context } = this.props;

    let { selected } = this.state;
    const selection = value;
    const findSelection = selected.indexOf(parseInt(selection));

    if (findSelection === -1) {
      selected = [...selected, parseInt(selection)];
    } else {
      selected.splice(findSelection, 1);
    }
    this.setState({ selected: [...selected] });

    setTmpFilters(filterReference, [...selected]);

    if (context) {
      posthog.capture(context, {
        action: "change_filter",
        id: filterReference,
      });
    }
  };

  globalSelection = (action) => {
    const { filterReference, setTmpFilters, posthog, options, context } =
      this.props;
    let { selected } = this.state;

    if (action === "remove") {
      selected = [];
    } else {
      selected = options?.map((item) => item.id);
    }
    this.setState({ selected: [...selected] });
    setTmpFilters(filterReference, [...selected]);
    if (context) {
      posthog.capture(context, {
        action: "change_filter",
        id: filterReference,
      });
    }
  };

  componentWillUnmount() {
    this.mounted = false;
  }

  componentDidMount() {
    const { filtersX, filterReference } = this.props;
    this.mounted = true;

    if (this.mounted) {
      this.setState({
        filters: filtersX[filterReference],
        selected: filtersX[filterReference] || [],
      });
    }
  }

  componentDidUpdate() {
    const { filtersX, filterReference } = this.props;
    const propsFilters = filtersX && filtersX[filterReference];
    const stateFilters = this.state && this.state.filters;

    if (!stateFilters && propsFilters && this.mounted) {
      this.setState({
        filters: [...propsFilters],
        selected: [...propsFilters],
      });
    } else if (
      stateFilters &&
      propsFilters &&
      stateFilters.length !== propsFilters.length &&
      this.mounted
    ) {
      this.setState({
        filters: null,
        selected: null,
      });
      setTimeout(() => {
        this.setState({
          filters: [...propsFilters],
          selected: [...propsFilters],
        });
      }, 100);
    } else if (
      Array.isArray(propsFilters) &&
      Array.isArray(stateFilters) &&
      this.mounted
    ) {
      let doUpdate = false;
      propsFilters.forEach((propFilter) => {
        const found = stateFilters.filter((stateFilter) => {
          return (
            (typeof stateFilter === "object"
              ? parseInt(stateFilter.id)
              : parseInt(stateFilter)) ===
            (typeof propFilter === "object"
              ? parseInt(propFilter.id)
              : parseInt(propFilter))
          );
        }).length;
        if (found === 0) {
          doUpdate = true;
        }
      });
      if (doUpdate && this.mounted) {
        this.setState({
          filters: null,
          selected: null,
        });
        setTimeout(() => {
          this.setState({
            filters: [...propsFilters],
            selected: [...propsFilters],
          });
        }, 100);
      }
    } else if (
      (propsFilters === undefined || propsFilters === null) &&
      (stateFilters === undefined ||
        stateFilters === null ||
        (Array.isArray(stateFilters) && stateFilters.length !== 0))
    ) {
      this.setState({
        filters: [],
        selected: [],
      });
    }
  }

  render() {
    const { onClick, label, loading, helperText, labelKey, options, cols } =
      this.props;
    const { selected } = this.state;
    return (
      <div className="turivius-checkbox-group-filter" onClick={onClick}>
        <div className="title">
          <label>{label}</label>
        </div>

        <div className={`checkbox-input-field ${loading ? "loading" : null}`}>
          {loading ? (
            <SimplePreloader />
          ) : options.length > 0 ? (
            <Grid fluid>
              <Row>
                <Col xs={24} sm={24}>
                  <div className="checkbox-group">
                    <label className="checkbox-group-category-label">
                      Global
                    </label>
                    <p
                      className="checkbox-group-category-action"
                      onClick={() => this.globalSelection("add")}
                    >
                      selecionar todos
                    </p>
                    <p
                      className="checkbox-group-category-action"
                      onClick={() => this.globalSelection("remove")}
                    >
                      remover todos
                    </p>
                  </div>
                </Col>

                {options?.map((elem, i) => (
                  <Col
                    xs={cols ? 24 / cols : 12}
                    sm={cols ? 24 / cols : 12}
                    md={cols ? 24 / cols : 12}
                    key={1}
                  >
                    <Checkbox
                      value={`${elem.id}`}
                      onChange={this.selectionChange}
                      checked={
                        selected
                          ? selected.filter((option) =>
                              typeof option === "object"
                                ? option.id === elem.id
                                : option === elem.id,
                            ).length > 0
                          : false
                      }
                    >
                      <div className="checkbox-group-label-container">
                        <div className="first-line">
                          {elem.is_new ? (
                            <TuriviusTooltip
                              trigger="hover"
                              message="Base de dados nova!"
                              placement="right"
                            >
                              <div>
                                <span className="new-badge">new</span>
                              </div>
                            </TuriviusTooltip>
                          ) : elem.is_partial ? (
                            <TuriviusTooltip
                              trigger="hover"
                              message="Base de dados parcial"
                              placement="right"
                            >
                              <div>
                                <FontAwesomeIcon
                                  icon={faStarHalf}
                                  className="tiny partial"
                                />
                              </div>
                            </TuriviusTooltip>
                          ) : null}
                          <div>
                            {elem[labelKey]
                              .replace("(novo)", "")
                              .replace("(parcial)", "")}
                          </div>
                        </div>
                      </div>
                    </Checkbox>
                  </Col>
                ))}
              </Row>
            </Grid>
          ) : null}
        </div>
        {helperText ? (
          <div className="input-field-header" style={{ marginBottom: 12 }}>
            {helperText}
          </div>
        ) : null}
      </div>
    );
  }
}

TuriviusMultipleSelection.propTypes = {
  context: PropTypes.string,
  label: PropTypes.string,
  loading: PropTypes.bool,
  labelKey: PropTypes.string,
  options: PropTypes.object,
  helperText: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  onClick: PropTypes.func,
  filterReference: PropTypes.string,

  // REDUX
  filters: PropTypes.object,
};

TuriviusMultipleSelection.defaultProps = {
  context: "Home",
  labelKey: "name",
};

export default TuriviusMultipleSelection;
