import { reports } from "./reports";
import { request } from "./requester";

// Admin endpoints are mostly restrict endpoints from other microservices

// ########## BEGIN PROFILES ##########
function getUserById(userId) {
  return request({
    type: "GET",
    action: `profiles/${userId}`,
    microservice: "profiles",
    auth: "token",
  }).then((response) => {
    return response;
  });
}

function getUsers({ companyId, genericFilter, page, pageSize, ordering }) {
  let data = {
    company_id: companyId,
    generic_filter: genericFilter,
    page_size: pageSize,
    ordering,
    page,
  };
  Object.keys(data).forEach(
    (key) => data[key] === undefined && delete data[key],
  );
  return request({
    type: "GET",
    action: `profiles/`,
    microservice: "profiles",
    auth: "token",
    data,
  }).then((response) => {
    return response;
  });
}

function getUsersByCompany({ companyId, page }) {
  return request({
    type: "GET",
    action: `api/profiles/?company_id=${companyId}&page=${page}`,
    microservice: "profiles",
    auth: "token",
  }).then((response) => {
    return response;
  });
}

function getCompanyById(companyId) {
  return request({
    type: "GET",
    action: `company/${companyId}`,
    microservice: "profiles",
    auth: "token",
  }).then((response) => {
    return response;
  });
}

function getCompanies({ page, pageSize, ordering, genericFilter }) {
  let data = {
    generic_filter: genericFilter,
    page_size: pageSize,
    ordering,
    page,
  };
  Object.keys(data).forEach(
    (key) => data[key] === undefined && delete data[key],
  );
  return request({
    type: "GET",
    action: `company/`,
    microservice: "profiles",
    auth: "token",
    data,
  }).then((response) => {
    return response;
  });
}

function createUser(
  companyId,
  companyName,
  companyCellphone,
  email,
  name,
  position,
) {
  return request({
    type: "POST",
    action: `profiles/`,
    microservice: "profiles",
    auth: "token",
    data: {
      company: companyId,
      email,
      name,
      position,
    },
  }).then((response) => {
    reports
      .reportToRDStation({
        event_type: "CONVERSION",
        event_family: "CDP",
        payload: {
          name: name,
          email: email,
          mobile_phone: companyCellphone,
          tags: ["new_user"],
          conversion_identifier: "Admin Dashboard Added New User to Company",
          company_name: companyName,
        },
      })
      .then((rdResponse) => console.log(rdResponse))
      .catch((err) => console.error(err));
    return response;
  });
}

function createCompanyWithAdminProfile(
  companyName,
  companyEmail,
  adminName,
  adminEmail,
  cellphone,
  lawArea = [],
  coupon,
) {
  return request({
    type: "POST",
    action: `profiles/create_complex/`,
    microservice: "profiles",
    auth: "token",
    data: {
      company_name: companyName,
      company_email: companyEmail,
      user_name: adminName,
      user_email: adminEmail,
      law_area: lawArea,
      cellphone,
      coupon,
    },
  }).then((response) => {
    reports
      .reportToRDStation({
        event_type: "CONVERSION",
        event_family: "CDP",
        payload: {
          name: response.profile.name,
          email: response.profile.email,
          mobile_phone: response.company.cellphone,
          tags: [...response.profile.law_area, "account_admin"],
          conversion_identifier: "Admin Dashboard",
          company_name: response.company.name,
        },
      })
      .then((rdResponse) => console.log(rdResponse))
      .catch((err) => console.error(err));
    return response;
  });
}

function updateUser(userId, data) {
  return request({
    type: "PATCH",
    action: `profiles/${userId}`,
    microservice: "profiles",
    auth: "token",
    data: {
      ...data,
    },
  }).then((response) => {
    return response;
  });
}

function updateCompany(companyId, data) {
  return request({
    type: "PATCH",
    action: `company/${companyId}`,
    microservice: "profiles",
    auth: "token",
    data: {
      ...data,
    },
  }).then((response) => {
    return response;
  });
}

function changeUserCompany(userId, targetCompany) {
  return request({
    type: "PATCH",
    action: `profiles/change_company/`,
    microservice: "profiles",
    auth: "token",
    data: {
      user: userId,
      target_company: targetCompany,
    },
  }).then((response) => {
    return response;
  });
}

function toggleUserActivity(userId) {
  return request({
    type: "PATCH",
    action: `profiles/${userId}/activity`,
    microservice: "profiles",
    auth: "token",
  }).then((response) => {
    return response;
  });
}

function toggleUserAdmin(userId, isAdmin) {
  return request({
    type: "PATCH",
    action: `profiles/${userId}/is_admin/`,
    microservice: "profiles",
    auth: "token",
    data: {
      is_admin: isAdmin,
    },
  }).then((response) => {
    return response;
  });
}

function toggleCompanyActivity(companyId, blocked) {
  return request({
    type: "PATCH",
    action: `company/${companyId}/block/`,
    microservice: "profiles",
    auth: "token",
    data: {
      id: companyId,
      blocked: blocked,
    },
  }).then((response) => {
    return response;
  });
}

function deleteUser(userId) {
  return request({
    type: "DELETE",
    action: `profiles/${userId}`,
    microservice: "profiles",
    auth: "token",
  }).then((response) => {
    return response;
  });
}

function deleteCompany(companyId) {
  return request({
    type: "DELETE",
    action: `company/${companyId}`,
    microservice: "profiles",
    auth: "token",
  }).then((response) => {
    return response;
  });
}
// ########## END PROFILES ##########

// ########## BEGIN MONITORING ##########
function getMonitoredTermsByUserId(userId) {
  return request({
    type: "GET",
    microservice: "monitoring",
    action: `monitored_terms_admin/?user_id=${userId}`,
    auth: "token",
  }).then((monitoring) => {
    return monitoring;
  });
}
// ########## END MONITORING ##########

// ########## BEGIN DASHBOARDS ##########
function enableUserBITributario(userId) {
  return request({
    type: "POST",
    action: `simpleuser/`,
    microservice: "dashboards",
    auth: "token",
    data: {
      user_id: userId,
    },
  }).then((response) => {
    return response;
  });
}

function disableUserBITributario(userId) {
  return request({
    type: "DELETE",
    action: `simpleuser/${userId}/`,
    microservice: "dashboards",
    auth: "token",
  }).then((response) => {
    return response;
  });
}
// ########## END DASHBOARDS ##########

// ########## BEGIN CARDS ##########
function getSearchHistoryById(userId) {
  return request({
    type: "GET",
    microservice: "cards",
    action: `search_history/${userId}`,
    auth: "token",
  }).then((history) => {
    return history;
  });
}
// ########## END CARDS ##########

// ########## BEGIN WEBSERVICES ##########
function resendFirstPasswordEmail(userId, email, cc = [], bcc = []) {
  return request({
    type: "POST",
    action: `platform/${userId}/set_password_email/`,
    microservice: "webservice",
    auth: "token",
    data: {
      email,
      cc,
      bcc,
    },
  }).then((response) => {
    return response;
  });
}
// ########## END WEBSERVICES ##########

// ########## BEGIN PAYMENT ##########
function updatePaymentURL(companyId, paymentURL) {
  return request({
    type: "PATCH",
    action: `update_payment/${companyId}`,
    microservice: "profiles",
    auth: "token",
    data: {
      asaas_url: paymentURL,
    },
  }).then((response) => {
    return response;
  });
}
// ########## END PAYMENT ##########

// ########## BEGIN CHECKOUT ##########
function getCheckoutBlockById(userId) {
  return request({
    type: "GET",
    action: `checkoutblock/${userId}`,
    microservice: "segmentation",
    auth: "token",
  }).then((response) => {
    return response;
  });
}
function blockCheckoutById(userId) {
  return request({
    type: "PATCH",
    action: `admin/checkoutblock/${userId}`,
    microservice: "segmentation",
    auth: "token",
  }).then((response) => {
    return response;
  });
}
// ########## END CHECKOUT ##########

// ########## BEGIN FINGERPRINTS ##########
function deleteUserSession(userId) {
  return request({
    type: "DELETE",
    microservice: "segmentation",
    action: `/admin/fingerprints/${userId}`,
    auth: "token",
  }).then((response) => {
    return response;
  });
}

export const admin = {
  getUserById,
  getUsers,
  getUsersByCompany,
  getCompanyById,
  getCompanies,
  createUser,
  createCompanyWithAdminProfile,
  updateUser,
  updateCompany,
  changeUserCompany,
  toggleUserActivity,
  toggleUserAdmin,
  toggleCompanyActivity,
  deleteUser,
  deleteCompany,
  enableUserBITributario,
  disableUserBITributario,
  resendFirstPasswordEmail,
  getMonitoredTermsByUserId,
  getSearchHistoryById,
  updatePaymentURL,
  getCheckoutBlockById,
  blockCheckoutById,
  deleteUserSession,
};
