import React, { useEffect, useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock, faLockOpen } from "@fortawesome/free-solid-svg-icons";
import {
  Modal,
  Button,
  Whisper,
  Tooltip,
  IconButton,
  toaster,
  Message,
} from "rsuite";
import { requester } from "../../requesters";

import "./style.scss";

const CheckoutBlockButton = ({ data }) => {
  const modalRef = useRef();
  const [openModal, setOpenModal] = useState(false);
  const [load, setLoad] = useState(false);
  const [isBlocked, setIsBlocked] = useState(null);

  function handleModal() {
    setOpenModal(!openModal);
  }

  function toggleUserAdmin() {
    const { id } = data;

    setLoad(true);

    requester.admin
      .blockCheckoutById(id)
      .then(() => {
        requester.admin
          .resendFirstPasswordEmail(
            data.id,
            data.email,
            [],
            []
          )
          .then(() => {
            setIsBlocked(!isBlocked);
            handleModal();
            toaster.push(
              <Message showIcon type="success" duration={4000}>
                Liberação de primeiro acesso realizado com sucesso!
              </Message>,
            );
          })
          .catch((err) => {
            toaster.push(
              <Message closable duration={5000} type="error">
                Erro ao enviar o e-mail: {err.message.errors[0]}
              </Message>,
            );
          })
      })
      .catch((err) => {
        console.error(err);
        toaster.push(
          <Message showIcon type="error" duration={4000}>
            Ocorreu um erro, tente novamente ou entre em contato com o suporte.
          </Message>,
        );
      })
      .finally(() => {
        setLoad(false);
      });
  }

  function getPermissions() {
    requester.admin
      .getCheckoutBlockById(data.id)
      .then((res) => {
        setIsBlocked(res[1]);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    getPermissions();
  }, [data]);

  return (
    <div className="status-container">
      <Whisper
        trigger="hover"
        placement={"bottomEnd"}
        controlId={`control-id-${isBlocked}`}
        speaker={
          <Tooltip>{`Clique aqui para liberar o primeiro acesso do usuário ${data.name}`}</Tooltip>
        }
      >
        <IconButton
          block
          className={`btn-alter-status ${isBlocked ? "inactive" : "active"}`}
          onClick={handleModal}
          icon={
            <FontAwesomeIcon
              className="rs-icon"
              icon={isBlocked ? faLock : faLockOpen}
            />
          }
          disabled={!isBlocked}
        >
          {isBlocked ? "Bloqueado" : "Liberado"}
        </IconButton>
      </Whisper>

      <Modal ref={modalRef} open={openModal} onClose={handleModal}>
        <Modal.Header>
          <h4>Confirmar Ação</h4>
        </Modal.Header>
        <Modal.Body>
          <p>
            {`Deseja liberar o primeiro acesso ao sistema para o usuário ${data.name}?`}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleModal}>Cancelar</Button>
          <Button loading={load} onClick={toggleUserAdmin}>
            Confirmar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CheckoutBlockButton;
