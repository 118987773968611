import React from "react";
import { Container, Content } from "rsuite";
import PropTypes from "prop-types";

const TuriviusBasePage = ({ children, sidenav, additionalChildren }) => (
  <div>
    {additionalChildren}
    <Container>
      {sidenav}
      <Content>{children}</Content>
    </Container>
  </div>
);

TuriviusBasePage.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  additionalChildren: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  sidenav: PropTypes.node.isRequired,
};

TuriviusBasePage.defaultProps = {
  additionalChildren: null,
  sidenav: null,
  children: null,
};

export default TuriviusBasePage;
