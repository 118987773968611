import React, { useEffect, useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faXmark } from "@fortawesome/free-solid-svg-icons";
import {
  Modal,
  Button,
  Whisper,
  Tooltip,
  IconButton,
  toaster,
  Message,
} from "rsuite";
import { requester } from "../../requesters";

//Config
import TuriviusColors from "../../vars.module.scss";
import "./style.scss";

const UpdateAdminButton = ({ data, table }) => {
  const modalRef = useRef();
  const [openModal, setOpenModal] = useState(false);
  const [load, setLoad] = useState(false);
  const [isAdmin, setIsAdmin] = useState(null);

  function handleModal() {
    setOpenModal(!openModal);
  }

  function setStatusItem() {
    switch (isAdmin) {
      case true:
        return "sim";
      case false:
        return "não";
      default:
        return "--";
    }
  }

  function toggleUserAdmin() {
    const { id } = data;

    setLoad(true);

    requester.admin
      .toggleUserAdmin(id, !isAdmin)
      .then((response) => {
        setIsAdmin(!isAdmin);
        handleModal();
        toaster.push(
          <Message showIcon type="success" duration={4000}>
            {`As configuração de administrador foram ${!isAdmin ? "ativadas" : "revogadas"}.`}
          </Message>,
        );
      })
      .catch((err) => {
        console.error(err);
        toaster.push(
          <Message showIcon type="error" duration={4000}>
            Ocorreu um erro, tente novamente ou entre em contato com o suporte.
          </Message>,
        );
      })
      .finally(() => {
        setLoad(false);
      });
  }

  useEffect(() => {
    const { is_admin } = data;
    setIsAdmin(is_admin);
  }, [data]);

  return (
    <div className="status-container">
      <Whisper
        trigger="hover"
        placement={table ? "top" : "bottomEnd"}
        controlId={`control-id-${isAdmin}`}
        speaker={
          <Tooltip>{`Clique aqui para ${isAdmin ? "revogar" : "ativar"} permissões de administrador para ${data.name}`}</Tooltip>
        }
      >
        {table ? (
          <Button className="status-button" onClick={handleModal}>
            <FontAwesomeIcon
              style={{ marginRight: "10px" }}
              color={isAdmin ? TuriviusColors.green : TuriviusColors.red}
              size="lg"
              icon={isAdmin ? faCheck : faXmark}
            />

            <p
              className={`status ${setStatusItem() === "sim" ? "ativo" : "bloqueado"}`}
            >
              {setStatusItem()}
            </p>
          </Button>
        ) : (
          <IconButton
            block
            className={`btn-alter-status ${isAdmin ? "active" : "inactive"}`}
            onClick={handleModal}
            icon={
              <FontAwesomeIcon
                className="rs-icon"
                icon={isAdmin ? faCheck : faXmark}
              />
            }
          >
            {isAdmin ? "Sim" : "Não"}
          </IconButton>
        )}
      </Whisper>

      <Modal ref={modalRef} open={openModal} onClose={handleModal}>
        <Modal.Header>
          <h4>Confirmar Ação</h4>
        </Modal.Header>
        <Modal.Body>
          <p>
            {`Deseja ${isAdmin ? "regovar" : "ativar"} as permissões de administrador para ${data.name}?`}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleModal}>Cancelar</Button>
          <Button loading={load} onClick={toggleUserAdmin}>
            Confirmar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default UpdateAdminButton;
