import React, { useState, useEffect } from "react";
import {
  faFileArrowDown,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton, Modal, Button } from "rsuite";
import { Turivius } from "../../ui";
import "./style.scss";

import { CSVLink } from "react-csv";

const headers = [
  { label: "ID", key: "user" },
  { label: "Termo", key: "query" },
  { label: "Filtro - Data Inicial", key: "data_range_0" },
  { label: "Filtro - Data Final", key: "data_range_1" },
  { label: "Entidades", key: "entity" },
  { label: "Data/Hora", key: "dt" },
];

const ExportSearchDataButton = ({ history, userName, getUserHistory }) => {
  const [openModal, setOpenModal] = useState(false);
  const [csvData, setCsvData] = useState([]);

  function handleModal() {
    setOpenModal(!openModal);
  }

  async function exportUserData() {
    let usersWithPermissions = history.map((search) => {
      return {
        user: search.user,
        query: search.query,
        email: search.email,
        data_range_0: search.filters.date_range["0"],
        data_range_1: search.filters.date_range["1"],
        entity: search.filters.entity,
        dt: search.dt,
      };
    });
    // console.table(usersWithPermissions, ["id", "name", "email", "company_id", "is_admin", "created_at", ...permissionKeys])
    setCsvData(usersWithPermissions);
    handleModal();
    console.log(history);
  }

  useEffect(() => {
    if (history === null) {
      getUserHistory();
    }
  }, []);

  return (
    <>
      <Turivius.TuriviusTooltip
        trigger="hover"
        placement="left"
        message={`Clique aqui para exportar o histórico do usuário ${userName}`}
      >
        <IconButton
          block
          className="btn-export-data"
          onClick={() => {
            handleModal();
            exportUserData();
          }}
          icon={<FontAwesomeIcon className="rs-icon" icon={faFileArrowDown} />}
          loading={history === null}
        >
          Exportar
        </IconButton>
      </Turivius.TuriviusTooltip>

      <Modal open={openModal} onClose={handleModal}>
        <Modal.Header>
          <h4>Exportar de Dados</h4>
        </Modal.Header>
        <Modal.Body>
          <p style={{ fontSize: 15 }}>
            Deseja gerar um arquivo <b>.xls</b> contendo o historico de pesquiso
            do usuário {userName}?{" "}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleModal}>Cancelar</Button>
          <Button onClick={exportUserData}>
            <CSVLink
              filename={`${userName} - Histórico de Pesquisa.xls`}
              data={csvData}
              headers={headers}
              separator={";"}
              style={{ textDecoration: "none", color: "#060606" }}
            >
              Exportar
            </CSVLink>
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ExportSearchDataButton;
