import React from "react";
import { remove } from "../../services/academy";
import { Button } from "rsuite";
import { Trash } from "iconsax-react";

const DeleteQuestion = ({ questionId, getQuestions }) => {
  async function onDeleteQuestion() {
    const deleteQuestion = await remove(questionId);

    getQuestions();
    console.log(deleteQuestion);
  }

  return (
    <Button
      onClick={onDeleteQuestion}
      className={`question-action-button delete`}
    >
      <Trash size="18" color="#Fff" />
      Deletar
    </Button>
  );
};

export default DeleteQuestion;
