import React, { useState } from "react";
import { Button, Message, Modal, toaster } from "rsuite";
import { requester } from "../../requesters";
import "./style.scss";

const DeleteUserSessionsButton = ({ userId }) => {
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);

  const deleteSessions = () => {
    setLoading(true);

    requester.admin
      .deleteUserSession(userId)
      .then((res) => {
        console.log(res);
        toaster.push(
          <Message header="Sessão deletadas" type={"success"} duration={5000}>
            Sessões do usuário foram deletadas com sucesso!
          </Message>,
        );
        handleModal();
      })
      .catch((err) => {
        console.log(err);
        toaster.push(
          <Message
            header="Erro ao deletar sessão"
            type={"error"}
            duration={5000}
          >
            Ocorreu um erro ao deletar a sessão deste usuário. Entre em contato
            com o suporte.
          </Message>,
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleModal = () => setModal(!modal);

  return (
    <>
      <Button block className="btn-confirm" onClick={handleModal}>
        Limpar Sessões
      </Button>

      <Modal open={modal} onClose={handleModal}>
        <Modal.Header>
          <h5> Limpar sessões do usuário </h5>
        </Modal.Header>
        <Modal.Body>
          <p>Tem certeza que deseja limpar as sessões desse usuário?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleModal} className="btn-cancel">
            Cancelar
          </Button>
          <Button
            onClick={deleteSessions}
            className="btn-confirm"
            loading={loading}
          >
            Confirmar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DeleteUserSessionsButton;
