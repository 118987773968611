import React from "react";
import { Col, Form, Grid, Input, InputPicker, Row, Toggle } from "rsuite";
import { sessions } from "./Settings";

const Textarea = React.forwardRef((props, ref) => (
  <Input {...props} as="textarea" ref={ref} />
));

const StatusToggle = React.forwardRef((props, ref) => {
  console.log(props);
  return (
    <Toggle
      size="lg"
      checked={props.value}
      {...props}
      ref={ref}
      checkedChildren="Ativo"
      unCheckedChildren="Inativo"
    />
  );
});

const selectData = sessions.map((item) => ({
  label: item,
  value: item,
}));

const FormUpdate = ({
  formRef,
  data,
  model,
  formValue,
  formDefaultValue,
  formError,
  setFormValue,
  setFormError,
}) => {
  console.log(formDefaultValue);
  return (
    <Form
      fluid
      ref={formRef}
      model={model}
      formValue={formValue}
      formError={formError}
      formDefaultValue={formDefaultValue}
      onChange={setFormValue}
      onCheck={setFormError}
    >
      <Form.Group controlId="actived">
        <Form.ControlLabel>Status</Form.ControlLabel>
        <Form.Control name="actived" accepter={StatusToggle} />
      </Form.Group>

      <Form.Group controlId="session">
        <Form.ControlLabel>Sessão</Form.ControlLabel>
        <Form.Control name="session" accepter={InputPicker} data={selectData} />
        <Form.HelpText>Sessão é obrigatório</Form.HelpText>
      </Form.Group>

      <Form.Group controlId="query">
        <Form.ControlLabel>Pergunta</Form.ControlLabel>
        <Form.Control name="query" />
        <Form.HelpText>Pergunta é obrigatório</Form.HelpText>
      </Form.Group>

      <Form.Group controlId="answer">
        <Form.ControlLabel>Resposta (Texto)</Form.ControlLabel>
        <Form.Control name="answer" rows={5} accepter={Textarea} />
      </Form.Group>

      <Form.Group controlId="videoURL">
        <Form.ControlLabel>Resposta (Youtube link)</Form.ControlLabel>
        <Form.Control name="videoURL" />
      </Form.Group>
      <Grid fluid>
        <Row>
          <Col sm={12}>
            <Form.Group controlId="user_updated">
              <Form.ControlLabel>Atualizado por:</Form.ControlLabel>
              <Input
                disabled
                value={
                  data.user_update
                    ? `${data?.user_update?.userId} - ${data?.user_update?.userName}`
                    : null
                }
              />
            </Form.Group>
          </Col>

          <Col sm={12}>
            <Form.Group controlId="last_updated">
              <Form.ControlLabel>Atualizado em:</Form.ControlLabel>
              <Input disabled value={data?.updated_at} />
            </Form.Group>
          </Col>
        </Row>

        <Row style={{ marginTop: 15 }}>
          <Col sm={12}>
            <Form.Group controlId="user_create">
              <Form.ControlLabel>Criado por:</Form.ControlLabel>
              <Input
                disabled
                value={`${data?.user_create.userId} - ${data?.user_create.userName}`}
              />
            </Form.Group>
          </Col>
          <Col sm={12}>
            <Form.Group controlId="created_at">
              <Form.ControlLabel>Criado em:</Form.ControlLabel>
              <Input disabled value={data.created_at} />
            </Form.Group>
          </Col>
        </Row>
      </Grid>
    </Form>
  );
};

export default FormUpdate;
