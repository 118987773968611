export const getCurrentDateHour = () => {
  const locale = "pt-br";
  const option = {
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  };
  const currentDate = new Date().toLocaleDateString(locale, option);

  return currentDate.replace(",", " às");
};
