import React from "react";
import { Redirect, Route } from "react-router-dom";
import { requester } from "../../requesters";
import { Turivius } from "../../ui";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Creators as TributarioActions } from "../../store/ducks/base";
import { Creators as TrabalhistaActions } from "../../store/ducks/setTrabalhista";

class PrivateRoute extends React.Component {
  state = {
    profile: null,
    requestingProfile: false,
  };

  getProfile(userId) {
    this.setState({ requestingProfile: true });
    requester.profiles
      .getById(userId)
      .then((response) => {
        localStorage.setItem("profile", JSON.stringify(response));
        localStorage.setItem("company", response.company.id);
        this.setState({ profile: response });
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        this.setState({ requestingProfile: false });
      });
  }

  getEntities() {
    requester.cards
      .getEntities()
      .then((entities) => {
        this.props.setEntities(entities);
      })
      .catch((err) => {
        console.error(err);
      });

    requester.cards
      .getEntities("trabalhista")
      .then((entities) => {
        this.props.setEntitiesTrabalhista(entities);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  componentDidMount() {
    const userId = localStorage.getItem("user");
    if (!this.state.profile) {
      this.getProfile(userId);
    }
    if (this.props.entities.length === 0) {
      this.getEntities();
    }
  }

  render() {
    const { profile, requestingProfile } = this.state;
    const { component: Component, ...rest } = this.props;

    return (
      <Route
        {...rest}
        render={(props) =>
          localStorage.getItem("token") ? (
            profile?.is_mega_user ? (
              <Component {...props} />
            ) : requestingProfile ? (
              <Turivius.TuriviusPreloader backdrop />
            ) : null
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: props.location },
              }}
            />
          )
        }
      />
    );
  }
}

const mapStateToProps = (state) => ({
  entities: state.base.entities,
  entitiesTrabalhista: state.setTrabalhista.entitiesTrabalhista,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    Object.assign({}, TributarioActions, TrabalhistaActions),
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);
