import { request } from "./requester";

function getDashboard(query, companyId) {
  return request({
    type: "GET",
    microservice: "nestle",
    action: query
      ? "dashboard/" + companyId + "?" + query
      : "dashboard/" + companyId,
    auth: "token",
  }).then((data) => {
    return data;
  });
}

function getDashboardSubject(assunto, companyId) {
  return request({
    type: "GET",
    microservice: "nestle",
    action: "dashboard/" + companyId + "?query=" + assunto + "&filters={}",
    auth: "token",
  }).then((data) => {
    return data;
  });
}

function getAcordaos(query, companyId) {
  return request({
    type: "GET",
    microservice: "nestle",
    action: query
      ? "dashboard/acordaos/" + companyId + "?" + query
      : "dashboard/acordaos/" + companyId,
    auth: "token",
  }).then((acordaos) => {
    return acordaos;
  });
}

function getFilters(companyId) {
  return request({
    type: "GET",
    microservice: "nestle",
    action: "dashboard/filters/" + companyId,
    auth: "token",
  }).then((data) => {
    return data;
  });
}

function getSubjects(companyId) {
  return request({
    type: "GET",
    microservice: "nestle",
    action: "subjects/" + companyId,
    auth: "token",
  }).then((data) => {
    return data;
  });
}

export const nestle = {
  getDashboard,
  getAcordaos,
  getFilters,
  getDashboardSubject,
  getSubjects,
};
