import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { toaster, Message } from "rsuite";
import "./style.scss";
import { requester } from "../../requesters";
import TuriviusLogin from "../../core/TuriviusLogin/TuriviusLogin";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

const Login = (props) => {
  const [load, setLoad] = useState(false);
  const [page, setPage] = useState("login");

  const login = async (email, password) => {
    setLoad(true);
    //login firebase
    const auth = getAuth();
    signInWithEmailAndPassword(auth, "vitor@turivius.com", "Turivius@2023")
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        // ...
      })
      .catch((error) => {
        const errorCode = error?.code;
        const errorMessage = error?.message;
        console.log(errorCode, errorMessage);
      });
    //FIM login firebase

    requester.authentication
      .login(email, password)
      .then((response) => {
        localStorage.setItem("token", response.token);
        localStorage.setItem("user", response.id);
        requester.setUserToken(response.token);
        return requester.profiles.getById(response.id);
      })
      .then((profile) => {
        if (profile.is_mega_user) {
          props.history.push("/empresas");
        } else {
          toaster.push(
            <Message duration={5000} type={"error"} showIcon closable>
              Acesso não permitido para este usuário
            </Message>,
          );
        }
      })
      .catch((err) => {
        switch (err.status) {
          case 400:
            toaster.push(
              <Message
                showIcon
                closable
                duration={5000}
                type={"error"}
                header={"Dados incorretos"}
              >
                E-mail ou senha incorretos. Verifique e tente novamente.
              </Message>,
            );
            break;

          default:
            toaster.push(
              <Message showIcon closable duration={5000} type={"error"}>
                Houve um problema de conexão, tente novamente em instantes.
              </Message>,
            );
            break;
        }
      })
      .finally(() => {
        setLoad(false);
      });
  };

  function forgotPassword(email) {
    setLoad(true);
    requester.authentication
      .recoverPassword(email)
      .then((response) => {
        toaster.push(
          <Message showIcon closable duration={5000} type={"success"}>
            Um e-mail foi enviado com instruções de recuperação!
          </Message>,
        );
      })
      .catch((err) => {
        switch (err.status) {
          case 400:
            toaster.push(
              <Message showIcon closable duration={5000} type={"error"}>
                Não foi possivel alterar sua senha. Verifique as informações e
                tente novamente.
              </Message>,
            );
            break;

          default:
            toaster.push(
              <Message showIcon closable duration={5000} type={"error"}>
                Houve um problema de conexão, tente novamente em instantes.
              </Message>,
            );
            break;
        }
      })
      .finally(() => {
        setLoad(false);
      });
  }

  useEffect(() => {
    document.title = "Login";
    if (localStorage.getItem("token")) {
      props.history.push("/");
    } else {
      localStorage.clear();
    }
  }, []);

  return (
    <div className="login-container">
      <div className="login">
        <TuriviusLogin
          inside
          logo="SamanthaLogo.png"
          // logo="new_logo.png"
          fetching={load}
          page={page}
          onLog={login}
          goLogin={() => setPage("login")}
          goForgotPassword={() => setPage("recuperar")}
          onNewPassword={forgotPassword}
          loginFooter={
            <div className="flag-system">
              <p>from</p>
              <img
                style={{ width: 200 }}
                src="new_logo-white.png"
                alt="Logo da Turivius na cor branca"
              />
            </div>
          }
        />
      </div>
    </div>
  );
};

export default withRouter(Login);
