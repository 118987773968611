import React from "react";
import PropTypes from "prop-types";
import "./TuriviusFileViewer.scss";

const TuriviusFileViewer = ({ fileURL, style }) => (
  <div className="turivius-file-viewer">
    {fileURL ? (
      <iframe
        src={fileURL}
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          ...style,
        }}
      ></iframe>
    ) : null}
  </div>
);

TuriviusFileViewer.propTypes = {
  fileURL: PropTypes.string.isRequired,
  style: PropTypes.object,
};

TuriviusFileViewer.defaultProps = {
  style: {},
};

export default TuriviusFileViewer;
