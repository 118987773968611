import React, { useEffect, useState } from "react";
import { Table, IconButton, Button, toaster, Message } from "rsuite";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faMinus,
  faCheck,
  faXmark,
  faArrowUpRightFromSquare,
} from "@fortawesome/free-solid-svg-icons";
import { Turivius } from "../../../ui";
import TuriviusColors from "../../../vars.module.scss";
import "./style.scss";
import { requester } from "../../../requesters";

const CustomCell = ({ rowData, dataKey, bool, ...props }) => (
  <Table.Cell {...props}>
    <p>
      {bool ? (
        rowData[dataKey] ? (
          <FontAwesomeIcon
            size="lg"
            color={TuriviusColors.green}
            icon={faCheck}
          />
        ) : (
          <FontAwesomeIcon
            size="lg"
            color={TuriviusColors.red}
            icon={faXmark}
          />
        )
      ) : dataKey === "updated_date" || dataKey === "registration_date" ? (
        <span>
          {rowData[dataKey]
            ? new Date(rowData[dataKey]).toLocaleString("pt-BR")
            : "-"}
        </span>
      ) : (
        rowData[dataKey]
      )}
    </p>
  </Table.Cell>
);

const ExpandCell = ({
  rowData,
  dataKey,
  expandedRowKeys,
  onChange,
  ...props
}) => (
  <Table.Cell {...props}>
    <IconButton
      size="xs"
      onClick={() => {
        onChange(rowData);
      }}
      disabled={rowData.search_results.length > 0 ? false : true}
      style={{ backgroundColor: "transparent" }}
      icon={
        expandedRowKeys.some((key) => key === rowData["id"]) ? (
          <FontAwesomeIcon
            size="lg"
            color={TuriviusColors.turivius}
            icon={faMinus}
          />
        ) : rowData.search_results.length === 0 ? (
          <Turivius.TuriviusTooltip
            trigger="hover"
            message={`Nenhuma atualização`}
            placement="top"
          >
            <span>
              <FontAwesomeIcon
                size="lg"
                color={TuriviusColors.turivius}
                icon={faPlus}
              />
            </span>
          </Turivius.TuriviusTooltip>
        ) : (
          <FontAwesomeIcon
            size="lg"
            color={TuriviusColors.turivius}
            icon={faPlus}
          />
        )
      }
    />
  </Table.Cell>
);

const renderRowExpanded = (rowData, entities) => {
  function dateFormatted(date) {
    if (date) {
      const dt = date.split("-");
      return `${dt[2]}/${dt[1]}/${dt[0]}`;
    } else {
      return ``;
    }
  }

  function redirectURLMonitoring(monitoring, all_monitoring) {
    let valueDefaults = {
      default_operator: "e",
      synonyms: true,
      fields: ["ementa", "full_text"],
      sort: 1,
    };

    try {
      // const url_base = "https://prodapi.turivius.com"
      const entity = [];
      const filters = {};

      // const url = (url_base + rowData.search_results[0].query_url)

      Object.keys(entities).forEach((e, i) => {
        entity.push(entities[i].id);
      });

      const synonyms =
        JSON.stringify(all_monitoring.filters?.synonyms) ||
        JSON.stringify(valueDefaults.synonyms);
      const default_operator =
        all_monitoring.filters?.default_operator ||
        valueDefaults.default_operator;
      const fields =
        JSON.stringify(all_monitoring.filters?.fields) ||
        JSON.stringify(valueDefaults.fields);
      console.log(monitoring);
      const allFilters = {
        filter_by_favorability: all_monitoring.filters?.filter_by_favorability,
        sort: all_monitoring.filters?.sort || valueDefaults.sort,
        date_range: {
          0: dateFormatted(monitoring.initial_date),
          1: dateFormatted(monitoring.final_date),
        },
        entity: all_monitoring.all_entities
          ? entity
          : all_monitoring.filters?.entity,
        filter_by_orgao_julgador_stf:
          all_monitoring.filters?.filter_by_orgao_julgador_stf,
        filter_by_secao_carf: all_monitoring.filters?.filter_by_secao_carf,
        filter_by_vote: all_monitoring.filters?.filter_by_vote,
      };

      Object.keys(allFilters).forEach((item, index) => {
        if (allFilters[item]?.length > 0 || allFilters[item] > 0) {
          filters[item] = allFilters[item];
        } else if (item === "date_range") {
          filters[item] = allFilters[item];
        }
      });

      return window.open(
        `https://app.turivius.com/resultados?query=${all_monitoring.query}&fields=${fields}&synonyms=${synonyms}&default_operator=${default_operator}&filters=${JSON.stringify(filters)}`,
      );
    } catch (error) {
      console.log(error);

      toaster.push(
        <Message type="error">
          Ocorreu um erro ao abrir este monitoramento
        </Message>,
      );
    }
  }

  return rowData.search_results.map((r, i) => {
    const { initial_date, final_date, total_new_results, query_url } = r;
    return (
      <div key={i} className="render-row-expanded">
        <div style={{ width: 300 }}>
          <label className="render-row-expanded-label">
            Número de Resultados
          </label>
          <p style={{ width: 300 }}>{total_new_results}</p>
        </div>
        <div style={{ width: 130 }}>
          <label className="render-row-expanded-label">Data Inicial</label>
          <p style={{ width: 300 }}>{dateFormatted(initial_date)}</p>
        </div>
        <div style={{ width: 130 }}>
          <label className="render-row-expanded-label">Data Final</label>
          <p style={{ width: 300 }}>{dateFormatted(final_date)}</p>
        </div>
        <div style={{ width: 200, display: "flex", flexDirection: "row" }}>
          <Button onClick={() => redirectURLMonitoring(r, rowData)}>
            <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
          </Button>
        </div>
      </div>
    );
  });
};

const UserMonitoring = ({ user, entities }) => {
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [monitoring, setMonitoring] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleExpanded = (rowData, dataKey) => {
    let open = false;
    const nextExpandedRowKeys = [];

    expandedRowKeys.forEach((key) => {
      if (key === rowData["id"]) {
        open = true;
      } else {
        nextExpandedRowKeys.push(key);
      }
    });

    if (!open) {
      nextExpandedRowKeys.push(rowData["id"]);
    }
    setExpandedRowKeys(nextExpandedRowKeys);
  };

  const getMonitoring = () => {
    setLoading(true);
    requester.admin
      .getMonitoredTermsByUserId(user)
      .then((response) => {
        setMonitoring(response);
      })
      .catch((err) => {
        console.error(err);
        toaster.push(
          <Message
            closable
            duration={5000}
            type="error"
            header={`Erro ${err?.status}`}
          >
            {err?.message}
          </Message>,
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getMonitoring();
  }, []);

  return (
    <div className="user-monitoring-list">
      <Table
        height={500}
        data={monitoring}
        rowKey={"id"}
        expandedRowKeys={expandedRowKeys}
        renderRowExpanded={(rowData) => renderRowExpanded(rowData, entities)}
        loading={loading}
      >
        <Table.Column width={60} align="center">
          <Table.HeaderCell>#</Table.HeaderCell>
          <ExpandCell
            dataKey="id"
            expandedRowKeys={expandedRowKeys}
            onChange={handleExpanded}
          />
        </Table.Column>

        <Table.Column width={300}>
          <Table.HeaderCell>Termo</Table.HeaderCell>
          <Table.Cell dataKey="query" />
        </Table.Column>

        <Table.Column width={150}>
          <Table.HeaderCell>Enviar por e-mail</Table.HeaderCell>
          <CustomCell bool dataKey="send_email" />
        </Table.Column>

        <Table.Column width={150}>
          <Table.HeaderCell>Enviar Notificação</Table.HeaderCell>
          <CustomCell bool dataKey="send_notification" />
        </Table.Column>

        <Table.Column width={200}>
          <Table.HeaderCell>Criação</Table.HeaderCell>
          <CustomCell dataKey="registration_date" />
        </Table.Column>

        <Table.Column width={200}>
          <Table.HeaderCell>Última Atualização</Table.HeaderCell>
          <CustomCell dataKey="updated_date" />
        </Table.Column>

        <Table.Column width={100}>
          <Table.HeaderCell>Ativo</Table.HeaderCell>
          <CustomCell bool dataKey="active" />
        </Table.Column>
      </Table>
    </div>
  );
};

export default UserMonitoring;
