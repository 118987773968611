import React from "react";
import { Message } from "rsuite";
import PropTypes from "prop-types";
import "./TuriviusMessage.scss";

const TuriviusMessage = ({
  title,
  message,
  type,
  withIcon,
  closable,
  style,
}) => (
  <Message
    style={style}
    closable={closable}
    showIcon={withIcon}
    type={type}
    header={title}
  >
    {message}
  </Message>
);

TuriviusMessage.propTypes = {
  message: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  title: PropTypes.string,
  type: PropTypes.oneOf(["info", "success", "warning", "error"]),
  withIcon: PropTypes.bool,
  closable: PropTypes.bool,
  style: PropTypes.object,
};

TuriviusMessage.defaultProps = {
  message: "",
  title: "",
  type: "info",
  withIcon: true,
  closable: true,
};

export default TuriviusMessage;
