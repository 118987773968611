import React, { useState } from "react";
import { Col, Grid, Input, InputPicker, Row } from "rsuite";
import ListQuestions from "./ListQuestions";
import CreateQuestion from "./CreateUpdateQuestion";
import { getAll } from "../../services/academy";
import { routes } from "./Settings";
import "./style.scss";

const data = routes.map((item) => ({
  label: item.name,
  value: item.route,
}));
const dataStatus = [
  { label: "Todos", value: "all" },
  { label: "Ativo", value: "true" },
  { label: "Inativo", value: "false" },
];

const TuriviusAcademy = () => {
  const [questions, setQuestions] = useState([]);
  const [filter, setFilter] = useState([]);
  const [loading, setLoading] = useState(true);

  async function getQuestions() {
    try {
      const getAllQuestions = await getAll();
      setQuestions(getAllQuestions);
      setFilter(getAllQuestions);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  function filterQuestion(value) {
    setFilter(
      questions.filter((q) =>
        q.query.toLowerCase().includes(value.toLowerCase()),
      ),
    );
  }

  function filterBySession(value) {
    if (value === "all") {
      setFilter(questions);
    } else {
      setFilter(questions.filter((q) => q.route === value));
    }
  }
  function filterByStatus(value) {
    console.log(value);
    if (value === "all") {
      setFilter(questions);
    } else {
      setFilter(questions.filter((q) => q.actived === JSON.parse(value)));
    }
  }

  return (
    <div className="turivius-academy-container">
      <div className="turivius-academy-container_header">
        <h1>Turivius Academy</h1>
        <CreateQuestion getQuestions={getQuestions} />
      </div>

      <div className="questions-filter">
        <Grid fluid>
          <Row>
            <Col sm={12} className="questions-filter_column">
              {/* <label>Pergunta:</label> */}
              <Input
                onChange={(value) => filterQuestion(value)}
                placeholder="Busque por uma pergunta"
                style={{ marginBottom: 20, marginTop: 25 }}
              />
            </Col>
            <Col sm={6} className="questions-filter_column">
              <label>Sessão:</label>
              <InputPicker
                block
                cleanable={false}
                defaultValue="all"
                data={[{ label: "Todos", value: "all" }, ...data]}
                onChange={filterBySession}
              />
            </Col>
            <Col sm={6} className="questions-filter_column">
              <label>Status:</label>
              <InputPicker
                block
                cleanable={false}
                data={dataStatus}
                onChange={filterByStatus}
                defaultValue="all"
              />
            </Col>
          </Row>
        </Grid>
      </div>

      <div className="turivius-academy-container_body">
        <ListQuestions
          getQuestions={getQuestions}
          loading={loading}
          questions={filter}
        />
      </div>
    </div>
  );
};

export default TuriviusAcademy;
