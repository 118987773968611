import { init, setUserToken, setUserFinger } from "./requesters/requester";
import { authentication } from "./requesters/authentication";
import { admin } from "./requesters/admin";
import { cards } from "./requesters/cards";
import { collections } from "./requesters/collections";
import { dashboards } from "./requesters/dashboards";
import { database } from "./requesters/database";
import { docs } from "./requesters/docs";
import { general } from "./requesters/general";
import { help } from "./requesters/help";
import { jurimetrics } from "./requesters/jurimetrics";
import { monitoring } from "./requesters/monitoring";
import { nestle } from "./requesters/nestle";
import { sso } from "./requesters/sso";
import { block } from "./requesters/block";
import { notifications } from "./requesters/notifications";
import { profiles } from "./requesters/profiles";
import { reports } from "./requesters/reports";
import { segmentation } from "./requesters/segmentation";
import { searchNotifications } from "./requesters/search_notifications";
import { slaJurimetric } from "./requesters/sla_jurimetric";
import { Posthog } from "./posthog/posthog";

export const requester = {
  init,
  setUserToken,
  setUserFinger,
  admin,
  authentication,
  cards,
  collections,
  dashboards,
  database,
  docs,
  general,
  help,
  jurimetrics,
  monitoring,
  nestle,
  sso,
  block,
  notifications,
  profiles,
  reports,
  segmentation,
  searchNotifications,
  slaJurimetric,
};

export const posthog = {
  ...Posthog,
};
