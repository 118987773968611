import React from "react";
import { Turivius } from "../../ui";

const NotFound = (props) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      <Turivius.TuriviusNotFound
        onReturn={props.history.goBack}
        imageError="/error.png"
      />
    </div>
  );
};

export default NotFound;
