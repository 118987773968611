import React from "react";
import { Form, Input, InputPicker, Toggle } from "rsuite";
import { sessions } from "./Settings";

const Textarea = React.forwardRef((props, ref) => (
  <Input {...props} as="textarea" ref={ref} />
));

// const StatusToggle = React.forwardRef((props, ref) => {
//     return(
//     <Toggle size="lg" checked={props.value} as="toggle"  {...props} ref={ref} checkedChildren="Ativo" unCheckedChildren="Inativo" />
// )});

const selectData = sessions.map((item) => ({
  label: item,
  value: item,
}));

const FormCreate = ({
  formRef,
  model,
  formValue,
  formError,
  setFormValue,
  setFormError,
}) => {
  return (
    <Form
      fluid
      ref={formRef}
      model={model}
      formValue={formValue}
      formError={formError}
      onChange={setFormValue}
      onCheck={setFormError}
    >
      <Form.Group controlId="session">
        <Form.ControlLabel>Sessão</Form.ControlLabel>
        <Form.Control name="session" accepter={InputPicker} data={selectData} />
        <Form.HelpText>Sessão é obrigatório</Form.HelpText>
      </Form.Group>

      <Form.Group controlId="query">
        <Form.ControlLabel>Pergunta</Form.ControlLabel>
        <Form.Control name="query" />
        <Form.HelpText>Pergunta é obrigatório</Form.HelpText>
      </Form.Group>

      <Form.Group controlId="answer">
        <Form.ControlLabel>Resposta (Texto)</Form.ControlLabel>
        <Form.Control name="answer" rows={5} accepter={Textarea} />
      </Form.Group>

      <Form.Group controlId="videoURL">
        <Form.ControlLabel>Resposta (Youtube link)</Form.ControlLabel>
        <Form.Control name="videoURL" />
      </Form.Group>
    </Form>
  );
};

export default FormCreate;
