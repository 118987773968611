import React, { useState } from "react";
import { Button, Message, toaster } from "rsuite";
import { requester } from "../../requesters";
import "./style.scss";

const NewPasswordButton = ({ email }) => {
  const [loading, setLoading] = useState(false);

  const sendNewPassword = () => {
    setLoading(true);

    requester.authentication
      .recoverPassword(email)
      .then((response) => {
        toaster.push(
          <Message showIcon closable type="success" duration={5000}>
            Um e-mail com instruções de recuperação foi enviado para o usuário!
          </Message>,
        );
      })
      .catch((err) => {
        switch (err.status) {
          case 400:
            toaster.push(
              <Message showIcon closable type="error" duration={5000}>
                Não foi possível alterar sua senha. Verifique as informações e
                tente novamente.
              </Message>,
            );
            break;
          default:
            console.error(err);
            toaster.push(
              <Message showIcon closable type="error" duration={5000}>
                Houve um problema de conexão, tente novamente
              </Message>,
            );
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Button
      className="btn-send-password"
      loading={loading}
      onClick={sendNewPassword}
    >
      Enviar Nova Senha
    </Button>
  );
};

export default NewPasswordButton;
