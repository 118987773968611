import React from "react";
import { Turivius } from "../../ui";
import TuriviusSidenav from "../TuriviusSidenav";
import '../../ds-components/styles/index.jsx.css';
import "rsuite/dist/rsuite.min.css";
import "./style.scss";

const TuriviusBasePage = function({ children }) {
  return (
    <Turivius.TuriviusBasePage
      sidenav={<TuriviusSidenav />}
      additionalChildren={null}
    >
      {children}
    </Turivius.TuriviusBasePage>
  );
};

export default TuriviusBasePage;
