import React from "react";
import PropTypes from "prop-types";
import {
  Whisper,
  Popover,
  Col,
  Grid,
  Row,
  Tooltip,
  Message,
  Modal,
  Checkbox,
} from "rsuite";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStarHalf } from "@fortawesome/free-solid-svg-icons";
import SimplePreloader from "../../loaders/SimplePreloader";
import TuriviusTooltip from "../../component-encapsulation/turivius-tooltip/TuriviusTooltip";
import TuriviusButton from "../../buttons/TuriviusButton";

import "./TuriviusEntitiesModal.scss";

class TuriviusEntitiesModal extends React.Component {
  // ({ error, onClick, entitiesCounter, className }) =>

  state = {
    filters: null,
    selected: null,
    open: false,
    items: null,
  };

  handleOpenClose = () => {
    const { open } = this.state;
    this.setState({ open: !open });
  };

  getOptions = (str) => {
    const { entitiesResponse } = this.props;

    if (this.mounted) {
      this.setState({
        items: this.groupArray(entitiesResponse, "class"),
      });
    }
  };

  groupArray = (arr, groupBy) => {
    const groupedArray = [];
    const categories = {};

    arr.forEach((elem) => {
      categories[elem[groupBy]] = "";
    });

    Object.keys(categories).forEach((category, i) => {
      groupedArray.push([]);
      arr.forEach((elem) => {
        elem.enabled = true;
        elem[groupBy] === category ? groupedArray[i].push(elem) : null;
      });
    });
    return groupedArray;
  };

  selectionChange = (value, checked) => {
    let { selected } = this.state;
    const selection = value;
    const findSelection = selected.indexOf(parseInt(selection));

    if (findSelection === -1) {
      selected = [...selected, parseInt(selection)];
    } else {
      selected.splice(findSelection, 1);
    }
    this.setState({
      selected: [...selected],
    });
    this.props.setTmpFilters("entities", [...selected]);
    if (this.props.context) {
      this.props.posthog.capture(this.props.context, {
        action: "change_filter",
        id: "entities",
      });
    }
  };

  changeSelectionGroup = (group, action) => {
    const { selected } = this.state;
    const { posthog, context } = this.props;

    for (const option of group) {
      const findSelection = selected.indexOf(option.id);
      if (findSelection === -1 && action === "add") {
        selected.push(option.id);
      } else if (findSelection !== -1 && action === "remove") {
        selected.splice(findSelection, 1);
      }
    }
    this.setState({
      /* NO SEGMENTATION */
      selected: [...selected],

      /* SEGMENTATION */
      // selected: [...selected]
      //     .filter(e => this.props.permissions.entidades[e])
    });

    this.props.setTmpFilters("entities", [...selected]);

    if (context) {
      posthog.capture(context, {
        action: "change_filter",
        id: "entities",
      });
    }
  };

  globalSelection = (action) => {
    let { selected, items } = this.state;
    const { posthog } = this.props;

    if (action === "remove") {
      selected = [];
    } else {
      selected = items
        ?.reduce((acc, item) => [...acc, ...item], [])
        ?.map((item) => item.id);
    }
    this.setState({
      selected: [...selected],
    });
    this.props.setTmpFilters("entities", [...selected]);
    if (this.props.context) {
      posthog.capture(this.props.context, {
        action: "change_filter",
        id: "entities",
      });
    }
  };

  getSelectionString = () => {
    const { selected, items } = this.state;
    const totalLength = items?.reduce((acc, item) => acc + item.length, 0);

    if (selected?.length === 0) {
      return "Selecionar órgãos";
    } else if (selected?.length === totalLength) {
      return (
        <span style={{ color: "#1675e0" }}>
          Todos órgãos selecionados ({selected?.length})
        </span>
      );
    } else if (selected?.length <= 3) {
      return (
        <span style={{ color: "#1675e0" }}>
          {items
            ?.reduce((acc, item) => [...acc, ...item], [])
            .filter((item) => selected?.indexOf(item.id) !== -1)
            .reduce((acc, item) => [...acc, item.initials], [])
            .join(", ")}
        </span>
      );
    } else {
      const groupedSelections = [];
      items?.forEach((item) =>
        groupedSelections.push({
          group: item[0].class,
          total: item.length,
          selected: item
            ?.filter((item) => selected?.indexOf(item.id) !== -1)
            .reduce((acc, item) => [...acc, item.initials], []).length,
        }),
      );
      return (
        <span style={{ color: "#1675e0" }}>
          {groupedSelections
            .filter((gs) => gs.selected !== 0)
            ?.map(
              (gs) =>
                `${gs.group} (${gs.selected === gs.total ? "Todos" : gs.selected})`,
            )
            .join(", ")}
        </span>
      );
    }
  };

  verifyGroupSelection = (group) => {
    const { selected } = this.state;
    // selected = selected.filter(e => this.props.permissions.entidades[e])

    let allSelected = true;
    let allDisabled = true;
    let countSelected = 0;
    let countUnselected = 0;
    // for (let option of group.filter(e => this.props.permissions.entidades[e.id])) {
    for (const option of group) {
      const verifySelection = selected.indexOf(option.id);
      if (option.enabled) {
        allDisabled = false;
      }
      if (verifySelection === -1) {
        allSelected = false;
        countUnselected += 1;
      } else {
        countSelected += 1;
      }
    }
    if (countSelected === 0 || countUnselected === 0) {
      return allSelected ? (
        <Checkbox
          onChange={() => this.changeSelectionGroup(group, "remove")}
          checked={!allDisabled}
          disabled={allDisabled}
        >
          {group[0].class}
        </Checkbox>
      ) : (
        <Checkbox
          onChange={() => this.changeSelectionGroup(group, "add")}
          checked={false}
          disabled={allDisabled}
        >
          {group[0].class}
        </Checkbox>
      );
    } else {
      return (
        <Checkbox
          onChange={() => this.changeSelectionGroup(group, "add")}
          indeterminate
          disabled={allDisabled}
        >
          {group[0].class}
        </Checkbox>
      );
    }
  };

  componentWillUnmount() {
    this.mounted = false;
  }

  componentDidMount() {
    this.mounted = true;
    this.getOptions();
    if (this.mounted) {
      this.setState({
        filters: this.props.filters["entities"],
        selected: this.props.filters["entities"],
        placeholder: this.props.placeholder,
        /* SEGMENTATION */
        // selected: this.props.filters['entities']?.filter(e => this.props.permissions.entidades[e]),
      });
    }
  }

  componentDidUpdate() {
    const propsFilters = this.props.filters && this.props.filters["entities"];
    const stateFilters = this.state && this.state.filters;

    if (!stateFilters && propsFilters && this.mounted) {
      this.setState({
        filters: [...propsFilters],

        /* NO SEGMENTATION */
        selected: [...propsFilters],

        /* SEGMENTATION */
        // selected: [...propsFilters]
        //     .filter(e => this.props.permissions.entidades[e])
      });
    } else if (
      stateFilters &&
      propsFilters &&
      stateFilters.length !== propsFilters.length &&
      this.mounted
    ) {
      this.setState({
        filters: null,
        selected: null,
      });
      setTimeout(() => {
        this.setState({
          filters: [...propsFilters],

          /* NO SEGMENTATION */
          selected: [...propsFilters],

          /* SEGMENTATION */
          // selected: [...propsFilters]
          //     .filter(e => this.props.permissions.entidades[e])
        });
      }, 100);
    } else if (
      Array.isArray(propsFilters) &&
      Array.isArray(stateFilters) &&
      this.mounted
    ) {
      let doUpdate = false;
      propsFilters.forEach((propFilter) => {
        const found = stateFilters.filter((stateFilter) => {
          return (
            (typeof stateFilter === "object"
              ? parseInt(stateFilter.id)
              : parseInt(stateFilter)) ===
            (typeof propFilter === "object"
              ? parseInt(propFilter.id)
              : parseInt(propFilter))
          );
        }).length;
        if (found === 0) {
          doUpdate = true;
        }
      });
      if (doUpdate && this.mounted) {
        this.setState({
          filters: null,
          selected: null,
        });
        setTimeout(() => {
          this.setState({
            filters: [...propsFilters],

            /* NO SEGMENTATION */
            selected: [...propsFilters],

            /* SEGMENTATION */
            // selected: [...propsFilters]
            //     .filter(e => this.props.permissions.entidades[e])
          });
        }, 100);
      }
    }
  }

  render() {
    const { selected, open, items } = this.state;
    const {
      loading,
      error,
      onClick,
      entitiesCounter,
      className,
      width,
      style,
    } = this.props;

    return (
      <div className={className}>
        <Whisper
          placement="bottom"
          trigger="hover"
          controlId="tooltip-click-entities-explain"
          speaker={
            <Tooltip>Clique para alterar os órgãos selecionados</Tooltip>
          }
        >
          <div
            style={{ width: width, ...style }}
            data-testid="simple-home-page-entities-modal-input"
            aria-disabled="false"
            onClick={() => {
              if (onClick) {
                onClick();
              }
              this.handleOpenClose();
            }}
            className={`select-entity ${error ? "rs-custom-component-error" : ""} rs-btn-icon rs-btn-icon-with-text rs-btn rs-btn-default rs-btn-md`}
          >
            <p>
              {loading ? (
                <div style={{ display: "flex" }}>
                  <SimplePreloader />
                </div>
              ) : (
                this.getSelectionString()
              )}
            </p>
          </div>
        </Whisper>
        <Modal
          size="full"
          className="turivius-modal-entities-filter"
          open={open}
          onClose={this.handleOpenClose}
        >
          <Modal.Header>
            <span>Seleção de Órgãos</span>
            <TuriviusButton
              appearance="ghost"
              onClick={() => this.globalSelection("remove")}
            >
              Remover Todos
            </TuriviusButton>
            <TuriviusButton
              appearance="ghost"
              onClick={() => this.globalSelection("add")}
            >
              Selecionar Todos
            </TuriviusButton>
            <Whisper
              placement="bottom"
              trigger={["hover", "click"]}
              controlId="popover-entities-availabilty-modal"
              speaker={
                <Popover title="Cobertura das bases">
                  <div style={{ display: "flex" }}>
                    <div
                      style={{
                        paddingRight: 5,
                        borderRight: "1px solid #ddd",
                        fontSize: 14,
                      }}
                    >
                      <b>A partir de 2000</b>
                      <p style={{ maxWidth: 130, textAlign: "justify" }}>
                        CARF, STF, STJ, RFB-COSIT, RFB-DISIT, TJ-SP 1, TJ-DFT,
                        CCRF-PR, DRJs, CC-RJ, TRF2, TJ-RS, TJ-BA, TJ-MS, TJ-GO,
                        TJ-MG, TJ-SP, TJ-PR, TJ-PA, TRF5, TIT-SP
                      </p>
                    </div>
                    <div style={{ paddingLeft: 5, fontSize: 14 }}>
                      <b>A partir de 2010</b>
                      <p style={{ maxWidth: 130, textAlign: "justify" }}>
                        TJ-PE, TAT-SC, CMT-SP, TJ-PB, TARF-RS, CC-MG, CERF-ES,
                        TJ-ES, TJ-RO, TJ-MA, TJ-CE, TRF1, TJ-SC, TRF4, TJ-RJ,
                        TRF3, CAT-GO, RFB-Receita
                      </p>
                    </div>
                  </div>
                </Popover>
              }
              enterable
            >
              <Message style={{ cursor: "help", margin: "4px 0px" }}>
                <b>Atenção:</b> Temos bases atualizadas a partir de 2000 e
                outras a partir de 2010. Clique aqui para ver a lista completa
                de órgãos e coberturas.{" "}
              </Message>
            </Whisper>
          </Modal.Header>

          <Modal.Body>
            <div className="turivius-modal-checkbox-group-filter ">
              <div
                className={`checkbox-input-field-contente ${error ? "error" : ""}`}
              >
                {loading ? (
                  <SimplePreloader />
                ) : items && selected ? (
                  <Grid fluid>
                    <Row>
                      {items?.map((group, i) => {
                        return (
                          <Row
                            className="checkbox-group-category-container-row"
                            key={i}
                          >
                            <Col xs={24} sm={24}>
                              <label className="checkbox-group-category-title">
                                {this.verifyGroupSelection(group)}
                              </label>
                            </Col>
                            {group?.map((elem, j) => (
                              <Col xs={24} sm={12} md={8} lg={4} key={j}>
                                <Checkbox
                                  id={`Checkbox_${elem.id}`}
                                  key={`Checkbox_${elem.id}`}
                                  value={`${elem.id}`}
                                  disabled={!elem.enabled}
                                  onChange={this.selectionChange}
                                  checked={
                                    selected
                                      ? selected.filter((option) =>
                                          typeof option === "object"
                                            ? option.id === elem.id
                                            : option === elem.id,
                                        ).length > 0
                                      : false
                                  }
                                >
                                  <div className="checkbox-group-entity-container">
                                    <div className="first-line-group-entity">
                                      {elem.is_new ? (
                                        <TuriviusTooltip
                                          trigger="hover"
                                          message="Base de dados nova!"
                                          placement="right"
                                        >
                                          <div>
                                            <span className="new-badge">
                                              new
                                            </span>
                                          </div>
                                        </TuriviusTooltip>
                                      ) : elem.is_partial ? (
                                        <TuriviusTooltip
                                          trigger="hover"
                                          message="Base de dados parcial"
                                          placement="right"
                                        >
                                          <div>
                                            <FontAwesomeIcon
                                              icon={faStarHalf}
                                              className="tiny partial"
                                            />
                                          </div>
                                        </TuriviusTooltip>
                                      ) : null}
                                      <div>
                                        {elem.initials
                                          .replace("(novo)", "")
                                          .replace("(parcial)", "")}
                                      </div>
                                    </div>
                                    <p className="counter-entity">
                                      {entitiesCounter &&
                                      entitiesCounter[elem.id]
                                        ? `${entitiesCounter[elem.id]} resultados`
                                        : ""}
                                    </p>
                                  </div>
                                </Checkbox>
                              </Col>
                            ))}
                          </Row>
                        );
                      })}
                    </Row>
                  </Grid>
                ) : null}
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <TuriviusButton appearance="primary" onClick={this.handleOpenClose}>
              Confirmar Seleção
            </TuriviusButton>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

TuriviusEntitiesModal.propTypes = {
  posthog: PropTypes.func,
  error: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
  entitiesResponse: PropTypes.object,
  loading: PropTypes.bool,
  context: PropTypes.string,
  width: PropTypes.string,
  // action:PropTypes.func,

  // REDUX
  filters: PropTypes.object,
  entities: PropTypes.object,
  entitiesCounter: PropTypes.object,
  setTmpFilters: PropTypes.func,

  // SEGMENTATION
  permissions: PropTypes.object,
};

TuriviusEntitiesModal.defaultProps = {
  context: "Home",
  className: "search-entity",
  width: "100%",
  error: false,
  onClick: null,
  posthog: null,
  loading: false,
};

export default TuriviusEntitiesModal;
