import React from "react";
import { Table, Pagination } from "rsuite";
import "./style.scss";

const CustomCell = ({ rowData, dataKey, customCell, ...props }) => (
  <Table.Cell {...props}>
    {customCell({ rowData, dataKey, customCell, ...props })}
  </Table.Cell>
);

const TuriviusTable = ({
  autoHeight,
  bordered,
  cellBordered,
  columns,
  data,
  hover,
  loading,
  locale,
  minHeight,
  onRowClick,
  rowClassName,
  rowHeight,
  showHeader,
  width,
  height,
  wordWrap,
  style,

  paginate,
  first,
  last,
  next,
  prev,
  defaultActivePage,
  maxButtons,
  total,
  limit,
  onChangePage,
  paginationStyle,
  activePage,
}) => {
  const [defaultActivePages, setDefaultActivePages] = React.useState(
    defaultActivePage ? defaultActivePage : 1,
  );

  return (
    <React.Fragment>
      <Table
        autoHeight={autoHeight}
        bordered={bordered}
        cellBordered={cellBordered}
        columns={columns}
        data={data}
        hover={hover}
        loading={loading}
        locale={locale}
        minHeight={minHeight}
        onRowClick={onRowClick}
        rowClassName={rowClassName}
        rowHeight={rowHeight}
        showHeader={showHeader}
        width={width}
        height={height}
        wordWrap={wordWrap}
        style={style}
      >
        {columns.map((column) => {
          const { key, label, ...rest } = column;
          return (
            <Table.Column {...rest} key={key}>
              {column.customHeader ? (
                <column.customHeader>{label}</column.customHeader>
              ) : (
                <Table.HeaderCell>{label}</Table.HeaderCell>
              )}
              {column.customCell ? (
                <CustomCell dataKey={key} customCell={column.customCell} />
              ) : (
                <Table.Cell dataKey={key} />
              )}
            </Table.Column>
          );
        })}
      </Table>
      {paginate ? (
        <Pagination
          total={total}
          limit={limit}
          first={first}
          last={last}
          next={next}
          prev={prev}
          maxButtons={maxButtons}
          activePage={activePage || defaultActivePages}
          onChangePage={(page) => {
            onChangePage(page);
            setDefaultActivePages(page);
          }}
          style={paginationStyle || {}}
        />
      ) : null}
    </React.Fragment>
  );
};

export default TuriviusTable;

/*
export default TuriviusTable;
      const [sortColumn, setSortColumn] = React.useState();
      const [sortType, setSortType] = React.useState();
  
      const getData = () => {
          if (sortColumn && sortType) {
            return data.sort((a, b) => {
              let x = a[sortColumn];
              let y = b[sortColumn];
              if (typeof x === 'string') {
                x = x.charCodeAt();
              }
              if (typeof y === 'string') {
                y = y.charCodeAt();
              }
              if (sortType === 'asc') {
                return x - y;
              } else {
                return y - x;
              }
            });
          }
          return data;
        };
  
      const handleSortColumn = (sortColumn, sortType) => {
          setTimeout(() => {
            setSortColumn(sortColumn);
            setSortType(sortType);
          }, 500);
        };
        */
