import React from "react";
import { withRouter } from "react-router-dom";
import TuriviusColors from "../../vars.module.scss";
import "./style.scss";

const LinkDetails = (props) => {
  function directPage() {
    const { typeData, data } = props;

    if (typeData === "company") {
      props.history.push(`/empresas/${data.id}`);
    } else {
      props.history.push(`/usuarios/${data.id}`);
    }
  }

  return (
    <div style={{ width: "100%" }}>
      <div
        style={{
          color: TuriviusColors.turivius_light,
          padding: "5px 0px",
          cursor: "pointer",
        }}
        onClick={() => directPage()}
      >
        {props.children}
      </div>
    </div>
  );
};

export default withRouter(LinkDetails);
