import { request } from "./requester";

function getSLABases() {
  return request({
    type: "GET",
    microservice: "sla_jurimetric",
    action: "sla_bases",
  }).then((data) => {
    return data;
  });
}

function getSLAClassified() {
  return request({
    type: "GET",
    microservice: "sla_jurimetric",
    action: "sla_classified",
  }).then((data) => {
    return data;
  });
}

function getSLAAccuracy() {
  return request({
    type: "GET",
    microservice: "sla_jurimetric",
    action: "sla_accuracy",
  }).then((data) => {
    return data;
  });
}

export const slaJurimetric = {
  getSLABases,
  getSLAClassified,
  getSLAAccuracy,
};
