import React, { useState } from "react";
import PropTypes from "prop-types";
import "./TuriviusLogin.scss";
import {
  toaster,
  Message,
  Button,
  ButtonToolbar,
  Content,
  FlexboxGrid,
  Form,
  Panel,
  Schema,
  Container,
  InputGroup,
  Input,
} from "rsuite";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

var loginModel = Schema.Model({
  email_login: Schema.Types.StringType()
    .isEmail("Por favor, informe um e-mail válido.")
    .isRequired("Este campo é obrigatório."),
  password: Schema.Types.StringType().isRequired("Este campo é obrigatório."),
});

var recoverPasswordModel = Schema.Model({
  email_forgot: Schema.Types.StringType()
    .isEmail("Por favor, informe um e-mail válido.")
    .isRequired("Este campo é obrigatório."),
});

const TuriviusLogin = ({
  page,
  inside,
  logo,
  fetching,
  goForgotPassword,
  goLogin,
  goSSO,
  onLog,
  onNewPassword,
  loginHeader,
  loginFooter,
}) => {
  const formLogin = React.useRef(null);
  const formForget = React.useRef(null);

  const [loginFormValue, setLoginFormValue] = useState({});
  const [loginFormError, setLoginFormError] = useState({});
  const [recoverPasswordFormValue, setRecoverPasswordFormValue] = useState({
    email_forgot: "",
  });
  const [recoverPasswordFormError, setRecoverPasswordFormError] = useState({});
  const [visible, setvisible] = useState(false);

  const handleChangeVisible = () => {
    setvisible(!visible);
  };

  const handleSubmitLogin = () => {
    if (formLogin.current.check()) {
      onLog(loginFormValue.email_login, loginFormValue.password);
    } else {
      toaster.push(
        <Message
          showIcon
          closable
          type="warning"
          duration={5000}
          header="Campos inválidos"
        >
          Preencha os campos e tente novamente
        </Message>,
      );
    }
  };

  const handleSubmitRecoverPassword = () => {
    if (formForget.current.check()) {
      onNewPassword(recoverPasswordFormValue.email_forgot);
      if (!fetching) {
        goLogin();
        setLoginFormValue({
          email_login: recoverPasswordFormValue.email_forgot,
        });
      }
    } else {
      toaster.push(
        <Message
          showIcon
          closable
          type="warning"
          duration={5000}
          header="Campos inválidos"
        >
          Preencha os campos e tente novamente
        </Message>,
      );
    }
  };

  const keyPressLogin = (e) => {
    if (e.key === "Enter") {
      handleSubmitLogin();
    }
  };

  const keyPressRecoverPassword = (e) => {
    if (e.key === "Enter") {
      handleSubmitRecoverPassword();
    }
  };

  return (
    <div className="login-page">
      <Container>
        <Content>
          <FlexboxGrid justify="center">
            <FlexboxGrid.Item colspan={20}>
              <div style={{ marginBottom: 20 }}>{loginHeader}</div>
              {inside ? null : (
                <div className={`logo-image ${inside ? "inside" : "outside"}`}>
                  <img src={logo} alt="Turivius" />
                </div>
              )}
              <Panel
                className="box-login"
                header={
                  inside ? (
                    <div>
                      <div
                        className={`logo-image ${inside ? "inside" : "outside"}`}
                      >
                        <img src={logo} alt="Turivius" />
                      </div>
                      <h4>{page === "login" ? null : "Recuperar Senha"}</h4>
                    </div>
                  ) : (
                    <div>
                      <h4>{page === "login" ? null : "Recuperar Senha"}</h4>
                    </div>
                  )
                }
              >
                {page === "login" ? (
                  <Form
                    fluid
                    ref={formLogin}
                    onChange={setLoginFormValue}
                    onCheck={setLoginFormError}
                    formValue={loginFormValue}
                    model={loginModel}
                    readOnly={fetching}
                    formError={loginFormError}
                  >
                    <Form.Group controlId="login">
                      <Form.ControlLabel name="email_login">
                        E-mail
                      </Form.ControlLabel>
                      <Form.Control
                        name="email_login"
                        onKeyPress={keyPressLogin}
                      />
                    </Form.Group>

                    <Form.Group>
                      <Form.ControlLabel>Senha</Form.ControlLabel>
                      <InputGroup inside style={{ border: "none" }}>
                        <Form.Control
                          name="password"
                          type={visible ? "text" : "password"}
                          onKeyPress={keyPressLogin}
                        />

                        <InputGroup.Addon
                          onClick={handleChangeVisible}
                          style={{ cursor: "pointer" }}
                        >
                          <FontAwesomeIcon
                            icon={visible ? faEye : faEyeSlash}
                          />
                        </InputGroup.Addon>
                      </InputGroup>
                    </Form.Group>

                    <Form.Group>
                      <ButtonToolbar className="container-buttons">
                        <Button
                          block
                          className="btn-forgot"
                          appearance="link"
                          onClick={() => goForgotPassword()}
                          disabled={fetching}
                        >
                          Esqueceu sua senha?
                        </Button>
                        <Button
                          block
                          className="btn-login"
                          onClick={handleSubmitLogin}
                          loading={fetching}
                        >
                          Entrar
                        </Button>
                      </ButtonToolbar>
                    </Form.Group>
                  </Form>
                ) : (
                  <Form
                    fluid
                    ref={formForget}
                    onChange={setRecoverPasswordFormValue}
                    onCheck={setRecoverPasswordFormError}
                    model={recoverPasswordModel}
                    formValue={recoverPasswordFormValue}
                    formDefaultValue={recoverPasswordFormValue}
                    readOnly={fetching}
                    formError={recoverPasswordFormError}
                  >
                    <Form.Group controlId="forgot-password">
                      <Form.ControlLabel name="email_forgot">
                        E-mail de Recuperação
                      </Form.ControlLabel>
                      <Form.Control
                        name="email_forgot"
                        onKeyPress={keyPressRecoverPassword}
                      />
                    </Form.Group>

                    <Form.Group>
                      <ButtonToolbar className="container-buttons">
                        <Button
                          block
                          className="btn-login"
                          onClick={handleSubmitRecoverPassword}
                          loading={fetching}
                        >
                          Pedir nova senha
                        </Button>

                        <Button
                          block
                          className="btn-login again"
                          onClick={() => goLogin()}
                          disabled={fetching}
                        >
                          Fazer login
                        </Button>
                      </ButtonToolbar>
                    </Form.Group>
                  </Form>
                )}
              </Panel>
              <div style={{ marginTop: 20 }}>{loginFooter}</div>
            </FlexboxGrid.Item>
          </FlexboxGrid>
        </Content>
      </Container>
    </div>
  );
};
// };

TuriviusLogin.propTypes = {
  page: PropTypes.string,
  logo: PropTypes.string,
  fetching: PropTypes.bool,
  // recoverPasswordValue: PropTypes.object,
  // recoverPasswordError: PropTypes.object,
  // loginFormError: PropTypes.object,
  // loginFormValue: PropTypes.object,
  onNewPassword: PropTypes.func,
  onLog: PropTypes.func,
  loginHeader: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  goLogin: PropTypes.func,
  goForgotPassword: PropTypes.func,
  loginFooter: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

TuriviusLogin.defaultProps = {
  page: "login",
  logo: "",
  fetching: false,
};

export default TuriviusLogin;
