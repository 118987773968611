import React, { useState } from "react";
import { Add, ArrowLeft2, CloseCircle, DocumentDownload } from "iconsax-react";
import { Button, Modal } from "rsuite";
import { CSVLink } from "react-csv";

const headers = [
  { label: "ID do Usuário", key: "id" },
  { label: "Nome", key: "name" },
  { label: "E-mail", key: "email" },
  { label: "ID da Empresa", key: "company_id" },
  { label: "Nome da Empresa", key: "company_name" },
];

const ExportAllUsers = () => {
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [csvData, setCsvData] = useState([]);

  const handleModal = () => setModal(!modal);

  const exportUsers = () => {
    const URL = process.env.REACT_APP_PROFILES;
    const options = {
      method: "GET",
      headers: { "User-Agent": "Insomnia/2023.5.6" },
    };

    setLoading(true);

    fetch(URL + "api/profiles/users_from_clients", options)
      .then((response) => response.json())
      .then((response) => setCsvData(response))
      .catch((err) => console.error(err))
      .finally(() => setLoading(false));
  };

  return (
    <>
      <Button
        block
        className="tv-btn-modal-default"
        onClick={() => {
          handleModal();
          exportUsers();
        }}
      >
        Exportar Usuários
      </Button>

      <Modal className="tv-modal-default" open={modal} onClose={handleModal}>
        <Modal.Header closeButton={false} className="tv-modal-default_header">
          <div style={{ textAlign: "end" }}>
            <CloseCircle
              size="24"
              color="#98A2B3"
              onClick={handleModal}
              style={{ cursor: "pointer" }}
            />
          </div>

          <h6 className="tv-modal-default_header_title">
            Exportar todos os usuários
          </h6>
        </Modal.Header>

        <Modal.Body className="tv-modal-default_body">
          <p className="tv-modal-default_header_subtitle">
            Atenção! Vocês exportará todos os usuários ativos da base. Tem
            certeza que deseja fazer isso?
          </p>
        </Modal.Body>

        <Modal.Footer className="tv-modal-default_footer">
          <Button
            block
            className="tv-modal-default_footer_button cancel"
            size="sm"
            onClick={() => handleModal()}
          >
            <ArrowLeft2 size="24" color="#475467" />
            Cancelar
          </Button>
          <Button
            className="tv-modal-default_footer_button confirm"
            block
            size="sm"
            loading={loading}
          >
            <CSVLink
              className="tv-modal-default_footer_button export"
              filename={`Turivius - Usuarios.xls`}
              data={csvData}
              headers={headers}
              separator={";"}
              style={{ textDecoration: "none", color: "#fff" }}
            >
              {!loading ? (
                <>
                  <DocumentDownload size="24" color="#fff" />
                  Exportar
                </>
              ) : null}
            </CSVLink>
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ExportAllUsers;
