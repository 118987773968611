import React, { useState } from "react";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Modal, IconButton, toaster, Message } from "rsuite";
import { requester } from "../../requesters";
import { Turivius } from "../../ui";
import "./style.scss";

const DeleteServiceButton = ({ getPermissions, service, user }) => {
  const [openModal, setOpenModal] = useState(false);
  const [load, setLoad] = useState(false);

  function handleModal() {
    setOpenModal(!openModal);
  }

  function deleteUserSegmentation() {
    setLoad(true);

    requester.segmentation
      .deleteServiceSegmentationByUserId(service, user.id)
      .then((response) => {
        getPermissions(service);
        handleModal();
        toaster.push(
          <Message
            closable
            duration={4000}
            type={"success"}
            header={"Dados Atualizados"}
          >
            Configuração deletada com sucesso!
          </Message>,
        );
      })
      .catch((err) => {
        setLoad(false);
        toaster.push(
          <Message closable duration={4000} type={"error"}>
            Houve um problema de conexão, tente novamente em instantes.
          </Message>,
        );
      });
  }

  return (
    <>
      <Turivius.TuriviusTooltip
        trigger={"hover"}
        placement={"bottom"}
        message={"Clique aqui para remover as permissões do usuário"}
      >
        <IconButton
          block
          className="btn-delete-service"
          icon={<FontAwesomeIcon icon={faTrash} />}
          onClick={() => handleModal()}
        />
      </Turivius.TuriviusTooltip>

      <Modal open={openModal} onClose={handleModal}>
        <Modal.Header>
          <h4>Confirmar Ação</h4>
        </Modal.Header>

        <Modal.Body>
          <p style={{ fontSize: 15 }}>
            Deseja confirmar a deleção do plano para o usuário{" "}
            <b>{user.name}</b>? O usuário perderá as permissões de acesso.
          </p>
        </Modal.Body>

        <Modal.Footer>
          <Button onClick={handleModal}>Cancelar</Button>
          <Button loading={load} onClick={deleteUserSegmentation}>
            Confirmar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DeleteServiceButton;
