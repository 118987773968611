import React, { useEffect, useState } from "react";
import { Turivius } from "../../../ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faXmark } from "@fortawesome/free-solid-svg-icons";
import TuriviusColors from "../../../vars.module.scss";
import { requester } from "../../../requesters";
import "./style.scss";
import { Message, toaster } from "rsuite";

const columns = [
  { key: "id", label: "ID", fixed: true, width: 100 },
  { key: "name", label: "Nome", fixed: true, width: 200 },
  { key: "qt_cards", label: "Quantidade Cards", fixed: true, width: 200 },
  {
    key: "public_to_company",
    label: "Publico para empresa",
    fixed: true,
    width: 200,
    customCell: ({ rowData, dataKey, ...props }) => (
      <span>
        {rowData.public_to_company ? (
          <FontAwesomeIcon
            size="lg"
            color={TuriviusColors.green}
            icon={faCheck}
          />
        ) : (
          <FontAwesomeIcon
            size="lg"
            color={TuriviusColors.red}
            icon={faXmark}
          />
        )}
      </span>
    ),
  },
  {
    key: "shared_with.length",
    label: "Usuarios Compartilhados",
    fixed: true,
    width: 200,
  },
  {
    key: "last_updated",
    label: "Última Atualização",
    fixed: true,
    width: 200,
    customCell: ({ rowData, dataKey }) => (
      <span>
        {rowData[dataKey]
          ? new Date(rowData[dataKey]).toLocaleString("pt-BR")
          : "-"}
      </span>
    ),
  },
];

const UserColletions = ({ user }) => {
  const [load, setLoad] = useState(false);
  const [userCollections, setUserCollections] = useState([]);
  const [userCollectionsCount, setUserCollectionsCount] = useState(null);

  function getCollections(page) {
    setLoad(true);
    requester.collections
      .getUserCollections(user, page, false)
      .then((response) => {
        setUserCollections(response.user_boards.boards);
        setUserCollectionsCount(response.user_boards.count);
      })
      .catch((err) => {
        console.error(err);
        toaster.push(
          <Message
            closable
            duration={5000}
            type="error"
            header={`Erro ${err?.status}`}
          >
            {err?.message}
          </Message>,
        );
      })
      .finally(() => {
        setLoad(false);
      });
  }

  useEffect(() => {
    getCollections(1);
  }, []);

  return (
    <div className="user-collection-container">
      <Turivius.TuriviusTable
        data={userCollections}
        columns={columns}
        loading={load}
        height={500}
        fixed
        resizable
        paginate
        total={userCollectionsCount ?? 0}
        limit={10}
        onChangePage={getCollections}
      />
    </div>
  );
};

export default UserColletions;
