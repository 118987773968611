import { request } from "./requester";

function getAll() {
  return request({
    type: "GET",
    microservice: "search_notifications",
    action: `search_notification`,
    auth: "token",
  }).then((searchNotifications) => {
    return searchNotifications;
  });
}

function deleteByChannel(channel) {
  return request({
    type: "DELETE",
    microservice: "search_notifications",
    action: `search_notification/${channel}`,
    auth: "token",
  }).then((response) => {
    return response;
  });
}

export const searchNotifications = {
  getAll,
  deleteByChannel,
};
