import React from "react";
import PropTypes from "prop-types";
import "./TuriviusCard.scss";
import { Button, IconButton } from "rsuite";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDownload,
  faQuoteRight,
  faChartBar,
} from "@fortawesome/free-solid-svg-icons";

//Components
import TuriviusPanel from "../../component-encapsulation/turivius-panel/TuriviusPanel";
import TuriviusEntityIndicator from "../turivius-entity-indicator/TuriviusEntityIndicator";

const ementas =
  "ICMS. Credito indevido. Imposto destacado em documentos destinados a outro estabelecimento. ICMS. Credito indevido. Escrituração em duplicidade. ICMS. Credito indevido. Retorno de mercadorias remetidas para industrialização, com o imposto suspenso. ICMS. Credito indevido. Retorno de mercadoria de armazém geral, operação não tributada. ICMS. Credito indevido. Mercadoria para uso e consumo. ICMS. Credito indevido. Nota fiscal eletrônica cancelada. ICMS. Credito indevido. Imposto apropriado a menor. ICMS. Emitir notas fiscais com destaque do imposto em operações não tributadas. ICMS. Deixar de pagar. Falta de lançamento a débito no RAICMS, referente ao pagamento do imposto devido nas importações de mercadorias. Itens 1 a 6, 8 e 9, foram objeto de pagamento. Recurso conhecido e não provido";
const decisao =
  "Vistos e relatados estes autos em que são partes as acima indicadas, decide a Egrégia 1a. Turma do Tribunal Regional Federal da 4ª Região, por unanimidade, negar provimento ao apelo, nos termos do relatório, votos e notas taquigráficas que ficam fazendo parte integrante do presente julgado.";

const TuriviusCard = ({
  entity,
  name,
  dtPub,
  dtJug,
  onQuote,
  onWhole,
  onSave,
  onStatic,
  ementa,
  decision,
}) => (
  <TuriviusPanel>
    <div className="publishedContainer">
      <p>{dtJug}</p>
      <p>{dtPub}</p>
    </div>
    <div className="visualizeContainer">
      VISUALIZAR: {ementa ? <a href=""> EMENTA</a> : null},{" "}
      {decision ? <a href=""> DECISÃO</a> : null}
    </div>
    <div className="ementaContainer">
      <p className="ementaTitle">EMENTA</p>
      <p className="ementaText">{ementa}</p>
    </div>
    <div className="decisionContainer">
      <p className="decisionTitle">DECISÃO</p>
      <p className="decisionText">{decision}</p>
    </div>
    <div className="actionBottom">
      <Button className="btnActionSave">SALVAR</Button>
      <Button className="btnActionStatic" disabled={onStatic ? false : true}>
        <FontAwesomeIcon icon={faChartBar} />
        ESTATÍSTICA DO RELATOR
      </Button>
    </div>
  </TuriviusPanel>
);

TuriviusCard.propTypes = {
  entity: PropTypes.string,
  name: PropTypes.string,
  dtJug: PropTypes.string,
  dtPub: PropTypes.string,
  ementa: PropTypes.string,
  decision: PropTypes.string,
};

TuriviusCard.defaultProps = {
  entity: "TIT-SP",
  name: "DRT 10 - Recurso ordinario 3148559-5/2011 | 275055",
  dtJug: "Julgado em 17/10/2011",
  dtPub: "Publicado em 17/10/2011",
  ementa: ementas,
  decision: decisao,
};

export default TuriviusCard;
