import React, { useState } from "react";
import { MaskedInput, InputGroup } from "rsuite";
import PropTypes from "prop-types";

import "./TuriviusDateFilter.scss";

const TuriviusDateFilter = ({
  context,
  showHelperText,
  helperText,
  filters,
  filter,
  label,
  addon,
  posthog,
  ...props
}) => {
  const [errorInitial, setErrorInitial] = useState(false);
  const [errorFinal, setErrorFinal] = useState(false);

  function getMMDDYYYYDate(str) {
    const date = str.split("/");

    return `${date[1]}/${date[0]}/${date[2]}`;
  }

  function isValidDate(d) {
    return d instanceof Date && !isNaN(d);
  }

  function handleChangeSelectionBegin(value, a) {
    const dateInput = new Date(getMMDDYYYYDate(value));

    if (!isValidDate(dateInput)) {
      setErrorInitial(true);
    } else {
      setErrorInitial(false);
    }

    props.setTmpFilters(filter.field_name, {
      ...filters[filter.field_name],
      0: value,
    });
  }

  function handleChangeSelectionEnd(value) {
    const dateInput = new Date(getMMDDYYYYDate(value));

    if (!isValidDate(dateInput)) {
      setErrorFinal(true);
    } else {
      setErrorFinal(false);
    }

    props.setTmpFilters(filter.field_name, {
      ...filters[filter.field_name],
      1: value,
    });
  }

  function handleSelectionEndBlur(e) {
    const dateStringInput = e.target.value;
    const dateInput = new Date(getMMDDYYYYDate(dateStringInput));

    if (isValidDate(dateInput)) {
      if (
        dateInput < new Date(getMMDDYYYYDate(filters[filter.field_name]["0"]))
      ) {
        props.setTmpFilters(filter.field_name, {
          ...filters[filter.field_name],
          0: dateStringInput,
          1: filters[filter.field_name]["0"],
        });
      } else {
        props.setTmpFilters(filter.field_name, {
          ...filters[filter.field_name],
          1: dateStringInput,
        });
      }

      if (context) {
        posthog.capture(context, {
          action: "change_filter",
          id: filter.field_name,
        });
      }
    } else {
      setErrorFinal(true);
    }
  }

  function handleSelectionBeginBlur(e) {
    const dateStringInput = e.target.value;
    const dateInput = new Date(getMMDDYYYYDate(dateStringInput));

    if (isValidDate(dateInput)) {
      if (
        dateInput > new Date(getMMDDYYYYDate(filters[filter.field_name]["1"]))
      ) {
        props.setTmpFilters(filter.field_name, {
          ...filters[filter.field_name],
          0: filters[filter.field_name]["1"],
          1: dateStringInput,
        });
      } else {
        props.setTmpFilters(filter.field_name, {
          ...filters[filter.field_name],
          0: dateStringInput,
        });
      }
      if (context) {
        posthog.capture(context, {
          action: "change_filter",
          id: filter.field_name,
        });
      }
    } else {
      setErrorInitial(true);
      setTimeout(() => {
        setErrorInitial(false);
      }, 200);
    }
  }

  return filters && filters[filter.field_name] ? (
    <div>
      <div className="title">
        <label>{label}</label>
      </div>

      <InputGroup>
        <MaskedInput
          id="initial-date-filter"
          className={errorInitial ? "rs-custom-input-error" : ""}
          mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}
          // guide = {true}
          // showMask={true}
          keepCharPositions={false}
          placeholder="__/__/____"
          value={filters[filter.field_name]["0"]}
          onChange={() => handleChangeSelectionBegin()}
          onBlur={() => handleSelectionBeginBlur()}
        />
        <InputGroup.Addon>{addon || "até"}</InputGroup.Addon>
        <MaskedInput
          id="final-date-filter"
          className={errorFinal ? "rs-custom-input-error" : ""}
          mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}
          // guide={true}
          // showMask={true}
          keepCharPositions={false}
          placeholder="__/__/____"
          value={filters[filter.field_name]["1"]}
          onChange={() => handleChangeSelectionEnd()}
          onBlur={() => handleSelectionEndBlur()}
        />
      </InputGroup>

      {showHelperText ? (
        helperText ? (
          <div className="input-field-header" style={{ marginBottom: 12 }}>
            {helperText}
          </div>
        ) : (
          <div className="input-field-header" style={{ marginBottom: 12 }}>
            <span className="rs-custom-input-helper-text">
              {filter.help ? filter.help.replace(/&#013/gi, "") : <>&nbsp;</>}
            </span>
          </div>
        )
      ) : null}
    </div>
  ) : null;
};

TuriviusDateFilter.propTypes = {
  context: PropTypes.string,
  filter: PropTypes.object,
  label: PropTypes.string,
  showHelperText: PropTypes.bool,
  helperText: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  posthog: PropTypes.object,

  // REDUX
  filters: PropTypes.object,
  setTmpFilters: PropTypes.func,
};

TuriviusDateFilter.defaultProps = {
  label: "",
  showHelperText: false,
};

export default TuriviusDateFilter;
