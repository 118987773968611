import React, { useEffect, useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faXmark } from "@fortawesome/free-solid-svg-icons";
import {
  Modal,
  Button,
  Whisper,
  Tooltip,
  IconButton,
  toaster,
  Message,
} from "rsuite";
import { requester } from "../../requesters";

//Config
import TuriviusColors from "../../vars.module.scss";
import "./style.scss";

export const UpdateBIButton = ({ data, table }) => {
  const modalRef = useRef();
  const [openModal, setOpenModal] = useState(false);
  const [load, setLoad] = useState(false);
  const [status, setStatus] = useState(null);

  function handleModal() {
    setOpenModal(!openModal);
  }

  function setStatusItem() {
    switch (status) {
      case true:
        return "ativo";
      case false:
        return "bloqueado";
      default:
        return "--";
    }
  }

  function onToggleUserBITributario() {
    const { id } = data;
    setLoad(true);

    if (status) {
      requester.admin
        .disableUserBITributario(id)
        .then((response) => {
          setStatus(!status);
          handleModal();
          toaster.push(
            <Message
              showIcon
              type="success"
              duration={4000}
              header={`Permissões de BI Tributário ${status ? "Revogada" : "Liberada"}`}
            >
              {`As permissões de BI Tributário foram ${status ? "revogadas" : "liberadas"} com sucesso!`}
            </Message>,
          );
        })
        .catch((err) => {
          console.log(err);
          toaster.push(
            <Message showIcon type="error" duration={4000}>
              Ocorreu um erro, tente novamente ou entre em contato com o
              suporte.
            </Message>,
          );
        })
        .finally(() => {
          setLoad(false);
        });
    } else {
      requester.admin
        .enableUserBITributario(id)
        .then((response) => {
          setStatus(!status);
          handleModal();
          toaster.push(
            <Message
              showIcon
              type="success"
              duration={4000}
              header={`Permissões de BI Tributário ${status ? "Revogada" : "Liberada"}`}
            >
              {`As permissões de BI Tributário foram ${status ? "revogadas" : "liberadas"} com sucesso!`}
            </Message>,
          );
        })
        .catch((err) => {
          console.log(err);
          toaster.push(
            <Message showIcon type="error" duration={4000}>
              Ocorreu um erro, tente novamente ou entre em contato com o
              suporte.
            </Message>,
          );
        })
        .finally(() => {
          setLoad(false);
        });
    }
  }

  useEffect(() => {
    const { dashboard } = data;
    setStatus(dashboard);
  }, [data]);

  return (
    <div className="status-container">
      <Whisper
        trigger="hover"
        placement={table ? "top" : "bottomEnd"}
        controlId={`control-id-${status}`}
        speaker={
          <Tooltip>{`Clique aqui para ${status ? "desativar" : "ativar"} as permissões de BI`}</Tooltip>
        }
      >
        {table ? (
          <Button className="status-button" onClick={handleModal}>
            <FontAwesomeIcon
              style={{ marginRight: "10px" }}
              color={status ? TuriviusColors.green : TuriviusColors.red}
              size="lg"
              icon={status ? faCheck : faXmark}
            />

            <p className={`status ${setStatusItem()}`}>{setStatusItem()}</p>
          </Button>
        ) : (
          <IconButton
            block
            className={`btn-alter-status ${status ? "active" : "inactive"}`}
            onClick={handleModal}
            icon={
              <FontAwesomeIcon
                className="rs-icon"
                icon={status ? faCheck : faXmark}
              />
            }
          >
            {status ? "Ativo" : "Inativo"}
          </IconButton>
        )}
      </Whisper>

      <Modal ref={modalRef} open={openModal} onClose={handleModal}>
        <Modal.Header>
          <h4>Confirmar Ação</h4>
        </Modal.Header>
        <Modal.Body>
          <p>
            {`Deseja ${status ? "desativar" : "ativar"} as permissões de BI Tributário para o usuário ${data.name}?`}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleModal}>Cancelar</Button>
          <Button loading={load} onClick={onToggleUserBITributario}>
            Confirmar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default UpdateBIButton;
