import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { Turivius } from "../../ui";
import { Input, Col, Row, Grid, Button } from "rsuite";
import { requester } from "../../requesters";

import { UpdateStatusUserButton } from "../../components/UpdateStatusButton";
import GoDetailsLink from "../../components/GoDetailsLink";
import ExportAllUsers from "../../modules/users/components/ExportAllUsers";
import "./style.scss";

const columns = [
  { key: "id", label: "ID", fixed: false, width: 100 },
  { key: "name", label: "Nome", fixed: false, width: 200, resizable: true },
  {
    key: "email",
    label: "E-Mail",
    fixed: false,
    width: 200,
    resizable: true,
    customCell: ({ rowData, dataKey }) => (
      <div
        style={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        <Turivius.TuriviusTooltip
          trigger="hover"
          message={`Clique para enviar e-mail para ${rowData.email}`}
          placement="top"
        >
          <a href={`mailto:${rowData.email}`}>{rowData.email}</a>
        </Turivius.TuriviusTooltip>
      </div>
    ),
  },
  {
    key: "company",
    label: "Escritório",
    fixed: false,
    width: 200,
    resizable: true,
    customCell: ({ rowData, dataKey, ...props }) => (
      <GoDetailsLink data={rowData.company} typeData={"company"}>
        {rowData.company.name}
      </GoDetailsLink>
    ),
  },
  {
    key: "active",
    label: "Status",
    fixed: false,
    width: 125,

    customCell: ({ rowData, dataKey, ...props }) => (
      <UpdateStatusUserButton table data={rowData} type={"user"} />
    ),
  },
  {
    key: "is_admin",
    label: "Tipo",
    fixed: false,
    width: 150,
    customCell: ({ rowData, dataKey, ...props }) => (
      <p>{rowData.is_admin ? "Administrador" : "Comum"}</p>
    ),
  },
  // {
  //     key: 'action',
  //     label: 'Ações',
  //     fixed: false,
  //     width: 100,
  //     customCell: ({ rowData, dataKey, ...props }) => (
  //         <GoDetailsButton data={rowData} typeData={"user"} />
  //     )
  // },
];

const Users = (props) => {
  const [genericFilter, setGenericFilter] = useState("");
  const [genericFilterSearchTimeout, setGenericFilterSearchTimeout] =
    useState(null);
  const [users, setUsers] = useState([]);
  const [usersCount, setUsersCount] = useState(null);
  const [load, setLoad] = useState(true);
  const [loadSearching, setLoadSearching] = useState(false);
  const [activePage, setActivePage] = useState(1);

  const getUsers = (page) => {
    setLoad(true);
    requester.admin
      .getUsers({ page, pageSize: 10, ordering: "id", genericFilter })
      .then((response) => {
        setUsers(response.profiles);
        setUsersCount(response.count);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoad(false);
      });
  };

  const changeGenericFilter = (value) => {
    setLoadSearching(true);
    clearTimeout(genericFilterSearchTimeout);
    setGenericFilterSearchTimeout(
      setTimeout(() => {
        getUsers(1);
        setActivePage(1);
        setLoadSearching(false);
      }, 1000),
    );
  };

  function directPage(data) {
    props.history.push(`/usuarios/${data.id}`);
  }

  function keyPressSearch(e) {
    if (e.key === "Enter") {
      changeGenericFilter();
    }
  }

  useEffect(() => {
    document.title = `Usuários`;
    getUsers(1);
  }, []);

  return (
    <Grid fluid>
      <div className="container-users">
        <Row className="users-header">
          <Col xs={24} sm={24} md={19}>
            <h1>Usuários</h1>
          </Col>
          <Col xs={24} sm={24} md={5}>
            <ExportAllUsers />
          </Col>
        </Row>

        <Row className="users-search">
          <Col xs={24} sm={24} md={19}>
            <Input
              className="input-search"
              placeholder="Filtro Genérico"
              value={genericFilter}
              onChange={setGenericFilter}
              onKeyUp={keyPressSearch}
            />
          </Col>
          <Col xs={24} sm={24} md={5}>
            <Button
              block
              className="users-search-button"
              loading={loadSearching}
              onClick={changeGenericFilter}
            >
              Buscar
            </Button>
          </Col>
        </Row>

        <div className="users-body">
          <Turivius.TuriviusTable
            wordWrap
            data={users}
            columns={columns}
            loading={load}
            height={500}
            onRowClick={(users) => directPage(users)}
            paginate
            total={usersCount}
            limit={10}
            onChangePage={(page) => {
              getUsers(page);
              setActivePage(page);
            }}
            activePage={activePage}
          />
        </div>
      </div>
    </Grid>
  );
};

export default withRouter(Users);
