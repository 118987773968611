import React, { useState, useEffect } from "react";
import { requester } from "../../requesters";
import {
  faFileArrowDown,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton, Modal, Button } from "rsuite";
import { Turivius } from "../../ui";
import { permissions } from "../../config/permissions";
import "./style.scss";

import { CSVLink } from "react-csv";

const headers = [
  { label: "ID", key: "id" },
  { label: "Ativo", key: "active" },
  { label: "Nome", key: "name" },
  { label: "E-mail", key: "email" },
  { label: "Company ID", key: "company_id" },
  { label: "Administrador", key: "is_admin" },
  // { label: "Quantidade de Coleções", key: "qt_boards" },
  // { label: "Quantidade de Monitoramento", key: "qt_monitoramento" },
  // { label: "Quantidade de Pesquisas", key: "qt_pesquisas" },
  { label: "Data de Criação", key: "created" },
  { label: "Última Atualização", key: "last_modified" },
];

const ExportUsersDataButton = ({ company, users, getUsersByCompany }) => {
  const [openModal, setOpenModal] = useState(false);
  const [csvData, setCsvData] = useState([]);

  function handleModal() {
    setOpenModal(!openModal);
  }

  async function exportUserData() {
    // console.log(csvData)
    permissions.forEach((permission) => {
      const userIds = users.reduce((acc, value) => {
        acc.push(value.id);
        return acc;
      }, []);
      requester.segmentation
        .getServiceSegmentationByUsersIds(permission, userIds)
        .then(async (response) => {
          let permissionKeys = response
            .reduce((acc, value) => [...acc, ...Object.keys(value)], [])
            .filter((value, i, self) => self.indexOf(value) === i);

          let usersWithPermissions = users.map((user) => {
            const userPermission = response.find(
              (permission) => permission.user_id === user.id.toString(),
            );
            if (userPermission) {
              return {
                id: user.id,
                name: user.name,
                email: user.email,
                company_id: user.company_id,
                is_admin: user.is_admin,
                active: user.active,
                last_modified: user.last_modified,
                created: user.created,
                ...userPermission,
              };
            }
            return {
              id: user.id,
              name: user.name,
              email: user.email,
              company_id: user.company_id,
              is_admin: user.is_admin,
              last_modified: user.last_modified,
              active: user.active,
              created: user.created,
            };
          });
          // console.table(usersWithPermissions, ["id", "name", "email", "company_id", "is_admin", "created_at", ...permissionKeys])
          setCsvData(usersWithPermissions);
          // console.log(usersWithPermissions)
        })
        .catch(console.error);
    });
  }

  useEffect(() => {
    if (users === null) {
      getUsersByCompany();
    }
  }, []);

  return (
    <>
      <Turivius.TuriviusTooltip
        trigger="hover"
        placement="left"
        message={`Clique aqui para exportar os dados dos usuário referente a empresa ${company.name}`}
      >
        <IconButton
          block
          className="btn-export-data"
          onClick={() => {
            handleModal();
            exportUserData();
          }}
          icon={<FontAwesomeIcon className="rs-icon" icon={faFileArrowDown} />}
        >
          Exportar
        </IconButton>
      </Turivius.TuriviusTooltip>

      <Modal open={openModal} onClose={handleModal}>
        <Modal.Header>
          <h4>Exportar de Dados</h4>
        </Modal.Header>
        <Modal.Body>
          <p style={{ fontSize: 15 }}>
            Deseja gerar um arquivo <b>.xls</b> contendo as informações de
            usuário da empresa {company.name}?{" "}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleModal}>Cancelar</Button>
          <Button
            onClick={() => {
              exportUserData();
              handleModal();
            }}
            loading={csvData.length > 0 ? false : true}
          >
            {csvData.length > 0 ? (
              <CSVLink
                filename={`${company.name} - Usuarios.xls`}
                data={csvData}
                headers={headers}
                separator={";"}
                style={{ textDecoration: "none", color: "#060606" }}
              >
                Exportar
              </CSVLink>
            ) : (
              "Loading"
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ExportUsersDataButton;
