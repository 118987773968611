import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Button, IconButton, Input } from "rsuite";

import "./style.scss";

const TuriviusMultipleInput = (props) => {
  const [fields, setFields] = useState([]);

  const { name, error, onChange } = props;

  function handleChange(id, value, event) {
    let newFields = [...fields]; // copia fields antigos
    newFields = newFields.map((field) => {
      // atualiza field por id
      if (field.id === id) {
        field.value = value;
      }
      return field;
    });
    setFields(newFields);
    onChange(
      newFields.map((f) => f.value),
      event,
    );
  }

  function handleAddField() {
    const newFields = [
      ...fields,
      {
        id: fields.length === 0 ? 0 : fields[fields.length - 1].id + 1,
        value: "",
      },
    ];
    setFields(newFields);
    onChange(newFields.map((f) => f.value));
  }

  function handleRemoveField(id) {
    let newFields = [...fields]; // copia fields antigos
    newFields = newFields.filter((field) => field.id !== id); // remove field por id
    setFields(newFields);
    onChange(newFields.map((f) => f.value));
  }

  return (
    <div style={{ margin: "0px 4px" }}>
      {fields.map((field, i) => (
        <div key={field.id}>
          <div
            style={{ display: "inline-flex", width: "100%", margin: "4px 0px" }}
          >
            <Input
              value={field.value}
              onChange={(value, event) => handleChange(field.id, value, event)}
              style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
            />
            <IconButton
              color="red"
              appearance="primary"
              onClick={() => handleRemoveField(field.id)}
              icon={<FontAwesomeIcon icon={faTrash} />}
              style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
            />
          </div>
          {error?.array[i]?.hasError ? (
            <div
              id={`${name}-error-${field.id}`}
              style={{ position: "relative" }}
              role="alert"
              aria-relevant="all"
              className="rs-form-control-message-wrapper rs-form-error-message-wrapper rs-form-error-message-placement-bottom-start"
            >
              <span className="rs-form-error-message rs-form-error-message-show">
                <span className="rs-form-error-message-arrow"></span>
                <span className="rs-form-error-message-inner">
                  {error?.array[i]?.errorMessage}
                </span>
              </span>
            </div>
          ) : null}
        </div>
      ))}
      <Button onClick={handleAddField} block apperarance="primary">
        Adicionar campo
      </Button>
    </div>
  );
};

export default TuriviusMultipleInput;
