import React, { useEffect, useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faXmark } from "@fortawesome/free-solid-svg-icons";
import {
  Modal,
  Button,
  Whisper,
  Tooltip,
  IconButton,
  toaster,
  Message,
} from "rsuite";
import { requester } from "../../requesters";

//Config
import TuriviusColors from "../../vars.module.scss";
import "./style.scss";

/*****
 *   Status Button for Company
 ******/

export const UpdateStatusCompanyButton = ({ data, table }) => {
  const modalRef = useRef();
  const [openModal, setOpenModal] = useState(false);
  const [load, setLoad] = useState(false);
  const [status, setStatus] = useState(null);

  function handleModal() {
    setOpenModal(!openModal);
  }

  function setStatusItem() {
    switch (!status) {
      case true:
        return "ativo";
      case false:
        return "bloqueado";
      default:
        return "--";
    }
  }

  function onToggleCompanyActivity() {
    const { id } = data;

    setLoad(true);

    requester.admin
      .toggleCompanyActivity(id, !status)
      .then((response) => {
        setStatus(!status);
        handleModal();
        toaster.push(
          <Message
            showIcon
            type="success"
            duration={4000}
            header={`Empresa ${!status ? "Bloqueada" : "Ativada"}`}
          >
            {`A empresa foi ${!status ? "bloqueada" : "ativada"} com sucesso!`}
          </Message>,
        );
      })
      .catch((err) => {
        console.log(err);
        toaster.push(
          <Message showIcon type="error" duration={4000}>
            Ocorreu um erro, tente novamente ou entre em contato com o suporte.
          </Message>,
        );
      })
      .finally(() => {
        setLoad(false);
      });
  }

  useEffect(() => {
    const { blocked } = data;
    setStatus(blocked);
  }, [data]);

  return (
    <div className="status-container">
      <Whisper
        trigger="hover"
        placement={table ? "top" : "bottomEnd"}
        controlId={`control-id-${status}`}
        speaker={
          <Tooltip>{`Clique aqui para ${status ? "inativar" : "ativar"} ${data.name}`}</Tooltip>
        }
      >
        {table ? (
          <Button className="status-button" onClick={handleModal}>
            <FontAwesomeIcon
              style={{ marginRight: "10px" }}
              color={!status ? TuriviusColors.green : TuriviusColors.red}
              size="lg"
              icon={!status ? faCheck : faXmark}
            />

            <p className={`status ${setStatusItem()}`}>{setStatusItem()}</p>
          </Button>
        ) : (
          <IconButton
            block
            className={`btn-alter-status ${!status ? "active" : "inactive"}`}
            onClick={handleModal}
            icon={
              <FontAwesomeIcon
                className="rs-icon"
                icon={!status ? faCheck : faXmark}
              />
            }
          >
            {!status ? "Ativo" : "Inativo"}
          </IconButton>
        )}
      </Whisper>

      <Modal ref={modalRef} open={openModal} onClose={handleModal}>
        <Modal.Header>
          <h4>Confirmar Ação</h4>
        </Modal.Header>
        <Modal.Body>
          <p>{`Deseja ${!status ? "bloquear" : "ativar"} ${data.name}?`}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleModal}>Cancelar</Button>
          <Button loading={load} onClick={onToggleCompanyActivity}>
            Confirmar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

/*****
 *   Status Button for User
 ******/

export const UpdateStatusUserButton = ({ data, table }) => {
  const modalRef = useRef();
  const [openModal, setOpenModal] = useState(false);
  const [load, setLoad] = useState(false);
  const [status, setStatus] = useState(null);

  function handleModal() {
    setOpenModal(!openModal);
  }

  function setStatusItem() {
    switch (status) {
      case true:
        return "ativo";
      case false:
        return "bloqueado";
      default:
        return "--";
    }
  }

  function onToggleUserActivity() {
    const { id } = data;

    setLoad(true);

    requester.admin
      .toggleUserActivity(id, !status)
      .then((response) => {
        setStatus(!status);
        handleModal();
        toaster.push(
          <Message
            showIcon
            type="success"
            duration={4000}
            header={`Usuário ${!status ? "Ativado" : "Bloqueado"}`}
          >
            {`O usuário foi ${!status ? "ativado" : "bloqueado"} com sucesso!`}
          </Message>,
        );
      })
      .catch((err) => {
        console.error(err);
        toaster.push(
          <Message showIcon type="error" duration={4000}>
            Ocorreu um erro, tente novamente ou entre em contato com o suporte.
          </Message>,
        );
      })
      .finally(() => {
        setLoad(false);
      });
  }

  useEffect(() => {
    const { active } = data;
    setStatus(active);
  }, [data]);

  return (
    <div className="status-container">
      <Whisper
        trigger="hover"
        placement={table ? "top" : "bottomEnd"}
        controlId={`control-id-${status}`}
        speaker={
          <Tooltip>{`Clique aqui para ${status ? "inativar" : "ativar"} ${data.name}`}</Tooltip>
        }
      >
        {table ? (
          <Button className="status-button" onClick={handleModal}>
            <FontAwesomeIcon
              style={{ marginRight: "10px" }}
              color={status ? TuriviusColors.green : TuriviusColors.red}
              size="lg"
              icon={status ? faCheck : faXmark}
            />

            <p className={`status ${setStatusItem()}`}>{setStatusItem()}</p>
          </Button>
        ) : (
          <IconButton
            block
            className={`btn-alter-user-status ${status ? "active" : "inactive"}`}
            onClick={handleModal}
            icon={
              <FontAwesomeIcon
                className="rs-icon"
                icon={status ? faCheck : faXmark}
              />
            }
          >
            {status ? "Ativo" : "Bloqueado"}
          </IconButton>
        )}
      </Whisper>

      <Modal ref={modalRef} open={openModal} onClose={handleModal}>
        <Modal.Header>
          <h4>Confirmar Ação</h4>
        </Modal.Header>
        <Modal.Body>
          <p>{`Deseja ${status ? "inativar" : "ativar"} ${data.name}?`}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleModal}>Cancelar</Button>
          <Button loading={load} onClick={onToggleUserActivity}>
            Confirmar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
