import React from "react";
import { Whisper, Popover } from "rsuite";
import PropTypes from "prop-types";
import "./TuriviusPopover.scss";

const TuriviusPopover = ({
  id,
  trigger,
  children,
  message,
  placement,
  title,
  visible,
}) => (
  <Whisper
    visible={visible}
    trigger={trigger}
    placement={placement}
    controlId={id ? id : `control-id-${placement}`}
    speaker={<Popover title={title}>{message}</Popover>}
  >
    {children}
  </Whisper>
);

TuriviusPopover.propTypes = {
  id: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  title: PropTypes.string,
  message: PropTypes.node,
  visible: PropTypes.bool,
  trigger: PropTypes.oneOf([
    "click",
    "contextMenu",
    "hover",
    "focus",
    "active",
    "none",
  ]),
  placement: PropTypes.oneOf([
    "top",
    "bottom",
    "right",
    "left",
    "bottomStart",
    "bottomEnd",
    "topStart",
    "topEnd",
    "leftStart",
    "leftEnd",
    "rightStart",
    "rightEnd",
    "auto",
    "autoVerticalStart",
    "autoVerticalEnd",
    "autoHorizontalStart",
    "autoHorizontalEnd",
  ]),
};

TuriviusPopover.defaultProps = {
  trigger: "click",
  placement: "auto",
  visible: true,
};

export default TuriviusPopover;
