export const action = [
  { id: 1, label: "BI Tributário", value: 1 },
  { id: 2, label: "Status", value: 2 },
  { id: 3, label: "Administrador", value: 3 },
];

export const values = [
  { id: 1, label: "Ativar", value: "true" },
  { id: 2, label: "Desativar", value: "false" },
];

export const planos = [
  { id: 9, name: "Plano Light" },
  { id: 8, name: "Plano Pró" },
  { id: 7, name: "Plano Enterprise" },
];

export const areas = [
  { id: "Ambiental", label: "Ambiental", value: "Ambiental" },
  { id: "Cível", label: "Cível", value: "Cível" },
  { id: "Consumidor", label: "Consumidor", value: "Consumidor" },
  { id: "Empresarial", label: "Empresarial", value: "Empresarial" },
  { id: "Penal", label: "Penal", value: "Penal" },
  { id: "Trabalhista", label: "Trabalhista", value: "Trabalhista" },
  { id: "Tributário", label: "Tributário", value: "Tributário" },
  { id: "Outros", label: "Outros", value: "Outros" },
];

export const occupation_area = [
  { id: "DJ", label: "Departamento Jurídico", value: "DJ" },
  { id: "EA", label: "Escritório de Advocacia", value: "EA" },
];
export const performsJurisprudentialSearch = [
  { id: 1, label: "Sim", value: "true" },
  { id: 2, label: "Não", value: "false" },
];

export const companies = [
  {
    id: 2312,
    name: "Turivius",
    email: "contato@turivius.com",
    status: true,
    users: 150,
    usersactived: 102,
  },
  {
    id: 2344,
    name: "HyperX",
    email: "contato@turivius.com",
    status: false,
    users: 30,
    usersactived: 27,
  },
  {
    id: 1256,
    name: "Logitech",
    email: "contato@turivius.com",
    status: false,
    users: 30,
    usersactived: 27,
  },
  {
    id: 8756,
    name: "Samsung",
    email: "contato@turivius.com",
    status: null,
    users: 30,
    usersactived: 27,
  },
  {
    id: 1739,
    name: "Amazon",
    email: "contato@turivius.com",
    status: true,
    users: 30,
    usersactived: 27,
  },
  {
    id: 5670,
    name: "Brastemp",
    email: "contato@turivius.com",
    status: true,
    users: 30,
    usersactived: 27,
  },
  {
    id: 3456,
    name: "Microsoft",
    email: "contato@turivius.com",
    status: true,
    users: 30,
    usersactived: 27,
  },
  {
    id: 2345,
    name: "Intelbras",
    email: "contato@turivius.com",
    status: true,
    users: 30,
    usersactived: 27,
  },
  {
    id: 6547,
    name: "Chilli Beans",
    email: "contato@turivius.com",
    status: true,
    users: 30,
    usersactived: 27,
  },
  {
    id: 1444,
    name: "Reckitt Benckiser",
    email: "contato@turivius.com",
    status: true,
    users: 30,
    usersactived: 27,
  },
  {
    id: 5333,
    name: "Arno",
    email: "contato@turivius.com",
    status: true,
    users: 30,
    usersactived: 27,
  },
  {
    id: 8455,
    name: "Gerdau",
    email: "contato@turivius.com",
    status: true,
    users: 30,
    usersactived: 27,
  },
  {
    id: 9557,
    name: "Nestle",
    email: "contato@turivius.com",
    status: true,
    users: 30,
    usersactived: 27,
  },
  {
    id: 1635,
    name: "Tupperware",
    email: "contato@turivius.com",
    status: true,
    users: 30,
    usersactived: 27,
  },
];

export const users = [
  {
    id: 42,
    name: "Juliano Cézar",
    email: "juliano@turivius.com",
    description: "O desenrolado dda Turivius",
    address: "Maringá - PR",
    cargo: "Desenvolvedor",
    telefone: "(xx) xxxxx-xxxx",
    areas_direito: "",
    agreements: "",
    status: true,
    is_admin: true,
    last_modified: "22/02/2022 às 10:28",
    company: {
      id: 2312,
      name: "Turivius",
      email: "contato@turivius.com",
      status: true,
      description: "Perfil Oficial da Turivius",
      address: "abACATE",
      telefone: "+55 48 1234-5678",
    },
    collections: 15,
    monitoring: 10,
    dashboard: false,
    segmentos: [
      {
        id: 1000,
        status: true,
        name: "Sistema Tributário",
        plano: 7,
        entity: "Todas",
        jurimetrics: "CARF",
        filter_unanimity: false,
        filter_favorability: false,
      },
      {
        id: 2000,
        status: true,
        name: "Sistema Trabalhista",
        plano: 9,
        entity: null,
        jurimetrics: null,
        filter_unanimity: true,
        filter_favorability: false,
      },
      {
        id: 3000,
        status: true,
        name: "Sistema Cívil",
        plano: 8,
        entity: null,
        jurimetrics: null,
        filter_unanimity: true,
        filter_favorability: true,
      },
      {
        id: 4000,
        status: true,
        name: "BI Tributário",
        plano: 99,
        entity: null,
        jurimetrics: null,
        filter_unanimity: true,
        filter_favorability: true,
      },
    ],
  },
  {
    id: 2851,
    name: "Jóbia Damone",
    email: "jobia@turivius.com",
    description: "indizeira",
    address: "Caruaru - PE",
    cargo: "Front-End",
    telefone: "(81) 99612-6534",
    areas_direito: "",
    agreements: "",
    status: true,
    is_admin: false,
    last_modified: "22/02/2022 às 10:40",
    company: {
      id: 2312,
      name: "Turivius",
      email: "contato@turivius.com",
      status: true,
      description: "Perfil Oficial da Turivius",
      address: "abACATE",
      telefone: "+55 48 1234-5678",
    },
    collections: 5,
    monitoring: 10,
    dashboard: true,
    segmentos: [
      {
        id: 1000,
        status: true,
        name: "Sistema Tributário",
        plano: 7,
        entity: "Todas",
        jurimetrics: "CARF",
        filter_unanimity: false,
        filter_favorability: false,
      },
      {
        id: 2000,
        status: false,
        name: "Sistema Trabalhista",
        plano: null,
        entity: null,
        jurimetrics: null,
        filter_unanimity: false,
        filter_favorability: false,
      },
      {
        id: 3000,
        status: false,
        name: "Sistema Cívil",
        plano: null,
        entity: null,
        jurimetrics: null,
        filter_unanimity: false,
        filter_favorability: false,
      },
      {
        id: 4000,
        status: true,
        name: "BI Tributário",
        plano: 99,
        entity: null,
        jurimetrics: null,
        filter_unanimity: true,
        filter_favorability: true,
      },
    ],
  },
  {
    id: 50,
    name: "Guilherme Kenzo",
    email: "guilherme@turivius.com",
    description: "CTO de milhões",
    address: "Florianopolis",
    cargo: "CTO Turivius",
    telefone: "(yy) yyyyy-yyyy",
    areas_direito: "",
    agreements: "",
    status: true,
    is_admin: true,
    last_modified: "23/02/2022 às 14:55",
    company: {
      id: 2312,
      name: "Turivius",
      email: "contato@turivius.com",
      status: true,
      description: "Perfil Oficial da Turivius",
      address: "abACATE",
      telefone: "+55 48 1234-5678",
    },
    collections: 5,
    monitoring: 10,
    dashboard: true,
    segmentos: [
      {
        id: 1000,
        status: true,
        name: "Sistema Tributário",
        plano: 7,
        entity: "Todas",
        jurimetrics: "CARF",
        filter_unanimity: false,
        filter_favorability: false,
      },
      {
        id: 2000,
        status: false,
        name: "Sistema Trabalhista",
        plano: null,
        entity: null,
        jurimetrics: null,
        filter_unanimity: false,
        filter_favorability: false,
      },
      {
        id: 3000,
        status: false,
        name: "Sistema Cívil",
        plano: null,
        entity: null,
        jurimetrics: null,
        filter_unanimity: false,
        filter_favorability: false,
      },
      {
        id: 4000,
        status: true,
        name: "BI Tributário",
        plano: 99,
        entity: null,
        jurimetrics: null,
        filter_unanimity: true,
        filter_favorability: true,
      },
    ],
  },
];

export const history = [
  {
    user: 2851,
    query: 'tributário e "taxa selic" e IRPJ',
    dt: "22/02/2022 - 17:17:33",
    url: "https://app.turivius.com/resultados?query=tribut%C3%A1rio%20e%20%22taxa%20selic%22%20e%20IRPJ&filters={%22entity%22:[0],%22date_range%22:{%220%22:%2201/01/2010%22,%221%22:%2204/01/2022%22},%22filter_by_favorability%22:[0,1,2,3,4]}",
  },
  {
    user: 2851,
    query: "crime ambiental",
    dt: "23/02/2022 - 16:42:41",
    url: "https://app.turivius.com/resultados?query=crime%20ambiental&filters={%22entity%22:[0,3,40,41,5,26,28,29,27,4,33,30,32,34,1,2,7,43,42,24,6,8,11,12,13,15,17,18,19,23,25,31,10,36,37,35,16],%22date_range%22:{%220%22:%2201/01/2010%22,%221%22:%2222/02/2022%22},%22filter_by_favorability%22:[0,1,2,3,4]}",
  },
  {
    user: 2851,
    query: "ICMS",
    dt: "13/02/2022 - 14:11:51",
    url: "https://app.turivius.com/resultados?query=icms&filters={%22entity%22:[0],%22date_range%22:{%220%22:%2201/01/2010%22,%221%22:%2231/01/2022%22},%22filter_by_favorability%22:[0,1,2,3,4]}",
  },
  {
    user: 42,
    query: "ICMS",
    dt: "21/02/2022 - 14:11:51",
    url: "https://app.turivius.com/resultados?query=icms&filters={%22entity%22:[0],%22date_range%22:{%220%22:%2201/01/2010%22,%221%22:%2231/01/2022%22},%22filter_by_favorability%22:[0,1,2,3,4]}",
  },
  {
    user: 42,
    query: "crime ambiental",
    dt: "23/02/2022 - 16:42:41",
    url: "https://app.turivius.com/resultados?query=crime%20ambiental&filters={%22entity%22:[0,3,40,41,5,26,28,29,27,4,33,30,32,34,1,2,7,43,42,24,6,8,11,12,13,15,17,18,19,23,25,31,10,36,37,35,16],%22date_range%22:{%220%22:%2201/01/2010%22,%221%22:%2222/02/2022%22},%22filter_by_favorability%22:[0,1,2,3,4]}",
  },
];

export const collections = [
  {
    user: 42,
    boards: [
      {
        id: 3747,
        name: "teste",
        qt_cards: 1,
        last_updated: "23/02/2022 - 16:14",
        shared_with: [{ id: 208 }, { id: 1 }, { id: 2851 }],
        deleted: false,
        public_to_company: false,
      },
      {
        id: 3747,
        name: "ação de cobrança",
        qt_cards: 15,
        last_updated: "23/02/2022 - 16:14",
        shared_with: [],
        deleted: false,
        public_to_company: true,
      },
      {
        id: 3747,
        name: "Coronavírus",
        qt_cards: 56,
        last_updated: "24/02/2022 - 15:31",
        shared_with: [{ id: 208 }],
        deleted: false,
        public_to_company: false,
      },
    ],
  },
  {
    user: 2851,
    boards: [
      {
        id: 9666,
        name: "Coleção Teste",
        qt_cards: 24,
        last_updated: "26/08/2021 - 01:00",
        shared_with: [{ id: 208 }, { id: 1 }, { id: 2851 }],
        deleted: false,
        public_to_company: false,
      },
    ],
  },
];

export const monitoring = [
  {
    user: 42,
    id: "d9e999e5-b840-4837-af09-7d603c3741sscb",
    search_results: [],
    registration_date: "04/01/2022 - 14:36",
    updated_date: "23/02/2022 - 17:24",
    query: 'tributário e "taxa selic" e IRPJ',
    filters: {
      entity: [0, 4, 5, 3],
    },
    active: true,
    send_email: true,
    send_notification: false,
    all_entities: false,
    frequency: "D",
  },
  {
    user: 42,
    id: "d9e999e5-b840-4837-af09-7d603c3741cb",
    search_results: [],
    registration_date: "04/01/2022 - 14:36",
    updated_date: "23/02/2022 - 17:24",
    query: 'tributário e "taxa selic" e IRPJ',
    filters: {
      entity: [0, 4, 5, 3],
    },
    active: true,
    send_email: true,
    send_notification: false,
    all_entities: false,
    frequency: "D",
  },
  {
    user: 2851,
    id: "d9e999e5-b840-4837-af09-7d603casdasdd",
    search_results: [
      {
        id: "a1b7f2aa-1f7d-479d-a6bd-d4e6a6d68dcc",
        registration_date: "2022-02-25T02:58:09.777927-03:00",
        updated_date: "25/02/2022 - 17:08",
        total_new_results: 18,
        entities:
          "0,1,2,3,40,41,5,7,8,43,11,12,13,42,15,17,18,19,23,24,25,26,28,29,27,6,4,31,33,10,30,32,34,36,37,35,16",
        initial_date: "24/02/2022",
        final_date: "25/02/2022",
        query_url:
          "https://app.turivius.com/cards/search?query=IR+PJ&page_n=0&fields=null&filters=%7B%22entity%22%3A%5B0%2C1%2C2%2C3%2C40%2C41%2C5%2C7%2C8%2C43%2C11%2C12%2C13%2C42%2C15%2C17%2C18%2C19%2C23%2C24%2C25%2C26%2C28%2C29%2C27%2C6%2C4%2C31%2C33%2C10%2C30%2C32%2C34%2C36%2C37%2C35%2C16%5D%2C%22date_range%22%3A%7B%220%22%3A%2224%2F02%2F2022%22%2C%221%22%3A%2225%2F02%2F2022%22%7D%7D",
        monitored_search: "08caa726-b146-433c-8097-92ee3d5b6930",
      },
      {
        id: "b6341f9c-511d-442e-af78-f996ba11d9d9",
        registration_date: "24/02/2022",
        updated_date: "24/02/2022 - 02:53",
        total_new_results: 5,
        entities:
          "0,1,2,3,40,41,5,7,8,43,11,12,13,42,15,17,18,19,23,24,25,26,28,29,27,6,4,31,33,10,30,32,34,36,37,35,16",
        initial_date: "23/02/2022",
        final_date: "24/02/2022",
        query_url:
          "https://app.turivius.com/cards/search?query=IR+PJ&page_n=0&fields=null&filters=%7B%22entity%22%3A%5B0%2C1%2C2%2C3%2C40%2C41%2C5%2C7%2C8%2C43%2C11%2C12%2C13%2C42%2C15%2C17%2C18%2C19%2C23%2C24%2C25%2C26%2C28%2C29%2C27%2C6%2C4%2C31%2C33%2C10%2C30%2C32%2C34%2C36%2C37%2C35%2C16%5D%2C%22date_range%22%3A%7B%220%22%3A%2223%2F02%2F2022%22%2C%221%22%3A%2224%2F02%2F2022%22%7D%7D",
        monitored_search: "08caa726-b146-433c-8097-92ee3d5b6930",
      },
    ],
    registration_date: "04/01/2022 - 14:36",
    updated_date: "23/02/2022 - 17:24",
    query: "IPI e ICMS",
    filters: {
      entity: [0, 4, 5, 3],
    },
    active: true,
    send_email: true,
    send_notification: false,
    all_entities: false,
    frequency: "D",
  },
];

export const permissions = [
  {
    user: 2851,
    status: true,
    is_admin: true,
    collections: 5,
    monitoring: 10,
    dashboard: true,
    segmentos: [
      {
        id: 1000,
        status: true,
        name: "Sistema Tributário",
        plano: 7,
        entity: "Todas",
        jurimetrics: "CARF",
        filter_unanimity: false,
        filter_favorability: false,
      },
      {
        id: 2000,
        status: false,
        name: "Sistema Trabalhista",
        plano: null,
        entity: null,
        jurimetrics: null,
        filter_unanimity: false,
        filter_favorability: false,
      },
      {
        id: 3000,
        status: false,
        name: "Sistema Cívil",
        plano: null,
        entity: null,
        jurimetrics: null,
        filter_unanimity: false,
        filter_favorability: false,
      },
    ],
  },
  {
    user: 42,
    status: true,
    is_admin: false,
    collections: 15,
    monitoring: 10,
    dashboard: false,
    segmentos: [
      {
        id: 1000,
        status: true,
        name: "Sistema Tributário",
        plano: 7,
        entity: "Todas",
        jurimetrics: "CARF",
        filter_unanimity: false,
        filter_favorability: false,
      },
      {
        id: 2000,
        status: true,
        name: "Sistema Trabalhista",
        plano: 9,
        entity: null,
        jurimetrics: null,
        filter_unanimity: true,
        filter_favorability: false,
      },
      {
        id: 3000,
        status: true,
        name: "Sistema Cívil",
        plano: 8,
        entity: null,
        jurimetrics: null,
        filter_unanimity: true,
        filter_favorability: true,
      },
      {
        id: 4000,
        status: true,
        name: "BI Tributário",
        plano: 999,
        entity: null,
        jurimetrics: null,
        filter_unanimity: true,
        filter_favorability: true,
      },
    ],
  },
];
