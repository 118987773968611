import React, { useEffect, useState } from "react";
import { Grid, Row, Col, Input, Button, RadioGroup, Radio } from "rsuite";
import { withRouter } from "react-router-dom";
import { Turivius } from "../../../ui";
import { UpdateStatusUserButton } from "../../UpdateStatusButton";
import UserPermissions from "../../UserInformations/UserPermissions";
import TuriviusPanel from "../../TuriviusPanel";
import CreateNewUserButton from "../../CreateNewUserButton";
import UpdateAdminButton from "../../UpdateAdminButton";
import UpdateBIButton from "../../UpdateBIButton";
import ExportUsersDataButton from "../../ExportUsersDataButton";
import "./style.scss";

const CompanyUsers = ({
  company,
  users,
  usersCount,
  getUsersByCompany,
  loadMore,
  hasMore,
  loadMoreUsers,
  ...props
}) => {
  const [userStatusFilter, setUserStatusFilter] = useState("");
  const [userNameEmailFilter, setUserNameEmailFilter] = useState("");

  useEffect(() => {
    getUsersByCompany();
  }, [""]);

  let filteredUsers =
    userStatusFilter !== ""
      ? users?.filter((u) => u.active === userStatusFilter)
      : users;
  filteredUsers = userNameEmailFilter
    ? filteredUsers?.filter(
        (u) =>
          u.name.includes(userNameEmailFilter) ||
          u.email.includes(userNameEmailFilter),
      )
    : filteredUsers;
  return users !== null ? (
    users?.length > 0 ? (
      <Grid fluid className="grid-container-user-list">
        <Row>
          <Col xs={24} sm={24} md={10}>
            <Input
              value={userNameEmailFilter}
              onChange={setUserNameEmailFilter}
              placeholder="Filtrar por nome ou email de usuário"
            />
          </Col>
          <Col xs={24} sm={12} md={10}>
            <RadioGroup
              style={{ marginBottom: 10, float: "right" }}
              name="radioList"
              inline
              appearance="picker"
              value={userStatusFilter}
              onChange={setUserStatusFilter}
            >
              <span
                style={{
                  padding: "8px 2px 8px 10px",
                  display: "inline-block",
                  verticalAlign: "middle",
                }}
              >
                Status:{" "}
              </span>
              <Radio value={""}>Todos</Radio>
              <Radio value={true}>Ativos</Radio>
              <Radio value={false}>Inativos</Radio>
            </RadioGroup>
          </Col>
          <Col xs={24} sm={12} md={4}>
            <ExportUsersDataButton
              company={company}
              users={users}
              getUsersByCompany={getUsersByCompany}
            />
          </Col>
        </Row>
        <Row>
          <p className="users-information">
            Exibindo <b>{users.length}</b> de <b>{usersCount}</b> usuários
          </p>
        </Row>

        <Row>
          <Col xs={24} sm={24} md={22}>
            {filteredUsers?.map((user, i) => (
              <TuriviusPanel
                redirectButton
                expandedButton
                inside={false}
                redirectAction={() =>
                  props.history.push(`/usuarios/${user.id}`)
                }
                key={user.id}
                header={
                  <Grid fluid>
                    <Row>
                      <Col xs={24} sm={24} md={5}>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <label>Nome</label>
                          <span>{user.name}</span>
                        </div>
                      </Col>
                      <Col xs={24} sm={24} md={5}>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <label>E-mail</label>
                          <span
                            style={{
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            <Turivius.TuriviusTooltip
                              trigger="hover"
                              message={`Clique para enviar e-mail para ${user.email}`}
                              placement="top"
                            >
                              <a href={`mailto:${user.email}`}>{user.email}</a>
                            </Turivius.TuriviusTooltip>
                          </span>
                        </div>
                      </Col>
                      <Col xs={24} sm={24} md={4}>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <label>Status</label>
                          <UpdateStatusUserButton table data={user} />
                        </div>
                      </Col>
                      <Col xs={24} sm={24} md={5}>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <label>Administrador</label>
                          <UpdateAdminButton table data={user} />
                        </div>
                      </Col>
                      <Col xs={24} sm={24} md={5}>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <label>BI Tributário</label>
                          <UpdateBIButton table data={user} />
                        </div>
                      </Col>
                    </Row>
                  </Grid>
                  // <div style={{ display: "flex", alignItems: "center" }}>
                  //     <div style={{ display: "flex", flexDirection: "column", width: "20%", padding: 5 }}>
                  //         <label>Nome</label>
                  //         <span>{user.name}</span>
                  //     </div>
                  //     <div style={{ display: "flex", flexDirection: "column", width: "20%", padding: 5 }}>
                  //         <label>E-mail</label>
                  //         <span style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                  //             <Turivius.TuriviusTooltip
                  //                 trigger="hover"
                  //                 message={`Clique para enviar e-mail para ${user.email}`}
                  //                 placement="top"
                  //             >
                  //                 <a href={`mailto:${user.email}`}>{user.email}</a>
                  //             </Turivius.TuriviusTooltip>
                  //         </span>
                  //     </div>
                  //     <div style={{ display: "flex", flexDirection: "column", width: "20%", padding: 5 }}>
                  //         <label>Status</label>
                  //         <UpdateStatusUserButton table data={user} />
                  //     </div>
                  //     <div style={{ display: "flex", flexDirection: "column", width: "20%", padding: 5 }}>
                  //         <label>Administrador</label>
                  //         <UpdateAdminButton table data={user} />
                  //     </div>
                  //     <div style={{ display: "flex", flexDirection: "column", width: "20%", padding: 5 }}>
                  //         <label>BI Tributário</label>
                  //         <UpdateAdminButton table data={user} />
                  //     </div>
                  // </div>
                }
                content={
                  <Grid fluid>
                    <Col xs={24} sm={24} md={5}>
                      <div style={{ display: "inline-grid", marginBottom: 20 }}>
                        <label>Nome</label>
                        <span>{user.name}</span>
                        <br />
                        <label>E-mail</label>
                        <span>{user.email}</span>
                      </div>
                      <Button
                        block
                        onClick={() =>
                          props.history.push(`/usuarios/${user.id}`)
                        }
                      >
                        Ver mais
                      </Button>
                    </Col>
                    <Col xs={24} sm={24} md={19}>
                      <UserPermissions user={user} users={users} />
                    </Col>
                  </Grid>
                }
              />
            ))}
            <div className="container-load-more">
              <Button
                className="btn-load-more"
                onClick={loadMore}
                disabled={!hasMore}
                loading={loadMoreUsers}
              >
                Carregar mais
              </Button>
            </div>
          </Col>
        </Row>
        <Row>
          <p className="users-information">
            Exibindo <b>{users.length}</b> de <b>{usersCount}</b> usuários
          </p>
        </Row>
      </Grid>
    ) : (
      <div className="container-no-data">
        <h4>Nenhum usuário criado</h4>
        <span>Clique no botão abaixo para criar um novo usuário</span>
        <CreateNewUserButton isBody={true} company={company} />
      </div>
    )
  ) : (
    <Turivius.TuriviusPreloader />
  );
};

export default withRouter(CompanyUsers);
