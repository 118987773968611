import db from "../config/fire-config";
import {
  getDocs,
  getDoc,
  doc,
  updateDoc,
  collection,
  addDoc,
  deleteDoc,
} from "firebase/firestore";
import { getAuth, reauthenticateWithCredential } from "firebase/auth";

export const getAll = async () => {
  const infoRef = collection(db, "informations");

  const querySnapshot = await getDocs(infoRef);
  const result = [];
  querySnapshot.forEach((doc) => {
    var obj = doc.data();
    obj.id = doc.id;
    result.push(obj);
  });
  if (result.length > 0) {
    return result;
  } else {
    throw new Error("Tivemos um problem para retornar os resultados");
  }
};
export const getById = async (id) => {
  const docRef = doc(db, "informations", id);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return docSnap.data();
  } else {
    throw new Error("Documento não existe");
  }
};
export const create = async (data) => {
  const auth = getAuth();
  const user = auth.currentUser;

  // TODO(you): prompt the user to re-provide their sign-in credentials
  const credential = ("vitor@turivius.com", "Turivius@2023");

  reauthenticateWithCredential(user, credential)
    .then(() => {
      // User re-authenticated.
    })
    .catch((error) => {
      // An error ocurred
      // ...
    });
  const docRef = await addDoc(collection(db, "informations"), data);
  return docRef.id;
};
export const edit = async (id, data) => {
  const auth = getAuth();
  const user = auth.currentUser;

  // TODO(you): prompt the user to re-provide their sign-in credentials
  const credential = ("vitor@turivius.com", "Turivius@2023");

  reauthenticateWithCredential(user, credential)
    .then(() => {
      // User re-authenticated.
    })
    .catch((error) => {
      // An error ocurred
      // ...
    });
  const docRef = doc(db, "informations", id);
  try {
    await updateDoc(docRef, data);
    return docRef.id;
  } catch (error) {
    const errorCode = error.code;
    const errorMessage = error.message;
    throw new Error(errorCode + " " + errorMessage);
  }
};
export const remove = async (id) => {
  const auth = getAuth();
  const user = auth.currentUser;

  // TODO(you): prompt the user to re-provide their sign-in credentials
  const credential = ("vitor@turivius.com", "Turivius@2023");

  reauthenticateWithCredential(user, credential)
    .then(() => {
      // User re-authenticated.
    })
    .catch((error) => {
      // An error ocurred
      // ...
    });
  try {
    await deleteDoc(doc(db, "informations", id));
    return "Removido com Sucesso";
  } catch (error) {
    const errorCode = error.code;
    const errorMessage = error.message;
    throw new Error(errorCode + " " + errorMessage);
  }
};
